import React, { useEffect, useRef, useState } from 'react';
import axios from "axios";
import { Link } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { useParams } from 'react-router-dom';
import { API_URL, BASE_URL } from './Config';
import config from "./common/Config";

const CloseTripSummary = (props) => {
	const { tripId } = useParams();
	const [tripOrder, setTripOrder] = useState([]);
	const [deliveredOrders, setDeliveredOrders] = useState([]);
	const [tripNumber, setTripNumber] = useState([]);
	const [driverName, setDriverName] = useState([]);
	const [vehicle, setVehicle] = useState([]);
	const [date, setDate] = useState([]);
	const [undeliveredOrders, setUndeliveredOrders] = useState([]);
	const [cashCollection, setCashCollection] = useState([]);
	const [totalCashCollection, setTotalCashCollection] = useState(0);
	const [chequeCollection, setChequeCollection] = useState([]);
	const [totalChequeCollection, setTotalChequeCollection] = useState(0);
	const [partialNotDeliveredBox, setPartialNotDeliveredBox] = useState([]);
	const [partialNotDeliveredPiece, setPartialNotDeliveredPiece] = useState([]);
	const [undeliveredProductsList, setUndeliveredProductsList] = useState([]);
	const [totalUndeliveredBox, setTotalUndeliveredBox] = useState(0);
	const [totalUndeliveredPcs, setTotalUndeliveredPcs] = useState(0);
	const [returnProductsList, setReturnProductsList] = useState([]);
	const [totalReturnBox, setTotalReturnBox] = useState(0);
	const [totalReturnPcs, setTotalReturnPcs] = useState(0);
	const [totalProductsBox, setTotalProductsBox] = useState([]);
	const [totalProductsPiece, setTotalProductsPiece] = useState([]);
	const [deliveredProductsBox, setDeliveredProductsBox] = useState([]);
	const [deliveredProductsPices, setDeliveredProductsPices] = useState([]);
	const [undeliveredProductsBox, setUndeliveredProductsBox] = useState([]);
	const [undeliveredProductsPices, setUndeliveredProductsPices] = useState([]);
	const [deliveredAmt, setDeliveredAmt] = useState(0);
	const [undeliveredAmt, setUndeliveredAmt] = useState(0);
	const deliveredAmtValue = parseFloat(deliveredAmt);
	const undeliveredAmtValue = parseFloat(undeliveredAmt);
	const totalAmt = parseFloat(deliveredAmtValue + undeliveredAmtValue).toFixed(2);
	const modalRef = useRef(null);
	const [deliveredOrderDetails, setDeliveredOrderDetails] = useState([]);
	const [undeliveredOrderDetails, setUndeliveredOrderDetails] = useState([]);
	const [partialUndelivered, setPartialUndelivered] = useState([]);
	const [returnDetails, setReturnDetails] = useState([]);
	const [totalManifest, setTotalManifest] = useState([]);
	const accessToken = localStorage.getItem('accessToken');

	const fetchData = async () => {
	    const formData = new FormData();
	    formData.append('tripId', tripId);
	    const apiUrl = `${API_URL}/get-trip-summary`;
	    try {
	      const response = await fetch(apiUrl, {
	        method: 'POST',
	        headers: {
	          // 'Content-Type': 'application/json',
	          authorization: `Bearer ${accessToken}`,
	        },
	        body: formData,
	      });

	      if (!response.ok) {
	        throw new Error('Failed to fetch new sales data');
	      }

	      const data = await response.json();
	      const tripData = data.data;

	      console.log(tripData.trip_order_details);

	      setTripOrder(tripData.trip_order_details);
	      setDeliveredOrders(tripData.deliverySummary.delivered_orders);
	      setTripNumber(tripData.trip_number);
	      setDriverName(tripData.driver);
	      setVehicle(tripData.vehicle);
	      setDate(tripData.date);
	      setUndeliveredOrders(tripData.deliverySummary.undelivered_orders);
	      setCashCollection(tripData.payment_collection.cash_collection_details);
	      setChequeCollection(tripData.payment_collection.cheque_collection_details);
	      setPartialNotDeliveredBox(tripData.partial_not_delivered_box);
	      setPartialNotDeliveredPiece(tripData.partial_not_delivered_piece);
	      setUndeliveredProductsList(tripData.undelivered_products_list);
	      setReturnProductsList(tripData.return_products_list);
	      setTotalProductsBox(tripData.total_products_box);
	      setTotalProductsPiece(tripData.total_products_piece);
	      setDeliveredProductsBox(tripData.delivered_products_box);
	      setDeliveredProductsPices(tripData.delivered_products_piece);
	      setUndeliveredProductsBox(tripData.undelivered_products_box);
	      setUndeliveredProductsPices(tripData.undelivered_products_piece);
	      setDeliveredAmt(parseFloat(tripData.deliverySummary.delivered_amt).toFixed(2));
	      setUndeliveredAmt(parseFloat(tripData.deliverySummary.undelivered_amt).toFixed(2));
	      setDeliveredOrderDetails(tripData.deliverySummary.delivered_order_details);
	      setUndeliveredOrderDetails(tripData.deliverySummary.undelivered_order_details);
	      setPartialUndelivered(tripData.partial_undelivered);
	      setReturnDetails(tripData.return_details);
	      setTotalManifest(tripData.total_manifest);

	      //console.log(tripData.undelivered_products_list);
	    } catch (error) {
	      console.error('Error fetching new sales data:', error);
	    }
	};

	useEffect(() => {

	    const calculateTotalPrice = () => {
	      const total = cashCollection.reduce((acc, product) => {
	        const price = parseFloat(product.cashCollected);
	        if (!isNaN(price)) {
	          return acc + price;
	        }
	        return acc;
	      }, 0);
	      setTotalCashCollection(total.toFixed(2)); // Format to 2 decimal places
	    };
	    calculateTotalPrice();

	    const calculateTotalCheque = () => {
	      const total = chequeCollection.reduce((acc, product) => {
	        const price = parseFloat(product.chequeAmount);
	        if (!isNaN(price)) {
	          return acc + price;
	        }
	        return acc;
	      }, 0);
	      setTotalChequeCollection(total.toFixed(2)); // Format to 2 decimal places
	    };
	    calculateTotalCheque();

	    let pieces = 0;
    	let boxes = 0;
	    const calculateUndeliveredProducts = () => {
	      const total = undeliveredProductsList.reduce((acc, product) => {
	        const pdtQty = parseFloat(product.qty);
	        if (!isNaN(pdtQty)) {
	          	if (product.qtyType === 'piece') {
		          pieces += pdtQty;
		        } else if (product.qtyType === 'box') {
		          boxes += pdtQty;
		      	}
	        }
	      }, 0);
	      setTotalUndeliveredBox(boxes);
	      setTotalUndeliveredPcs(pieces);
	    };
	    calculateUndeliveredProducts();

	    let returnPcs = 0;
    	let returnBox = 0;
	    const calculateReturnProducts = () => {
	      const total = returnProductsList.reduce((acc, product) => {
	        const pdtQty = parseFloat(product.qty);
	        if (!isNaN(pdtQty)) {
	          	if (product.qtyType === 'piece') {
		          returnPcs += pdtQty;
		        } else if (product.qtyType === 'box') {
		          returnBox += pdtQty;
		      	}
	        }
	      }, 0);
	      setTotalReturnBox(returnBox);
	      setTotalReturnPcs(returnPcs);
	    };
	    calculateReturnProducts();

	}, [cashCollection, chequeCollection, undeliveredProductsList, returnProductsList]);

	useEffect(() => {

		if (accessToken === null) {
	      window.location.href = `${config.BASE_URL}/login`;
	      return;
    	}

    	fetchData();
  	}, []);

  	useEffect(() => {
	    const table = $('#tripOrderDetails').DataTable({
	      pageLength: 50,
	      paging: true,
	      lengthMenu: [10, 20, 50],
	      searching: true,
	      ordering: true,
	      info: true,
	      responsive: true,
	      autoWidth: false,
	      language: {
	        paginate: {
	          first: 'First',
	          last: 'Last',
	          next: 'Next',
	          previous: 'Previous',
	        },
	        search: 'Search:',
	      },
	      data: tripOrder,
	      columns: [
	        { data: null },
	        { data: 'customerName' },
	        { data: 'manifestId' },
	        { 
           		data: 'referenceNo',
           		className: "text-center",
	            render: function (data, type, row) {
	                return '<a href="'+BASE_URL+'/trip_summary/print/' + row.orderId + '">' + row.referenceNo + '</a>';
	            }
	          
	        },
	        { data: 'postCode', className: "text-center" },
	        { data: 'route', className: "text-center" },
	        { data: 'sale_status', className: "text-center" }	        
	    ],
	    columnDefs: [
	        { 
	            targets: [0, 1, 2, 3, 4, 5,6], 
	            orderable: false 
	        }
	    ],
	    drawCallback: function (settings) {
	        var api = this.api();
	        var startIndex = api.page.info().start;
	        api.column(0, { page: 'current' }).nodes().each(function (cell, i) {
	            cell.innerHTML = startIndex + i + 1;
	        });
	    }
	    });
	    return () => {
	      table.destroy();
	    };
	  }, [tripOrder]);

	return(
		<>
			<Header />
			<div className="breadcrumbs">
			  	<div className="container-fluid">
			    	<div className="row">
			      		<div className="col-12 col-lg-12 col-sm-12"> 
			        		<Link to="/Dashboard"><i className="fa fa-home"></i></Link>   
			        		<Link to="/OpenTrips">Closed Trips</Link> 
			        		<span className="last-crumb">Complete Trip Summary  {tripNumber}</span> 
			      		</div>
			   	 	</div>
			  	</div>
			</div>
			<div className="main-container">
			    <div className="container-fluid">
			        <div className="row">
			            <div className="col-12 col-lg-12 col-sm-12">
			                <div className="show-addprod-area dashboard">
			                    <div className="complete-summery-area">
			                        <div className="title">
			                            <span>Complete Trip Summary  - {tripNumber} </span>
			                            <ul>
			                                <li>Date - {date}</li>
			                                <li>Driver - {driverName}</li>
			                                <li>Vehicle - {vehicle}</li>
			                               <a href={`/closed_trip_summary/${tripId}`} target="_blank" rel="noopener noreferrer">Summary Report</a>
			                            </ul>
			                        </div>
			                    </div>
			                    <div className="float:left; width:100%;">
			                        <div className="row">
			                            <div className="col-12 col-lg-3 col-sm-6">
			                                <div className="sumery-delivery-box">
			                                    <table width="100%" border="0" className="table table-bordered resizable">
			                                        <tbody>
			                                            <tr>
			                                                <td>Orders Delivered</td>
			                                                <td> <a href="#" data-bs-toggle="modal" data-bs-target="#ordersDeliveredMdl"> {deliveredOrders}</a></td>
			                                            </tr>
			                                            <tr>
			                                                <td>Orders Undelivered</td>
			                                                <td><a href="#" data-bs-toggle="modal" data-bs-target="#ordersUndeliveredMdl">{undeliveredOrders}</a></td>
			                                            </tr>
			                                            <tr>
			                                                <td>Cash Collected</td>
			                                                <td>£ {totalCashCollection}</td>
			                                            </tr>
			                                            <tr>
			                                                <td>Cheque (0)</td>
			                                                <td>£ {totalChequeCollection}</td>
			                                            </tr>
			                                        </tbody>
			                                    </table>
			                                </div>
			                            </div>
			                            <div className="col-12 col-lg-3 col-sm-6">
			                                <div className="sumery-delivery-box">
			                                    <table width="100%" border="0" className="table table-bordered resizable">
			                                        <thead>
			                                            <tr>
			                                                <th>Return Goods</th>
			                                                <th>Box</th>
			                                                <th>Pcs</th>
			                                            </tr>
			                                        </thead>
			                                        <tbody>
			                                            <tr>
			                                                <td><a href="#" data-bs-toggle="modal" data-bs-target="#partialUndeliveredMdl"> Partial Undeliv. </a></td>
			                                                <td>{partialNotDeliveredBox}</td>
			                                                <td>{partialNotDeliveredPiece}</td>
			                                            </tr>
			                                            <tr>
			                                                <td><a href="#" data-bs-toggle="modal" data-bs-target="#undeliveredProductsMdl">Undelivered</a></td>
			                                                <td>{totalUndeliveredBox}</td>
			                                                <td>{totalUndeliveredPcs}</td>
			                                            </tr>

			                                            <tr>
			                                                <td><a href="#" data-bs-toggle="modal" data-bs-target="#returnDetailsMdl">Return</a></td>
			                                                <td>{totalReturnBox}</td>
			                                                <td>{totalReturnPcs}</td>
			                                            </tr>
			                                        </tbody>
			                                    </table>
			                                </div>
			                            </div>
			                            <div className="col-12 col-lg-3 col-sm-6">
			                                <div className="sumery-delivery-box">
			                                    <table width="100%" border="0" className="table table-bordered resizable">
			                                        <thead>
			                                            <tr>
			                                                <th>&nbsp;</th>
			                                                <th>Box</th>
			                                                <th>Pcs</th>
			                                            </tr>
			                                        </thead>
			                                        <tbody>
			                                            <tr>
			                                                <td>Total Products</td>
			                                                <td>{totalProductsBox}</td>
			                                                <td>{totalProductsPiece}</td>
			                                            </tr>
			                                            <tr>
			                                                <td>Delivered</td>
			                                                <td>{deliveredProductsBox}</td>
			                                                <td>{deliveredProductsPices}</td>
			                                            </tr>

			                                            <tr>
			                                                <td>Undelivered</td>
			                                                <td>{undeliveredProductsBox}</td>
			                                                <td>{undeliveredProductsPices}</td>
			                                            </tr>
			                                        </tbody>
			                                    </table>
			                                </div>
			                            </div>
			                            <div className="col-12 col-lg-3 col-sm-6">
			                                <div className="sumery-delivery-box">
			                                    <table width="100%" border="0" className="table table-bordered resizable">
			                                        <tbody>
			                                            <tr>
			                                                <td>Delivered AMT</td>
			                                                <td>£ {deliveredAmt}</td>
			                                            </tr>
			                                            <tr>
			                                                <td>Undeliverd AMT</td>
			                                                <td>£ {undeliveredAmt}</td>
			                                            </tr>
			                                            <tr>
			                                                <td>&nbsp;</td>
			                                                <td>&nbsp;</td>
			                                            </tr>
			                                            <tr>
			                                                <td>Total</td>
			                                                <td>£ {totalAmt}</td>
			                                            </tr>
			                                        </tbody>
			                                    </table>
			                                </div>
			                            </div>
			                        </div>
			                    </div>
			                </div>

			                <div className="total-manifests-area dashboard">
			                    <p><b>Total Manifests :</b>{totalManifest}</p>
			                    <div className="verical-scrl-wrapper common-table autoheight-table close-trip-summery" id="style-3">
			                        <table className="table table-bordered resizable" id="tripOrderDetails">
			                            <thead>
			                                <tr>
			                                    <th>#</th>
			                                    <th>Customers (A/C No)</th>
			                                    <th>Manifest No</th>
			                                    <th>Order ID</th>
			                                    <th>Post code</th>
			                                    <th>Route</th>
			                                    <th>Status</th>
			                                </tr>
			                            </thead>
			                            <tbody>
			                                
			                            </tbody>
			                        </table>
			                    </div>
			                </div>
			            </div>
			        </div>
			    </div>
			</div>
			<div id="ordersDeliveredMdl" className="modal fade" aria-hidden="true" tabIndex="-1" role="dialog">
	        	<div className="modal-dialog" role="document">
		          	<div className="modal-content">
		            	<div className="modal-header">
		              	<h5 className="modal-title">Orders Delivered</h5>
		              	<button type="button" className="btn-close" data-bs-dismiss="modal"  aria-label="Close"></button>
		            	</div>
		            	<div className="modal-body">
		            		<table id="orders_delivered" className="table table-bordered table-hover table-striped dataTable resizable" >
					          <thead>  
					            <tr className="active">
					              <th>#</th>
					              <th>Invoice No</th>
					              <th>Invoice Date</th>
					              <th>Customers (A/C No)</th>               
					            </tr>
					          </thead>
					          <tbody id="trip_delivered">

					          	{deliveredOrderDetails.map((item, index) => (
						            <tr>
						              <td>{index + 1}</td>
						              <td>{item.referenceNo}</td>
						              <td>{item.invoice_date}</td>
						              <td>{item.customerName} ( {item.customerAccountNo} )</td>
						            </tr>
						        ))}
					          </tbody>
					        </table>
		              

		            	</div>
		            	
		          	</div>
	        	</div>
	      	</div>

	      	<div id="ordersUndeliveredMdl" className="modal fade" aria-hidden="true" tabIndex="-1" role="dialog">
	        	<div className="modal-dialog" role="document">
		          	<div className="modal-content">
		            	<div className="modal-header">
		              	<h5 className="modal-title">Orders Undelivered</h5>
		              	<button type="button" className="btn-close" data-bs-dismiss="modal"  aria-label="Close"></button>
		            	</div>
		            	<div className="modal-body">
		            		<table id="orders_delivered" className="table table-bordered table-hover table-striped dataTable resizable" >
					          <thead>  
					            <tr className="active">
					              <th>#</th>
					              <th>Invoice No</th>
					              <th>Invoice Date</th>
					              <th>Customers (A/C No)</th>               
					            </tr>
					          </thead>
					          <tbody id="trip_delivered">
					          	{undeliveredOrderDetails.map((item, index) => (
						            <tr>
						              <td>{index + 1}</td>
						              <td>{item.referenceNo}</td>
						              <td>{item.invoice_date}</td>
						              <td>{item.customerName} ( {item.customerAccountNo} )</td>
						            </tr>
						        ))}
					          	
					          </tbody>
					        </table>
		              

		            	</div>
		            	
		          	</div>
	        	</div>
	      	</div>

	      	<div id="partialUndeliveredMdl" className="modal fade" aria-hidden="true" tabIndex="-1" role="dialog">
	        	<div className="modal-dialog modal-xl" role="document">
		          	<div className="modal-content">
		            	<div className="modal-header">
		              	<h5 className="modal-title">Partial Undelivered List</h5>
		              	<button type="button" className="btn-close" data-bs-dismiss="modal"  aria-label="Close"></button>
		            	</div>
		            	<div className="modal-body">
		            		<table id="orders_delivered" className="table table-bordered table-hover table-striped dataTable resizable" >
					          <thead>  
					            <tr className="active">
					              	<th>#</th>
					              	<th>Order Id</th>
					              	<th>Description</th>
					              	<th>Order Qty</th>
					              	<th>Order Type</th>   
					              	<th>Unit Price</th>
					              	<th>VAT</th>
					              	<th>Total</th>  
					              	<th>Undeli.Qty</th> 
					              	<th>Reason</th> 
					              	 {/*<th>Action</th> */}                 
					            </tr>
					          </thead>
					          <tbody >

					          	{partialUndelivered.map((item, index) => {
					          	
					          		const subtotal = parseFloat(item.subtotal);
					          		const tax = parseFloat(item.tax);
					          		const total = (subtotal + tax).toFixed(2);

					          		return (
							            <tr>
							              <td>{index + 1}</td>
							              <td>{item.referenceNo}</td>
							              <td>{item.productName} ( {item.productCode} )</td>
							              <td>{item.quantity}</td>
							              <td>{item.order_type}</td>
							              <td>£ {item.unit_price}</td>
							              <td>£ {item.tax}</td>
							              <td>£ {total}</td>
							              <td>{item.not_delivered_qty}</td>
							              <td>{item.reason}</td>
							              {/* <td>
							              	{ item.reason == 'no longer needed' || item.reason == 'wrong product' ? (
							                    item.IsStock_update == 'N' ? (
							                     <button className="btn btn-primary btn-sm add-stock"  data-product-id={item.productId} data-order-id={item.orderId} >Add Stock</button>
							                    ) : (
							                      ''
							                    )
							                  ) : (
							                    ''
							                  )}
											</td>*/}
							              
							            </tr>
						             );
						         })}
					          </tbody>
					        </table>
		              

		            	</div>
		            	
		          	</div>
	        	</div>
	      	</div>

	      	<div id="undeliveredProductsMdl" className="modal fade" aria-hidden="true" tabIndex="-1" role="dialog">
	        	<div className="modal-dialog modal-lg" role="document">
		          	<div className="modal-content">
		            	<div className="modal-header">
		              	<h5 className="modal-title">Undelivered Products</h5>
		              	<button type="button" className="btn-close" data-bs-dismiss="modal"  aria-label="Close"></button>
		            	</div>
		            	<div className="modal-body">
		            		<table id="orders_delivered" className="table table-bordered table-hover table-striped dataTable resizable" >
					          <thead>  
					            <tr className="active">
					              	<th>#</th>
					              	<th>Description</th>
					              	<th className="text-center" >Order Qty</th>
					              	<th className="text-center" >Order Type</th>   
					              	<th className="text-end" >Unit Price</th>
					              	<th className="text-end" >VAT</th>
					              	<th className="text-end" >Total</th>        
					            </tr>
					          </thead>
					          <tbody id="trip_delivered">
					          	{undeliveredProductsList.map((item, index) => {
					          	
					          		const unSubtotal = parseFloat(item.subtotal);
					          		const unTax = parseFloat(item.item_tax);
					          		const unTotal = (unSubtotal + unTax).toFixed(2);

					          		return (
					          			<tr>
							              	<td>{index + 1}</td>
							              	<td>{item.productName} ( {item.productCode} )</td>
							              	<td className="text-center" >{item.qty}</td>
							              	<td className="text-center" >{item.qtyType}</td>
							              	<td className="text-end" >£ {item.unit_price}</td>
							              	<td className="text-end" >£ {item.item_tax}</td>
							              	<td className="text-end" >£ {unTotal}</td>
							            </tr>
					          		);
					          	})}
					          </tbody>
					        </table>
		              

		            	</div>
		            	
		          	</div>
	        	</div>
	      	</div>

	      	<div id="returnDetailsMdl" className="modal fade" aria-hidden="true" tabIndex="-1" role="dialog">
	        	<div className="modal-dialog modal-lg" role="document">
		          	<div className="modal-content">
		            	<div className="modal-header">
		              	<h5 className="modal-title">Return Products</h5>
		              	<button type="button" className="btn-close" data-bs-dismiss="modal"  aria-label="Close"></button>
		            	</div>
		            	<div className="modal-body">
		            		<table className="table table-bordered table-hover table-striped dataTable resizable" >
					          <thead>  
					            <tr className="active">
					              	<th>#</th>
					              	<th>Date</th>
						            <th>Return No</th>
						            <th>Customer (Ac/No)</th>   
						            <th>Grand Total</th>
						            <th>Total Items</th>
						            <th>Return By</th>  
						            <th>Status</th> 
						            <th> Actions</th>          
					            </tr>
					          </thead>
					          <tbody >
					          	{returnDetails.map((item, index) => {
					          		let status = '';
					          		if (item.is_accept === 'Y') {
					                    status = 'Accept';
					                } else if (item.is_accept === 'R') {
					                    status = 'Reject';
					                } else if (item.is_accept === 'N') {
					                    status = 'Pending';
					                }
					          		const rdTotal = parseFloat(item.grand_total);

					          		return (
					          			<tr>
							              	<td>{index + 1}</td>
							              	<td className="text-center" >{item.date}</td>
							              	<td className="text-center" >{item.reference_no}</td>
							              	<td>{item.company} ( {item.accound_no} )</td>
							              	<td className="text-end" >£ {rdTotal}</td>
							              	<td className="text-center" >{item.total_items}</td>
							              	<td className="text-center">{item.first_name} {item.last_name} </td>
							              	<td className="text-center" >{status}</td>
							              	<td>

							              	{
							              		status == 'Pending' 
							              		? 
							              			<div className="filter verti-popdot">
													  <a className="icon" href="#" data-bs-toggle="dropdown" aria-expanded="false">
													    Action <i className="bi bi-three-dots-vertical"></i>
													  </a>
													  <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
													    <li><a className="deductionbtn" href="#" >Accept Return</a></li>
													    <li><a className="applybtn" href="#" >Reject Return</a></li>
													  </ul>
													</div> 
							              		: ''

							              	}

							              	</td>
							            </tr>
					          		);
					          	})}
					          </tbody>
					        </table>
		              

		            	</div>
		            	
		          	</div>
	        	</div>
	      	</div>
			<Footer />
		</>
	);

};

export default CloseTripSummary;