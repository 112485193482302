import React, { useState, useEffect, useRef } from 'react';
import Header from '../../Header';
import Footer from '../../Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import config from "../../common/Config";
import Swal from 'sweetalert2';
import ExportProductList from './ExportProductList';

const InactiveProductList = ({refresh}) =>{
  const inActivePdtRef = useRef(null);
  const [searchValue, setSearchValue] = useState([]);
  const accessToken = localStorage.getItem('accessToken');
  const [totalActiveProduct, setTotalActiveProduct] = useState('');

  const getInAcivepdtList = () => {
	const $table = $(inActivePdtRef.current);
	if (!$.fn.DataTable.isDataTable($table)) {
	  $table.DataTable({
		processing: true,
		serverSide: true,
		destroy: true, 
		ajax: {
		  url: `${config.API_URL}/products/getInactive_product`,
		  type: 'POST',
		  headers: { authorization: `Bearer ${accessToken}` },
		  dataSrc: function (json) {
			setTotalActiveProduct(json.recordsFiltered);
			return json.data;
		  },
		},
		columns: [
		  { data: 'code' },
		  { data: 'name' },
		  { data: 'categories' },
		  { data: 'brand' },
		  { data: 'size' },
		  { data: 'box_cost' },
		  { data: 'price' },
		  { data: 'box_diff' },
		  { data: 'quantity' },
		  { data: 'piece_cost' },
		  { data: 'piece_price' },
		  { data: 'piece_diff' },
		  { data: 'split_quantity' },
		  {
			data: 'status',
			render: function (data, type, row) {
			  return `
				<label class="switch">
				  <input data-id="${row.id}" class="switch-input" type="checkbox" name="changeStatus" ${data == "1" ? "checked" : ""} />
				  <span class="switch-label" data-on="Yes" data-off="No"></span>
				  <span class="switch-handle"></span>
				</label>`;
			}
		  },
		  {
			data: 'code',
			render: function (data, type, row) {
			  return `
				<div>
				  <a class="deductionbtn reject-btn" href="${config.BASE_URL}/products/edit/${row.id}" title="Edit Product"><i class="bi bi-pencil-square"></i></a>
				  <a class="deductionbtn reject-btn" href="${config.BASE_URL}/products/previous_pricing/${row.id}" title="Previous Pricing"><i class="bi bi-tags"></i></a>
				</div>`;
			}
		  },
		],
		columnDefs: [{ orderable: false, targets: '_all' }],
		pageLength: 10,
	  });
  
	  // ✅ Handle status change with single event listener (Event Delegation)
	  $table.on('change', '.switch-input', function () {
		const id = $(this).data('id');
		const status = this.checked ? 1 : 0;
		handleChangeStatus(id, status);
	  });
  
	} else {
	  $table.DataTable().ajax.reload(null, false); 
	}
  };
  

  	useEffect(() => {
      	if (accessToken === null) {
          	window.location.href = `${config.BASE_URL}/login`;
          	return;
      	}
      	if (refresh) {
      		getInAcivepdtList();
      	}  
      	// return () => {
        //   	if (inActivePdtRef.current && $.fn.DataTable.isDataTable(inActivePdtRef.current)) {
        //       	$(inActivePdtRef.current).DataTable().destroy();
        //   	}
      	// };
  	}, [searchValue, refresh]);


  	const handleChangeStatus = async (id, status) => {
		const formData = new FormData();
	    formData.append('productId', id);
	    formData.append('status', status);
		try {
		  const response = await fetch(`${config.API_URL}/products/update_status`, {
			method: 'POST',
			headers: { authorization: `Bearer ${accessToken}` },
			body: formData,
		  });
	  
		  if (!response.ok) throw new Error('Failed to change status');
		  await response.json();
	  
		  Swal.fire({
			title: 'Success',
			text: 'Status changed successfully.',
			icon: 'success',
			showConfirmButton: false,
			timer: 1500,
		  });

		  getInAcivepdtList();

		  const row = $(`#InactiveProductTable`).DataTable().row(`[data-id="${id}"]`);
		  const rowData = row.data();
		  rowData.status = status;
		  row.data(rowData).draw(false); 
	  
		} catch (error) {
		  console.error('Error status change:', error);
		}
	  };
	  

	return(
		<>
		<div style={{ margin: "7px 0 0 0", float: "right"  }}>
			<ExportProductList totalActiveProduct={totalActiveProduct} urlLink="products/getInactive_product" pdtStatus="inactive" />
			</div>
			<table class="table table-bordered dataTable active-tbl resizable" ref={inActivePdtRef} style={{width: '100%'}} >
                <thead>
                  <tr>
                    <td colspan="5" className='dark-head'>&nbsp;</td>
                    <td colspan="4" className='dark-head' style={{ textAlign: 'center' }}>Box</td>
                    <td colspan="4" className='dark-head' style={{ textAlign: 'center' }}>Piece</td>
                    <td colspan="2" className='dark-head'>&nbsp;</td>
                  </tr>
                  <tr>
                    <th>Code</th>
                    <th>Name</th>
                    <th>Category</th>
                    <th>Brand</th>
                    <th>Size</th>
                    <th className='minitable-one'>Cost P</th>
                    <th className='minitable-one'>Sale P</th>
                    <th className='minitable-one'>Profit %</th>
                    <th className='minitable-one'>Qty</th>
                    <th className='minitable-two'>Cost P</th>
                    <th className='minitable-two'>Sale P</th>
                    <th className='minitable-two'>Profit %</th>
                    <th className='minitable-two'>Qty</th>
                    <th className='text-center'>Active</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  

                </tbody>
              </table>


		</>
	);
}

export default InactiveProductList;