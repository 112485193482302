import React, { useState, useEffect, useRef, useCallback  } from 'react';
import Select from 'react-select';
import axios from "axios";
import Header from '../Header';
import Footer from '../Footer';
import FlashMessage from '../FlashMessage';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { API_URL, BASE_URL } from '../Config';
import config from "../common/Config";

const StockTakeHistory = () => {
	const [draw, setDraw] = useState(1);
	const [start, setStart] = useState(0);
	const [length, setLength] = useState(5000);
	const [stockHistory, setStockHistory] = useState('');

  	const fetchStockHistory = async () => {
	    // Create URL parameters
	    const params = new URLSearchParams();
	    params.append('draw', draw);
	    params.append('start', start);
	    params.append('length', length);

	    // Construct API URL with query parameters
	    //const apiUrl = `${API_URL}/customer_not_order_report?${params.toString()}`;
	    const apiUrl = `${API_URL}/product/get_stock_take_history?${params.toString()}`;

	    const accessToken = localStorage.getItem('accessToken');      
	    if (accessToken === null) {
	        window.location.href = `${config.BASE_URL}/login`; 
	        return;
	    }

	    try {
	        const response = await fetch(apiUrl, {
	            method: 'GET',
	            headers: {
	                authorization: `Bearer ${accessToken}`,
	            },
	        });

	        if (!response.ok) {
	            throw new Error('Failed to fetch Not Order Report');
	        }
	        const data = await response.json();
	        setStockHistory(data.data);
	        //console.log(data);

	    } catch (error) {
	        console.error('api fail Error fetching Not Order Report:', error);
	    }
	};

	useEffect(() => {
    	const table = $('#stockHistoryReport').DataTable({
      		pageLength: 5000,
      		paging: true,
      		lengthMenu: [10, 20, 50, 5000],
      		searching: true,
      		ordering: false,
      		info: true,
      		responsive: true,
      		autoWidth: false,
      		language: {
	        	paginate: {
	          		first: 'First',
	          		last: 'Last',
	          		next: 'Next',
	          		previous: 'Previous',
	        	},
	        	search: 'Search:',
	      	},
      		data: stockHistory,
      		columns: [
        		{ data: 'uniqueStockId',className: 'text-start' },
        		{ data: 'categories', width: '9%', className : 'text-center' },
        		{ data: 'brands', width: '9%', className : 'text-center' },
        		{ data: 'start_date', width: '9%', className : 'text-center' },
        		{ data: 'end_date', width: '9%', className : 'text-center' },
        		{ 
        			data: null, 
        			width: '6%', 
        			className : 'text-center',
        			render: function (data, type, row) {
        				return '<a style="display: contents;" class="deductionbtn" href="'+BASE_URL+'/products/show_history_of_stock_take_list/' + row.id + '"><i class="fa fa-eye"></i></a>';

        			}
        		},
        		
    		],
    		drawCallback: function (settings) {
        		var api = this.api();
        		
    		}
    	});
    	return () => {
      		table.destroy();
    	};
  	}, [stockHistory]);


  	useEffect(() => {
    	fetchStockHistory();
  	},[]);

	return(
		<>	
			<Header />
			<div className="breadcrumbs">
		        <div className="container-fluid">
		          <div className="row">
		            <div className="col-12 col-lg-12 col-sm-12"> 
		              <Link to="/Dashboard"><i className="fa fa-home"></i></Link>  
		              <Link to="/stock_take/history">Stock Take History </Link> 
		            </div>
		          </div>
		       	</div>
		    </div>
		    <div className="main-container">
			    <div className="container-fluid">

			    	

	                    {/* <p><b>Stock Take History : </b></p> */}
	                    <div style={{ float: 'right' }}>
	                    	<Link to={`${BASE_URL}/stock_take/start_stock_take`}>
		    					<button style={{marginRight: '15px'}} type="button" class="multisubmit">Start Stock Take</button>
		    				</Link>
		    				
		    				<Link to={`${BASE_URL}/stock_take/stock_take_list`}>
		    					<button type="button" class="multisubmit">On Going Stock Take</button>
		    				</Link>
		    			</div>

						<div className="total-manifests-area dashboard" style={{position : 'relative'}}>
	                    <div className="verical-scrl-wrapper common-table autoheight-table stake-history" id="style-3">
	                        <table className="table table-bordered dataTable resizable" id="stockHistoryReport">
	                            <thead>
	                                <tr>
	                                    <th>Unique Id</th>
	                                    <th>Categories</th>
	                                    <th>Brands</th>
	                                    <th>Start Date</th>
	                                    <th>End Date</th>
	                                    <th>Action</th>
	                                </tr>
	                            </thead>
	                            <tbody>
									
	                                
	                            </tbody>
	                        </table>
	                    </div>
	                </div>

			    </div>
			</div>

		    <Footer />

		</>
	);
}
export default StockTakeHistory;