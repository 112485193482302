import axios from 'axios';
import Swal from 'sweetalert2';
import config from '../components/common/Config';


const accessToken = localStorage.getItem('accessToken');

export const fetchProductList = async (setProductData, selectedOption,setCustomerDetails,setCompanyAddress,customeId) => 
{


	const formData = new FormData();
  formData.append("platform", 'web');
  formData.append("authorization", accessToken);
  formData.append("customer_id", customeId);


  const apiUrl = `${config.API_URL}/ws_get_products_pos`;
  try {
    const response = await axios.post(apiUrl, formData, {

    });
    
    const data = await response.data;	   
    const formattedOptions = data.product_details.map(item => ({
     label: `${item.product_name || 'N/A'} - ${item.product_code || 'N/A'}`,  
     value: item.inner_ean_number,    
   }));

	// console.log(data.company_address);
    setCustomerDetails(data.customer);
    setCompanyAddress(data.company_address);    
    setProductData(formattedOptions);


  } catch (error) {
    console.error("Error fetching products data:", error);
  }
};

export const handleEposProductAdd = async(e,barcode,tableRows, setTableRows, customeId, handleQuantityChange,accessToken)=>{
  let id;  

  if (barcode == 0) {
    id = e?.value;  
  } else {
    id = e;  
  }

  if (id == null) return;
  const found = Array.isArray(tableRows) ? tableRows.find(element => (String(element.id) === String(id) || element.inner_ean_number === id) && String(element.is_promoted) === String(0)) : false;

  if (found) 
  {  

    let newQty = 0;
    let promosType = '';
    let promosId = '';
    const updatedRows = tableRows.map(row => 
    {

      if ((String(row.id) === String(id) || row.inner_ean_number === id) && String(row.is_promoted) === String(0)) 
      {  


        const order_qty = parseFloat(row.order_qty) + 1 || 0;
        newQty = parseFloat(row.order_qty) + 1;
        promosType = row.promosType;
        promosId = row.promosId;

        const unitCost = parseFloat(row.pdtcost) || 0;
        const rate = parseFloat(row.rate) || 0;
        const subtotal = (order_qty * unitCost).toFixed(2);
       

        const discountPercentage = parseFloat(row.discount) || 0; 
        const discountAmount = (parseFloat(subtotal) * discountPercentage / 100).toFixed(2);
        const vat = (((order_qty * unitCost)-discountAmount) * rate / 100).toFixed(2);
        const totalBeforeDiscount = (parseFloat(subtotal) + parseFloat(vat)).toFixed(2);
        const total = (parseFloat(totalBeforeDiscount) - parseFloat(discountAmount)).toFixed(2);


        return {
          ...row,
          order_qty: row.order_qty + 1,
          subtotal:subtotal-discountAmount,
          vat,
          total, 
        };
      }
      return row;
    });

    setTableRows(updatedRows);


    try {
      const updatedRows = [...tableRows]; 
      const currQty = parseInt(newQty);

      let totalOrderQty;
      if(promosType == "combo"){
       totalOrderQty = tableRows.reduce((sum, row) => {
            // Check if row matches the condition
        if (row.promosId == promosId && row.promosType == "combo") {
          if (row.id == id) {
                    return sum + currQty;  // Add newQty for the row with the matching id
                  } else {
                    return sum += row.order_qty;  // Add the order_qty for other matching rows
                  }
                }else{

                  return  sum; 
                }
              }, 0); 
     }else{
       totalOrderQty = parseInt(newQty);
     }




     for (let row of updatedRows) {


      if (row.id === id || row.inner_ean_number === id) 
      {
        const order_type = row.split === '0' || row.split === '2' ? '0' : '1';
        const secondApiUrl = `${config.API_URL}/ws_check_Product_Promation`;
        const secondConfig = 
        {
          headers: { 'authorization': `Bearer ${accessToken}`},
        };

        const secondFormData = new FormData();
        secondFormData.append("orderId", 0);  
        secondFormData.append("productId", id);
        secondFormData.append("order_type", order_type);
        secondFormData.append("qty", totalOrderQty); 


        const secondResponse = await axios.post(secondApiUrl, secondFormData, secondConfig);
        const secondData = secondResponse.data.item;

        console.log("Second API response:", secondData);


        if (secondData && secondData.is_promoted === "1") 
        {
          const promotionUnitPrice = parseFloat(secondData.unit_price) || 0;
          const promotionSubtotal = parseFloat(secondData.subtotal) || 0;
          const promotionQuantity = parseFloat(secondData.quantity) || 0;


          const existingProductIndex = tableRows.findIndex(row => row.id === secondData.product_id && row.is_promoted == secondData.is_promoted  );

          if (promotionQuantity === 0) 
          {
              setTableRows(prevRows => prevRows.filter(row => secondData.product_id !== row.id)); // Remove item if quantity is 0
              return;
            }


            if (existingProductIndex !== -1) {
              setTableRows(prevRows => prevRows.map((row, index) => {
                if (index === existingProductIndex) {
                  const itemTax = isNaN(secondData.item_tax) || secondData.item_tax === null || secondData.item_tax === '' ? 0 : secondData.item_tax;

                  const updatedRow = {
                    ...row,
                    order_qty: promotionQuantity,  
                    subtotal: promotionSubtotal.toFixed(2),
                    vat: (promotionSubtotal * formatNumber(itemTax) / 100).toFixed(2),
                    total: (promotionSubtotal + (promotionSubtotal * formatNumber(itemTax) / 100)).toFixed(2),
                  };
                  return updatedRow;
                }
                return row;
              }));
            } else {

              const split = secondData.order_type === "box" ? 0 : 1;

              const newRow1 = {
                code: secondData.product_code,
                id: secondData.product_id,
                price: promotionUnitPrice.toFixed(2),
                piece_cost: promotionUnitPrice.toFixed(2),
                split: split,
                type: secondData.order_type === "box" ? "box" : "piece",
                split_quantity: secondData.unit_quantity,
                split_price: promotionUnitPrice.toFixed(2),
                name: secondData.product_name,
                size: secondData.size,
                cost: 0,
                rate: secondData.item_tax ? parseFloat(secondData.item_tax) : 0,
                quantity: promotionQuantity,
                pdtcost: promotionUnitPrice.toFixed(2),
                order_qty: promotionQuantity,
                vat: 0.00,
                subtotal: 0.00,
                total: 0.00,
                is_promoted: secondData.is_promoted,
              };

              
              setTableRows(prevRows => [...prevRows, newRow1]);
            }
          }
        }
      }
    } catch (error) {
      console.error("Error checking promotion:", error);
    }
    
    return;

  }

  const formData = new FormData();
  formData.append("customer_id", customeId);
  formData.append("pattern", id);
  formData.append("platform", 'pos');
  formData.append("authorization", accessToken);
  const apiUrl = `${config.API_URL}/ws_get_products_pos1`;
  try 
  {
   const response = await axios.post(apiUrl, formData, 
   {

   });

   const data = await response.data;
   const productData = data.product_details[0];  

   const unitCost = productData.split == '0' || productData.split == '2'
   ? (productData.productDiscountApplicable === "1" 
     ? parseFloat(productData.discountedBoxPrice) 
     : parseFloat(productData.price))
   : (productData.productDiscountApplicable === "1" 
     ? parseFloat(productData.discountedSplitPrice) 
     : parseFloat(productData.split_price));

   const newRow = {
     code: productData.product_code,
     id: productData.id,
     price: productData.productDiscountApplicable === "1" ? productData.discountedBoxPrice : productData.price,
     piece_cost: productData.productDiscountApplicable === "1" ? productData.discountedSplitPrice : productData.split_price,
     split: productData.split,
     type: productData.split === '0' || productData.split === '2' ? 'box' : 'piece',
     split_quantity: productData.split_quantity,
     split_price: productData.productDiscountApplicable === "1" ? productData.discountedSplitPrice : productData.split_price,
     name: productData.product_name,
     size: productData.size,
     cost: productData.cost,
     rate: productData.rate,
     inner_ean_number: productData.inner_ean_number,
     quantity: 1,
     pdtcost: unitCost.toFixed(2),
     order_qty: 1,
     subtotal: (unitCost * 1).toFixed(2),
     vat: ((unitCost * 1) * formatNumber(productData.rate) / 100).toFixed(2),
     total: ((unitCost * 1) + ((unitCost * 1) * formatNumber(productData.rate) / 100)).toFixed(2),
     promosId : productData.promosId,
     promosType : productData.promosType || 'no-offer',
     is_promoted : 0,
   };

   console.log(newRow);

   const newPromosId = productData.promosId;          
   setTableRows(prevRows => [newRow,...prevRows]);
   const totalQty = tableRows.reduce((sum, row) => {

    if (row.promosId == newPromosId && row.promosType == "combo") {
      return sum + (row.order_qty + 1); 
    }
    return sum;
  }, 0); 

   const productId = productData.id;
   const order_type= productData.split === '0' || productData.split === '2' ? '0' : '1';
   const secondApiUrl = `${config.API_URL}/ws_check_Product_Promation`;

   const secondConfig = {
    headers: { 'authorization': `Bearer ${accessToken}` },
  };

  const secondFormData = new FormData();
  secondFormData.append("orderId", 0);
  secondFormData.append("productId", productId);
  secondFormData.append("order_type", order_type);
  secondFormData.append("qty", 1);

  const secondResponse = await axios.post(secondApiUrl, secondFormData, secondConfig);
  const secondData = secondResponse.data.item;

  console.log("Second API response:", secondData);

        // Handle the promotion data
     //   if (secondData && secondData.success && secondData.is_promoted === "1") 
     //   {

       //   alert("ggggg")
            const promotionUnitPrice = parseFloat(secondData.unit_price) || 0; // Use the promoted price (0 if promotion sets it to 0)
            const promotionSubtotal = parseFloat(secondData.subtotal) || 0; // Use the promoted subtotal (0 if it's discounted)
            const promotionQuantity = parseFloat(secondData.quantity) || 0; // Quantity after promotion (should be the same)

             // Check if product with the same ID already exists
            const existingProductIndex = tableRows.findIndex(row => row.id === secondData.product_id && row.is_promoted == secondData.is_promoted);

                    // If the product already exists, just update it (do not insert a duplicate)

            if (promotionQuantity === 0) {
              setTableRows(prevRows => prevRows.filter(row => secondData.product_id !== productData.id));
        return; // Exit early, no need to proceed with API or further state changes
      }
      if (existingProductIndex !== -1) {
        setTableRows(prevRows => prevRows.map((row, index) => {
          if (index === existingProductIndex) {
            const updatedRow = {
              ...row,
                                    order_qty: promotionQuantity,  // Update quantity based on promotion
                                    subtotal: promotionSubtotal.toFixed(2),
                                    vat: (promotionSubtotal * formatNumber(secondData.item_tax) / 100).toFixed(2),
                                    total: (promotionSubtotal + (promotionSubtotal * formatNumber(secondData.item_tax) / 100)).toFixed(2),

                                    is_promoted :  secondData.is_promoted ? secondData.is_promoted : 0,
                                  };
                                  return updatedRow;
                                }
                            return row; // Keep other rows unchanged
                          }));
      } else {

       const split = secondData.order_type === "box" ? 0 : 1; 
       const newRow1 = {
        code: secondData.product_code,
        id: secondData.product_id,
        price: promotionUnitPrice.toFixed(2),
        piece_cost: promotionUnitPrice.toFixed(2),
        split: split,
        type: secondData.order_type === "box" ? "box" : "piece",
        split_quantity: secondData.unit_quantity,
        split_price: promotionUnitPrice.toFixed(2),
        name: secondData.product_name,
        size: secondData.size,
                cost: 0, // Assuming promotion means no cost
                rate: secondData.item_tax ? parseFloat(secondData.item_tax) : 0,
                quantity: promotionQuantity,
                pdtcost: promotionUnitPrice.toFixed(2),
                order_qty: promotionQuantity,
              //  subtotal: promotionSubtotal.toFixed(2),
                subtotal:0.00,
            //    vat: (promotionSubtotal * formatNumber(secondData.item_tax) / 100).toFixed(2),
                vat:0.00,
                total:0.00,
                is_promoted :  secondData.is_promoted,
                promosId : '',
                promosType : '',
               // total: (promotionSubtotal + (promotionSubtotal * formatNumber(secondData.item_tax) / 100)).toFixed(2),
              };

            // Add the promotion row to the table
              setTableRows(prevRows => [...prevRows, newRow1]);
            }



          } catch (error) {
            console.error("Error fetching products data:", error);
          }

        };

        const formatNumber = (num) => {
          return parseFloat(num).toFixed(2);
        };



        export const fetchEposSearchCustomer = async(inputValue = 'ramee', customerInfo, setCustomerInfo, setCustomerData,accessToken,tableRows)=>
        { 



          if (inputValue.length < 2) 
          {
            setCustomerData([]); 
            return; 
          }

          const formData = new FormData();
          formData.append("platform", 'web');
          formData.append("pattern", inputValue);
          formData.append("authorization", `Bearer ${accessToken}`);

          const apiUrl = `${config.API_URL}/ws-get-customer-list`;
          try {
            const response = await axios.post(apiUrl, formData, {

            });


            if(tableRows.length >=1)
            {

             Swal.fire({
              title: 'Are you sure?',
              text: "You won't be able to revert this!",
              icon: 'warning',
              showCancelButton: true,  
              confirmButtonText: 'OK',
              cancelButtonText: 'Cancel',
              reverseButtons: true  
            }).then((result) => 
            {

              if (result.isConfirmed) 
              {
               window.location.reload(); 

              } else 
              {

                console.log("Action was cancelled");
              }
            });

          }








          
          const data = await response.data;
          const formattedOptions = Array.isArray(data.customer_details) 
          ? data.customer_details.map(item => ({
            label: item.name,
            value: item.id,
          }))
          : [];

          setCustomerData(formattedOptions);


        } catch (error) 
        {
          console.error("Error fetching products data:", error);
        }

      };

      export default { fetchProductList, handleEposProductAdd, fetchEposSearchCustomer };
