import React from 'react';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import config from "../common/Config";
import Swal from 'sweetalert2';
import Epos from '../sales/Epos';
import printOrderInvoice from './printOrderInvoice';

const Incomplete_Order = ({ setIncompleteOrder, incompleteOrder, setTableRow,   setCustomeId,  setDiscountQty,  setSelectedCustomer, getProductListChange, setSalesId, setSalesStatus, setSalesRefNo, tillUserName ,setreferenceNo}) => {
  const accessToken = localStorage.getItem('accessToken');
  // const [orderData, setOrderData] = useState(null);
  const firstName = localStorage.getItem("first_name");
  const lastName = localStorage.getItem("last_name");

  const handleEdit = async (id) => 
  {
    setTableRow([]);
    try {
      const response = await fetch(`${config.API_URL}/ws-fetch-place-order/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'authorization': `Bearer ${accessToken}` 
        },      
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setTableRow([]);    
      const { product_details, orderDetails, shop_details } = data.details;

      //setSelectedCustomer(orderDetails[0].name);
      if (orderDetails.length > 0) 
      {
        setCustomeId(orderDetails[0].customer_id);
        const selectedCustomer = {
          label: orderDetails[0].company, // Assuming this is the correct structure
          value: orderDetails[0].customer_id // Assuming this is the correct ID
        };
        setSelectedCustomer(selectedCustomer); 
        setDiscountQty(parseInt(orderDetails[0].order_discount));
        getProductListChange(orderDetails[0].name, orderDetails[0].customer_id);
        setSalesId(orderDetails[0].id);
        setSalesStatus("Incomplete");
        setSalesRefNo(orderDetails[0].reference_no);
        setreferenceNo(orderDetails[0].reference_no);

      }

      setTableRow([]);
      const newRows = product_details.map(productData => {
        const unitCost = productData.split === '0' || productData.split === '2'
        ? (productData.productDiscountApplicable === "1" 
         ? parseFloat(productData.discountedBoxPrice) 
         : parseFloat(productData.price))
        : (productData.productDiscountApplicable === "1" 
         ? parseFloat(productData.discountedSplitPrice) 
         : parseFloat(productData.piece_cost));

          let pdtcost;
        if(productData.order_type=='piece')
        {
         pdtcost= productData.piece_cost;    
       }else
       {        
        pdtcost= productData.price;
      }
      const subtotal = ((pdtcost * productData.order_qty) - parseFloat(productData.item_discount || 0)).toFixed(2);
      
      const vat = ((pdtcost * productData.order_qty) * (parseFloat(productData.rate) / 100)).toFixed(2);
      const total = (parseFloat(subtotal) + parseFloat(vat)).toFixed(2);
    
      


      
      return {
        item_id : productData.id,
        code: productData.code,
        id: productData.product_id,
        price: productData.productDiscountApplicable === "1" ? productData.discountedBoxPrice : productData.price,
        piece_cost: productData.productDiscountApplicable === "1" ? productData.discountedSplitPrice : productData.piece_cost,

        split: productData.split,
        type: productData.split === '0' || productData.split === '2' ? 'box' : 'piece',
        split_quantity: productData.order_qty,
        split_price: productData.productDiscountApplicable === "1" ? productData.discountedSplitPrice : productData.piece_cost,
        name: productData.product_name,
        size: productData.size,
        cost: '',
        rate: productData.rate,
        quantity: parseInt(productData.order_qty),
        pdtcost: pdtcost,
        order_qty: parseInt(productData.order_qty),
        subtotal: subtotal,
        vat: productData.item_tax,
        total: total,
        discount: productData.discount,
        discountValue: productData.item_discount,
        promosId : productData.promosId,
        promosType : productData.promosType || 'no-offer',
        is_promoted : productData.is_promoted,
        inner_ean_number : productData.inner_ean_number,
        orderType : productData.order_type,
      };
    });

    // Set new table rows
      setTableRow(newRows);


    } catch (error) {
      console.error('Error:', error);
      
    }
  };

  const handleDelete = async (id) => {
  // Show confirmation dialog first
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    });

  // If the user confirms, proceed with the deletion
    if (result.isConfirmed) {
      try {
        const response = await fetch(`${config.API_URL}/ws-delete-pos-order/${id}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${accessToken}`
          },
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json(); 

      // Remove the deleted order from the state
        setIncompleteOrder(prevOrders => prevOrders.filter(order => order.id !== id));

      // Show success message
        Swal.fire('Deleted!', 'The order has been deleted.', 'success');
      } catch (error) {
        console.error('Error deleting item:', error);
      // Optionally show an error message to the user
        Swal.fire('Error!', 'There was a problem deleting the order.', 'error');
      }
    } else {
    // Optionally, handle if the user cancels the deletion (this part is not strictly necessary)
      console.log('Deletion canceled');
    }
  };

  const handleGenerateOrderReceipt = (id) => {
    let data = incompleteOrder.find((item)=> item.id === id);
    let selectedCustomer = {
      label : data.name
    };
    let salesRefNo = data.reference_no;
    printOrderInvoice(selectedCustomer, tillUserName, salesRefNo, firstName, lastName);
  };
  

  return (

     // <Epos orderData={orderData} />
    <div className="container">
    <div className="modal fade" id="incomplete-order" tabIndex="-1">
    <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content">
    <div className="modal-header">
    <h5 className="modal-title">Incomplete Order</h5>
    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <div className="modal-body">
    <div className="incomplete-order-table">
    <table className="table table-bordered resizable">
    <thead>
    <tr>
    <th>Sr. No</th>
    <th>Invoice No.</th>
    <th>Customer Name</th>
    <th>Date</th>
    <th>Action</th>
    </tr>
    </thead>
    <tbody>
    {incompleteOrder.map((row, index) => (
                      <tr key={row.id}> {/* Use row.id as key */}
      <td>{index + 1}</td>
      <td>{row.reference_no}</td>
      <td>{row.name}</td>
      <td>{row.date}</td>
      <td>
      <a data-bs-dismiss="modal" aria-label="Close" href="#" onClick={() => handleEdit(row.id)}>
      <i className="bi bi-file-earmark-x-fill"></i>
      </a>

      <a href="#" onClick={() => handleDelete(row.id)}>
      <i className="bi bi-trash-fill"></i>
      </a>
      <a href="#" onClick={() => handleGenerateOrderReceipt(row.id)}>
      <i className="bi bi-printer-fill"></i>
      </a>
      </td>
      </tr>
      ))}
    </tbody>
    </table>
    </div>
    </div>
    <div className="modal-footer">
    <button type="button" className="closebtn" data-bs-dismiss="modal">Cancel</button>
              {/* <button type="button" className="printbtn">Save & Print</button> */}
    </div>
    </div>
    </div>
    </div>
    </div>
    );
  };

  export default Incomplete_Order;
