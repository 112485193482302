import React, { useState, useEffect, useRef, useCallback  } from 'react';
import Select from 'react-select';
import axios from "axios";
import Header from '../Header';
import Footer from '../Footer';
import FlashMessage from '../FlashMessage';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { API_URL, BASE_URL } from '../Config';
import config from '../common/Config';
import PurchaseService from '../../services/PurchaseService';



const PurchasesHistory = () => {

	const [draw, setDraw] = useState(1);
  	const [start, setStart] = useState(0);
  	const [length, setLength] = useState(50);
  	const [purchaseGrn, setPurchaseGrn] = useState([]);

	const accessToken = localStorage.getItem('accessToken'); 
	const fetchPurchaseGrnList = async () => {
		const params = new URLSearchParams();
	    params.append('draw', draw);
	    params.append('start', start);
	    params.append('length', length);

	    const apiPurchaseOrderUrl = `${API_URL}/purchase/purchaseHistory?${params.toString()}`;
	    try {
	        const response = await fetch(apiPurchaseOrderUrl, {
	            method: 'GET',
	            headers: {
	                authorization: `Bearer ${accessToken}`,
	            },
	        });

	        if (!response.ok) {
	            throw new Error('Failed to fetch purchase order');
	        }
	        const data = await response.json();
	        setPurchaseGrn(data.data);
	        //console.log(data);

	    } catch (error) {
	        console.error('api Error fetching purchase order:', error);
	    }
	};

	/*useEffect(() => {

  		if (accessToken === null){
            window.location.href = `${config.BASE_URL}/login`; 
            return;
        }
        
        fetchPurchaseGrnList();
  	},[]);*/

  	useEffect(() => {
        if (accessToken === null) {
            window.location.href = `${config.BASE_URL}/login`; 
            return;
        }
        const loadPurchaseGrnList = async () => {
            try {
                const data = await PurchaseService.fetchPurchaseGrnList(draw, start, length, accessToken);
                setPurchaseGrn(data);
            } catch (error) {
                console.error('Failed to load purchase GRN list:', error);
            }
        };

        loadPurchaseGrnList();
    }, [draw, start, length, accessToken]);

  	useEffect(() => {
		  // Initialize DataTable
		  const table = $('#purchaseGrnTable').DataTable({
		    pageLength: 50,
		    paging: true,
		    lengthMenu: [10, 20, 50],
		    searching: true,
		    ordering: false,
		    info: true,
		    responsive: true,
		    autoWidth: false,
		    language: {
		      paginate: {
		        first: 'First',
		        last: 'Last',
		        next: 'Next',
		        previous: 'Previous',
		      },
		      search: 'Search:',
		    },
		    data: purchaseGrn,
		    columns: [
		      	{ data: 'date', width: '8%' },
		      	{ data: 'Po', width: '8%' },
		      	{ data: 'Grn', width: '8%' },
		      	{ data: 'invoice_number', width: '9%' },
		      	{ data: 'supplier' },
		      	{ data: 'total_tax', width: '6%' },
		      	{ data: 'grand_total', width: '8%' },
		      	{
	          		data: null,
	          		width : '5%',
	          		className : 'text-center',
	          		render: function (data, type, row) {
	          			return '<a class="icon viewbtn" title="View" href="'+config.BASE_URL+'/purchases/view_grn/' + row.id +'"  id="mark_deliver">  <i class="fa fa-eye"></i></a>';
	        		}
	        	}
		    ],
		    drawCallback: function (settings) {
		    }
		  });

		  

		  return () => {
		    table.destroy();
		  };
		}, [purchaseGrn]);

	return(
		<>
			<Header />
			<div className="breadcrumbs">
	          	<div className="container-fluid">
		            <div className="row">
		                <div className="col-12 col-lg-12 col-sm-12"> 
		                  <Link to="/Dashboard"><i className="fa fa-home"></i></Link>   
		                  <Link to="/reports/mis_report">Purchases</Link> 
		                  <Link to="/reports/mis_report">Purchases GRN</Link> 
		                </div>
		            </div>
	          	</div>
	      	</div>
	      	<div className="main-container">
			    <div className="container-fluid">

			    	<div className="total-manifests-area dashboard" style={{position : 'relative'}} >
	                    <p><b>GRN </b></p>
	                    
	                    <div className="verical-scrl-wrapper common-table autoheight-table purchase-history" id="style-3">
	                        <table className="table table-bordered dataTable resizable" id="purchaseGrnTable">
	                            <thead>
	                                <tr>
	                                    <th>Date</th>
	                                    <th>PO No</th>
	                                    <th>Grn No</th>
	                                    <th>Invoice No</th>
	                                    <th>Supplier</th>
	                                    <th>VAT</th>
	                                    <th>Grand Total</th>
	                                    <th>Action</th>
	                                    
	                                </tr>
	                            </thead>
	                            <tbody>
	                                
	                            </tbody>
	                            <tfoot>

					            </tfoot>
	                        </table>
	                    </div>
	                </div>

			    </div>
			</div>
	      	<Footer />

		</>
	);

}
export default PurchasesHistory;