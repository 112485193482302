import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import $ from "jquery";
import "datatables.net-bs5";
import { useParams } from "react-router-dom";
import { API_URL, BASE_URL } from "../Config";
import config from "../common/Config";
import jsPDF from "jspdf"; // Library to generate PDF
import "jspdf-autotable"; // Plugin for auto table
import printNewSalesInvoice from "./printNewSalesInvoice";
import printNewSalesInvoice_tsc from "./printNewSalesInvoice_tsc";
import printNewSalesInvoice_knm from "./printNewSalesInvoice_knm";
import printNewQuoteInvoice from "./printNewQuoteInvoice";

const NewSalesInvoice = (props) => {
  const { orderId } = useParams();
  const [shopDetails, setShopDetails] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);
  const [productDetails, setProductDetails] = useState([]);
  const [totalVal, setTotalVal] = useState({
    totalstd: 0,
    totalzeroGoods: 0,
    totalTax: 0,
    orderDiscount: 0,
    grandTotal: 0,
  });

  const accessToken = localStorage.getItem("accessToken");

  const fetchInvoice = async () => {
    const formData = new FormData();
    formData.append("orderId", orderId);
    const apiUrl = `${API_URL}/ws-new-sales-details`;
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to fetch Not Order Report");
      }
      const data = await response.json();
      //console.log(data.details.shop_details);
      setShopDetails(data.details.shop_details);
      setOrderDetails(data.details.orderDetails[0]);
      setProductDetails(data.details.product_details);

      const totalstd = data.details.product_details
        .filter((item) => item.item_tax != "0.00" && item.item_tax != null)
        .reduce((sum, item) => {
          return sum + parseFloat(item.subtotal);
        }, 0);

      const totalzeroGoods = data.details.product_details
        .filter((item) => item.item_tax == "0.00" || item.item_tax === null)
        .reduce((sum, item) => {
          return sum + parseFloat(item.subtotal);
        }, 0);

      let totalDiscountOnVat = 0;

      if (parseFloat(data.details.orderDetails[0].over_all_discount) > 0) {
        totalDiscountOnVat += parseFloat(
          data.details.orderDetails[0].over_all_discount
        );
      }

      if (data.details.orderDetails[0].percentage !== null) {
        totalDiscountOnVat += parseFloat(
          data.details.orderDetails[0].percentage
        );
      }

      let totalTax = data.details.product_details.reduce(
        (sum, item) => sum + (item.item_tax ? parseFloat(item.item_tax) : 0),
        0
      );

      //  alert(totalDiscountOnVat);
      totalTax = totalTax - (totalTax * totalDiscountOnVat) / 100;

      const orderDiscount =
        parseFloat(data.details.orderDetails[0].order_discount) || 0;
      const orderDiscount1 =
        parseFloat(data.details.orderDetails[0].order_discount1) || 0;
      const over_all_discount =
        parseFloat(data.details.orderDetails[0].over_all_discount) || 0;
      const over_all_amount =
        parseFloat(data.details.orderDetails[0].over_all_amount) || 0;

      const grandTotal =
        totalstd + totalzeroGoods + totalTax - over_all_amount - orderDiscount1;

      setTotalVal({
        totalstd,
        totalzeroGoods,
        totalTax,
        orderDiscount,
        orderDiscount1,
        over_all_discount,
        over_all_amount,
        grandTotal,
      });
    } catch (error) {
      console.error("api fail Error fetching Not Order Report:", error);
    }
  };

  useEffect(() => {
    if (accessToken === null) {
      window.location.href = `${config.BASE_URL}/login`;
      return;
    }
    fetchInvoice();
  }, []);

  useEffect(() => {
    const table = $("#invoicePrint").DataTable({
      pageLength: 50,
      paging: false,
      lengthMenu: [10, 20, 50],
      searching: false,
      ordering: false,
      info: false,
      responsive: true,
      autoWidth: false,
      data: productDetails,
      columns: [
        {
          data: null,
          render: function (data, type, row) {
            if (row.discount == "100.00" || row.discount == 100.0) {
              return `<span style="color: red;">${row.product_code} - ${row.product_name}- ${row.size}</span>`;
            } else {
              return `<span>${row.product_code} - ${row.product_name} - ${row.size}</span> `;
            }
          },
        },
        {
          data: null,
          className: "text-center",
          render: function (data, type, row) {
            if (row.discount == "100.00" || row.discount == 100.0) {
              return `<span style="color: #f14240;">${parseInt(
                row.quantity
              )}</span>`;
            } else {
              return `<span>${parseInt(row.quantity)}</span>`;
            }
          },
        },
        {
          data: null,
          className: "text-center",
          render: function (data, type, row) {
            if (row.discount == "100.00" || row.discount == 100.0) {
              return `<span style="color: red;">${row.order_type}</span>`;
            } else {
              return `<span>${row.order_type}</span>`;
            }
          },
        },
        {
          data: "unit_price",
          className: "text-end",
          width: "10%",
          render: function (data, type, row) {
            if (row.discount == "100.00" || row.discount == 100.0) {
              return row.unit_price
                ? `<span style="color: red;">£ ${row.unit_price}</span>`
                : `<span style="color: red;">£ 0.00</span>`;
            } else {
              return row.unit_price
                ? `<span>£ ${row.unit_price}</span>`
                : `<span>£ 0.00</span>`;
            }
          },
        },
        {
          data: "item_discount",
          className: "text-end",
          width: "10%",
          render: function (data, type, row) {
            let a = Number(row.item_discount) || 0;
            if (row.discount == "100.00" || row.discount == 100.0) {
              return `<span style="color: red;">£ ${a.toFixed(2)}</span>`;
            } else {
              return `<span>£ ${a.toFixed(2)} </span>`;
            }
          },
        },
        {
          data: "item_tax",
          className: "text-end",
          width: "10%",
          render: function (data, type, row) {
            let a = Number(row.item_tax) || 0; // Ensure it's a valid number, defaulting to 0
            if (row.discount == "100.00" || row.discount == 100.0) {
              return `<span style="color: red;">£ ${a.toFixed(2)}</span>`;
            } else {
              return `<span> ${a.toFixed(2)} </span>`;
            }
          },
        },
        {
          data: "subtotal",
          className: "text-end",
          width: "10%",
          render: function (data, type, row) {
            if (row.discount == "100.00" || row.discount == 100.0) {
              return row.subtotal
                ? `<span style="color: red;">£ ${parseFloat(
                    row.subtotal
                  ).toFixed(2)}</span>`
                : `<span>£ 0.00</span>`;
            } else {
              return row.subtotal
                ? `<span>£ ${parseFloat(row.subtotal).toFixed(2)}</span>`
                : `<span>£ 0.00</span>`;
            }
          },
        },
      ],
      columnDefs: [
        {
          targets: [0],
          orderable: false,
        },
      ],
      drawCallback: function (settings) {
        var api = this.api();
        $("tfoot").empty();

        // Create footer rows with totals
        const footerRows = [
          {
            colSpan: 6,
            label: "Total Std Goods",
            value: `£ ${totalVal.totalstd.toFixed(2)}`,
          },
          {
            colSpan: 6,
            label: "VAT Amount",
            value: `£ ${totalVal.totalTax.toFixed(2)}`,
          },
          {
            colSpan: 6,
            label: "Total Zero Goods",
            value: `£ ${totalVal.totalzeroGoods.toFixed(2)}`,
          },
          {
            colSpan: 6,
            label: "Promotion Discount",
            value:
              totalVal.orderDiscount1 != null
                ? `£ ${totalVal.orderDiscount1.toFixed(2)}`
                : "£ 0.00",
          },
          {
            colSpan: 6,
            label: `Order Discount(${totalVal.over_all_discount}%)`,
            value: `£ ${
              totalVal && totalVal.over_all_amount
                ? totalVal.over_all_amount.toFixed(2)
                : "0.00"
            }`, // Check for totalVal and orderDiscount1
          },
          {
            colSpan: 6,
            label: "Invoice Total",
            value: `£ ${
              totalVal &&
              totalVal.grandTotal &&
              totalVal.orderDiscount1 !== undefined
                ? totalVal.grandTotal.toFixed(2)
                : "0.00"
            }`,
          },
        ];

        footerRows.forEach((row) => {
          const footerRow = $("<tr>")
            .append(
              `<td colSpan="${row.colSpan}"><span class="right">${row.label}</span></td>`
            )
            .append(
              `<td style="text-align : right;" ><span>${row.value}</span></td>`
            );
          $("tfoot").append(footerRow);
        });
      },
    });
    return () => {
      table.destroy();
    };
  }, [productDetails, totalVal]);

  const handleGenerateDeliveryNotePDF = (e, orderId) => {
    if (accessToken == "tsc" || accessToken == "tss") {
      printNewSalesInvoice_tsc(orderId);
    } else if (accessToken == "knm") {
      printNewSalesInvoice_knm(orderId);
    } else {
      printNewSalesInvoice(orderId);
    }
  };

  const handleGenerateQuotePDF = (e, orderId) => {
    printNewQuoteInvoice(orderId);
  };

  return (
    <>
      <Header />
      <div className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <Link to="/Dashboard">
                <i className="fa fa-home"></i>
              </Link>

              <span className="last-crumb">New Sales Invoice </span>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="show-order-area dashboard">
            <div className="row" style={{ margin: "10px" }}>
              <div class="col-7">
                {/* <button className='btn btn-primary btn-sm' style={{margin:'10px'}}
                onClick={() => test()}
              >
                Print
              </button> */}
                <button
                  className="btn btn-success btn-sm"
                  style={{ margin: "10px" }}
                  onClick={(e) => handleGenerateDeliveryNotePDF(e, orderId)}
                >
                  Print Invoice
                </button>
                <button
                  className="btn btn-info btn-sm"
                  style={{ margin: "10px" }}
                  onClick={(e) => handleGenerateQuotePDF(e, orderId)}
                >
                  Print Quote
                </button>
              </div>
            </div>
            <div className="sales-view-area" id="content-to-print">
              <div className="row page-header" style={{ paddingTop: "10px" }}>
                <div className="col-7">
                  <p>
                    <span>{orderDetails.company}</span>
                    <br />
                    {orderDetails.address}
                    <br />
                    {orderDetails.city}, {orderDetails.postal_code},{" "}
                    {orderDetails.country}
                    <br />
                  </p>
                  <p>
                    <span>Tel : {orderDetails.phone}</span>
                  </p>
                  <p>
                    <span>Email : {orderDetails.email}</span>
                  </p>
                  <p>
                    <span>Customer A/C Number: {orderDetails.account_no}</span>
                  </p>
                </div>
                <div className="col-5">
                  <ul>
                    <li>
                      <span>Order :</span> INV{orderDetails.reference_no}
                    </li>
                    <li>
                      <span>Order Date :</span> {orderDetails.date}
                    </li>
                    <li>
                      <span>Sale Status:</span> {orderDetails.sale_status}
                    </li>
                    <li>
                      <span>Route Number :</span> {orderDetails.route_number}
                    </li>
                    <li>
                      <span>Payment Status :</span>{" "}
                      {orderDetails.payment_status}
                    </li>
                  </ul>
                </div>
              </div>

              <div className="sales-view-table">
                <table
                  className="table table-bordered resizable"
                  id="invoicePrint"
                >
                  <thead>
                    <tr>
                      <th style={{ backgroundColor: "#e28483" }}>
                        Description
                      </th>
                      <th style={{ backgroundColor: "#e28483" }}>Quantity</th>
                      <th style={{ backgroundColor: "#e28483" }}>Type</th>
                      <th style={{ backgroundColor: "#e28483" }}>Unit Price</th>
                      <th style={{ backgroundColor: "#e28483" }}>Discount</th>
                      <th style={{ backgroundColor: "#e28483" }}>VAT Amount</th>
                      <th style={{ backgroundColor: "#e28483" }}>Net Amount</th>
                    </tr>
                  </thead>
                  <tbody></tbody>
                  <tfoot>
                    <tr>
                      <td colSpan="4">
                        <span className="right">Total Std Goods</span>
                      </td>
                      <td>
                        <span>£43.84</span>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="4">
                        <span className="right">VAT Amount</span>
                      </td>
                      <td>
                        <span>£8.77</span>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="4">
                        <span className="right">Total Zero Goods</span>
                      </td>
                      <td>
                        <span>0.00</span>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="4">
                        <span className="right">Order Discount</span>
                      </td>
                      <td>
                        <span>0.00</span>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="4">
                        <span className="right">Total Amount</span>
                      </td>
                      <td>
                        <span>£52.61</span>
                      </td>
                    </tr>
                  </tfoot>
                </table>
                <p style={{ textAlign: "center" }}>
                  No claims for damages or short delivery will be considered
                  unless made at the time of delivery of goods.These goods are
                  not sold on a sale or return basis.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default NewSalesInvoice;
