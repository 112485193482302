import React, { useState, useEffect, useRef } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { API_URL, BASE_URL } from "../Config";
import config from "../common/Config";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import $ from "jquery";
import "datatables.net-bs5";
import Swal from "sweetalert2";
import CustomDateRangePicker from "../common/CustomDateRangePicker";
import { format } from "date-fns";

const Proforma = () => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [newSales, setNewSales] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const salesOrderRef = useRef(null);
  const orderIdRef = useRef(null);
  const reasonRef = useRef(null);
  const accessToken = localStorage.getItem("accessToken");
  const [salesDates, setSalesDates] = useState([null, null]);

  useEffect(() => {
    fetchData();
  }, [fromDate, toDate]);

    const handleSubmitDates = (e) => {
      e.preventDefault();
      const [startDate, endDate] = salesDates;
      let formattedStartDate = startDate ? format(startDate, "MM/dd/yyyy") : "";
      let formattedEndDate = endDate ? format(endDate, "MM/dd/yyyy") : "";
      fetchData(formattedStartDate, formattedEndDate);
    };

  const fetchData = (startDate, endDate) => {
    const $table = $(salesOrderRef.current);
    if (!$table.length) return;

    // Destroy existing DataTable instance to prevent reinitialization issues
    if ($.fn.DataTable.isDataTable($table)) {
      $table.DataTable().destroy();
    }

    // Initialize DataTable
    $table.DataTable({
      processing: true,
      serverSide: true,
      pageLength: 50,
      ajax: {
        url: `${config.API_URL}/proforma/invoice`,
        type: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        data: function (d) {
          return {
            ...d,
            draw: d.draw,
            start: d.start,
            length: d.length,
            searchByFromdate: startDate,
            searchByTodate: endDate
          };
        },
        dataSrc: function (json) {
          if (json?.data && Array.isArray(json.data)) {
            setNewSales(json.data);
            return json.data;
          }
          return [];
        },
        error: function (xhr) {
          console.error("DataTable AJAX error:", xhr.responseText);
        },
      },
      language: {
        paginate: {
          first: "First",
          last: "Last",
          next: "Next",
          previous: "Previous",
        },
        search: "Search:",
      },
      columns: [
        {
          targets: 0,
          data: null,
          width: "8%",
          className: "text-center",
          render: function (data, type, row, meta) {
            return meta.row + 1 + meta.settings._iDisplayStart;
          },
        },
        { data: "invoice_date", width: "8%", className: "text-center" },
        {
          data: "reference_no",
          width: "10%",
          className: "text-center",
          render: function (data, type, row) {
            return (
              '<a href="' +
              config.BASE_URL +
              "/edit/proforma/" +
              row.id +
              '">' +
              row.reference_no +
              "</a>"
            );
          },
        },
        { data: "customer" },
        { data: "route_number", width: "5%", className: "text-center" },
        { data: "deliverydate", width: "10%", className: "text-center" },
        {
          data: null,
          width: "8%",
          render: function (data, type, row) {
            const invoiceTotal = row.invoice_total ? row.invoice_total : "";
            return `<span>${invoiceTotal}</span>`;
          },
        },
        { data: "sale_status", width: "10%", className: "text-center" },
        {
          data: null,
          width: "5%",
          render: function (data, type, row) {
            return `<div>
              <a class="deductionbtn" data-action="reject" data-order='${encodeURIComponent(
                JSON.stringify(data)
              )}' title="Reject Order"><i class="bi bi-ban"></i></a>
              <a class="applybtn" href="../trip_summary/print/${
                row.id
              }" title="Print Invoice"><i class="bi bi-printer"></i></a>
            </div>`;
          },
        },
      ],
      createdRow: function (row, data, dataIndex) {
        // Add a data-id attribute to each row based on the row's id
        $(row).attr("data-id", data.id);
      },
      columnDefs: [{ targets: [], sortable: true }],
    });

    // Handle clicks for actions
    $("#neworderTable").on("click", 'a[data-action="reject"]', function (e) {
      e.preventDefault();
      const orderData = $(this).data("order");
      try {
        const order = JSON.parse(decodeURIComponent(orderData));
        setSelectedOrder(order);
        setShowRejectModal(true);
      } catch (error) {
        console.error("Error parsing order data:", error);
      }
    });

    // Cleanup on unmount
    return () => {
      if ($.fn.DataTable.isDataTable($table)) {
        $table.DataTable().destroy();
      }
    };
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchData();
  };

  const updateReject = async (event) => {
    event.preventDefault();

    if (!orderIdRef.current || !reasonRef.current) {
      console.error("Required form elements are missing");
      alert("Required form elements are missing. Please try again.");
      return;
    }

    const orderId = orderIdRef.current.value;
    const reason = reasonRef.current.value;

    const formData = new FormData();
    formData.append("status", "Reject");
    formData.append("reason_note", reason);
    formData.append("id", orderId);
    formData.append("new-sales", 'No');
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken === null) {
      window.location.href = `${config.BASE_URL}/login`;
      return;
    }

    try {
      const response = await fetch(`${config.API_URL}/ws-update-status`, {
        method: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(
          `HTTP error! Status: ${response.status}. Error: ${errorText}`
        );
      }

      const data = await response.json();
      console.log("API Response:", data);

      Swal.fire({
        title: "Success!",
        text: "Order rejected successfully.",
        icon: "success",
        timer: 1500,
        showConfirmButton: false,
      }).then(() => {
        window.location.href = config.BASE_URL + "/picking/proforma/";
      });
    } catch (error) {
      console.error("Error rejecting order:", error);
      alert("Error rejecting order. Please check the console for details.");
    }

    setShowRejectModal(false);
  };

  return (
    <div>
      <Header />
      <nav className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <a href="#">
                <i className="fa fa-home"></i>
              </a>
              <span className="last-crumb">Proforma Invoice</span>
            </div>
          </div>
        </div>
      </nav>

      {showRejectModal && (
        <div
          className="modal fade show d-block"
          id="reject-order"
          tabIndex="-1"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Reject Order</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowRejectModal(false)}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                {selectedOrder && (
                  <div className="rejectorder-popup">
                    <form id="rejectForm">
                      <h4>Proforma Invoice Details</h4>
                      <table className="table table-bordered resizable">
                        <tbody>
                          <tr>
                            <td>Order Id</td>
                            <td>{selectedOrder.reference_no}</td>
                          </tr>
                          <tr>
                            <td>Customer (A/C)</td>
                            <td>{selectedOrder.customer}</td>
                          </tr>
                          <tr>
                            <td>Order Amount</td>
                            <td>{selectedOrder.invoice_total}</td>
                          </tr>
                          <tr>
                            <td>Previous Dues</td>
                            <td>{selectedOrder.previous_dues || "N/A"}</td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="form-group">
                        <label className="col-form-label">Reason Note</label>
                        <textarea
                          id="reason"
                          name="reason"
                          ref={reasonRef}
                        ></textarea>
                      </div>
                      <input
                        type="hidden"
                        name="order_id"
                        id="order_id"
                        ref={orderIdRef}
                        value={selectedOrder.id}
                      />
                      <button
                        type="button"
                        onClick={updateReject}
                        className="updatebtn left updateReject"
                      >
                        Update
                      </button>
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div>
                <div className="row">
                  <div  style={{ float: "left", width: '600px' }} >
                    <CustomDateRangePicker
                      dates={salesDates}
                      onChange={setSalesDates}
                      onSubmit={handleSubmitDates}
                    />
                  </div>
                   
                  <div
                    className="col-md-2 col-lg-8 col-sm-8"
                    style={{ float: "right" }}
                  >
                    {/*	<button onClick={printMultipleInvoices} className='acceptbtn' style={{float: 'right'}}>Print Multiple Invoices</button>*/}
                  </div>
                  <div className="total-manifests-area dashboard">
                    <div className="verical-scrl-wrapper proforma" id="style-3">
                      <table
                        className="table table-bordered dataTable"
                        id="neworderTable"
                        ref={salesOrderRef}
                      >
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Invoice Date</th>
                            <th>Order Id</th>
                            <th>Customers (Account No)</th>
                            <th>Route</th>
                            <th>Delivery Date</th>
                            <th>Invoice Amt</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody></tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Proforma;
