import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { API_URL, BASE_URL } from './Config';
import axios from 'axios';

const generateUniqueFilename = (baseName, extension) => {
  const timestamp = new Date().toISOString().replace(/[-:.]/g, "");
  return `${baseName}_${timestamp}.${extension}`;
};

const generateSummaryPDF = async (tripId) => {
  const accessToken = localStorage.getItem('accessToken');
  const formData = new FormData();
  formData.set('tripId', tripId);
  const apiUrl = `${API_URL}/get_trip_summary_details`;

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);  // Convert timestamp string to Date object
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;  // Return formatted date as "YYYY-MM-DD"
  };

  try {
    const response = await axios.post(apiUrl, formData, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    });

     const data = response.data;
    
    const tripData = data.data;

    const doc = new jsPDF('portrait'); // Set to portrait orientation
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const margin = 10;  // 5px padding
    let y = margin;
    const firstColumnWidth = pageWidth * 0.25;  // 30% of the page width
    const secondColumnWidth = pageWidth * 0.75; 
    //const H2firstColumnWidth = pageWidth * 0.45; 

    const H2firstColumnWidth = pageWidth * 0.23;  // 45% of the page width
    const H2secondColumnWidth = pageWidth * 0.23; // 27.5% of the page width
    const H2thirdColumnWidth = pageWidth * 0.23;  // 27.5% of the page width
    const H2foreColumnWidth = pageWidth * 0.23; 
    const H2fourthColumnWidth = pageWidth * 0.22; 
    const boldTxt = '<b>';
    const boldTxtClose = '</b>';

    const headerSections2 = [
     
      [
        { text: `Trip Summary Report  `, size: 15, bold: true },
        { text: ` `, size: 15, bold: true },
        { text: `Trip No - ${tripData.trip_number}    | Date  :${tripData.date}   | Vehicle  :${tripData.vehicle} | Driver  :${tripData.driver}`, size: 13, bold: true },
      
    ],
    

      ];





    const headerSections3 = [
      [
        { text: `Goods Taken`, size: 8, bold: true },
       { text: `${tripData.total_products_box} Box   | ${tripData.total_products_piece} Psc  | Total `, size: 8, bold: false },
       { text: ``, size: 8, bold: false },
        { text: `£ ${parseFloat(tripData.total_box_price).toFixed(2)}  |  £ ${parseFloat(tripData.total_piece_price).toFixed(2)}  | ${parseFloat(tripData.total_piece_price+tripData.total_box_price).toFixed(2)} `, size: 8, bold: false },
        ],
      [

        { text: `Goods Delivered`, size: 8, bold: true },
       { text: `${tripData.total_delivered_box} Box   | ${tripData.total_delivered_piece} Psc  | Total `, size: 8, bold: false },
       { text: ``, size: 8, bold: false },
        { text: `£ ${parseFloat(tripData.total_delivered_box_price).toFixed(2)}  |  £ ${parseFloat(tripData.total_delivered_piece_price).toFixed(2)}  | £ ${parseFloat(tripData.total_delivered_box_price+tripData.total_delivered_piece_price).toFixed(2)} `, size: 8, bold: false },
      
        ],
      [
      { text: `Goods Undelivered`, size: 8, bold: true },
      { text: `${tripData.undelivered_products_box} Box   | ${tripData.undelivered_products_piece} Psc  | Total `, size: 8, bold: false },
      { text: ``, size: 8, bold: false },
      { text: `£ ${parseFloat(tripData.undelivered_box_price).toFixed(2)}  |  £ ${parseFloat(tripData.undelivered_piece_price).toFixed(2)}  | £ ${parseFloat(tripData.undelivered_box_price+tripData.undelivered_piece_price).toFixed(2)} `, size: 8, bold: false },
      
        ],
      [
        { text: `Goods Return`, size: 8, bold: true },
        { text: `${tripData.return_products_box} Box   | ${tripData.return_products_piece} Psc  | Total `, size: 8, bold: false },
        { text: ``, size: 8, bold: false },
        { text: `£ ${parseFloat(tripData.return_box_price).toFixed(2)}  |  £ ${parseFloat(tripData.return_piece_price).toFixed(2)}  | £ ${parseFloat(tripData.return_box_price+tripData.return_piece_price).toFixed(2)} `, size: 8, bold: false },
      
        
        ],

      ];




    const sectionWidth = (pageWidth - margin * 2) / 2; // Adjust for margins

    // Render header sections
    y = 7;
   const renderSections = (sections, marginTop = 7) => { // marginTop is an argument that defaults to 10
  let yPosition = y + marginTop; // Add initial marginTop space before rendering starts

  sections.forEach((section, index) => {
    // Calculate starting X position for the columns (for layout)
    const colStartX = margin + (index % 3) * firstColumnWidth;

    section.forEach((item, i) => {
      doc.setFontSize(item.size);
      doc.setFont(item.bold ? 'helvetica' : 'helvetica', item.bold ? 'bold' : 'normal');
     // doc.text(item.text, colStartX, yPosition + i * 4); // Adjust Y based on current position
        if (item.text.trim() === "Trip Summary Report") {
        const textWidth = doc.getTextWidth(item.text.trim()); // Get text width
        const centerX = (pageWidth - textWidth) / 2; // Calculate center X position
        doc.text(item.text.trim(), centerX, yPosition + i * 4); // Render centered text
      } else {
        //const colStartX = margin + (i % 3) * firstColumnWidth; // Normal column layout
        doc.text(item.text, colStartX, yPosition + i * 4);
      }
    });

    // Increase the Y position after rendering each section
    if (index % 4 === 3) {
      yPosition += 12; // Adjust spacing between sections as needed
    }
  });

  // Add a little space after the last section
  yPosition += 15;

  // Update the global Y value if you need to keep track of the Y position globally
  y = yPosition;
};

 renderSections(headerSections2);





    const h2renderSections = (sections) => {
  let rowHeight = 0; // Store the maximum height of columns in a row
  let rowStartY = y; // Track the starting Y position for each row of 4 columns
  let columnHeights = []; // Store individual column heights within a row

  sections.forEach((section, index) => {
    // Determine column X position based on section index (4-column layout)
    let colStartX;
    if (index % 4 === 0) {
      colStartX = margin;
    } else if (index % 4 === 1) {
      colStartX = margin + H2firstColumnWidth;
    } else if (index % 4 === 2) {
      colStartX = margin + H2firstColumnWidth + H2secondColumnWidth;
    } else {
      colStartX = margin + H2firstColumnWidth + H2secondColumnWidth + H2thirdColumnWidth;
    }

    let sectionHeight = 0; // Track the height of the current section

    section.forEach((item, i) => {
      doc.setFontSize(item.size);
      doc.setFont(item.bold ? 'helvetica' : 'helvetica', item.bold ? 'bold' : 'normal');

      // Determine max width for text wrapping
      let maxWidth;
      if (index % 4 === 0) {
        maxWidth = H2firstColumnWidth - 5;
      } else if (index % 4 === 1) {
        maxWidth = H2secondColumnWidth - 5;
      } else if (index % 4 === 2) {
        maxWidth = H2thirdColumnWidth - 5;
      } else {
        maxWidth = H2fourthColumnWidth - 5;
      }

      // Wrap text if needed
      const textWidth = doc.getTextWidth(item.text);
      let lines = [];
      if (textWidth > maxWidth) {
        let words = item.text.split(' ');
        let line = '';

        words.forEach((word) => {
          const testLine = line + (line ? ' ' : '') + word;
          if (doc.getTextWidth(testLine) <= maxWidth) {
            line = testLine;
          } else {
            lines.push(line);
            line = word;
          }
        });
        lines.push(line);
      } else {
        lines.push(item.text);
      }

      // Render text
      lines.forEach((lineText, lineIndex) => {
        const lineHeight = 4;
        doc.text(lineText, colStartX + 5, y + 5 + i * lineHeight + lineIndex * lineHeight);
        sectionHeight = y + i + lineIndex - 10;
      });
    });

    // Store column height
    columnHeights.push(sectionHeight);

    // After 4 columns, calculate row height and draw borders
    if ((index + 1) % 4 === 0) {
      rowHeight = Math.max(...columnHeights);
      columnHeights = [];

      // Draw borders for 4 columns
      let colX = margin;
      for (let i = 0; i < 4; i++) {
        doc.setLineWidth(0.1);
        let colWidth = (i === 0) ? H2firstColumnWidth : (i === 1) ? H2secondColumnWidth : 
                       (i === 2) ? H2thirdColumnWidth : H2fourthColumnWidth;
        doc.rect(colX, rowStartY - 2, colWidth, rowHeight + 1);
        colX += colWidth;
      }

      // Move Y down for next row
      y += rowHeight + 12;
    }
  });

  y += -8; // Small adjustment after rendering
};

// Call the function to render the 4-column layout
h2renderSections(headerSections3);







    // Render table columns with customized widths
const tableColumns = [
  { title: '#', dataKey: 'product', size: 8, bold: true, halign: 'center' },
  { title: 'Customers (A/C No)', dataKey: 'order_type', size: 8, bold: true, halign: 'center' },
  { title: 'Order ID', dataKey: 'qty', size: 8, bold: true, halign: 'center' },
  { title: 'Credit Type', dataKey: 'unit_price', size: 8, bold: true, halign: 'center' },
  { title: 'Inv. Amt', dataKey: 'discount', size: 8, bold: true, halign: 'center' },
  { title: 'Pre Dues', dataKey: 'vat', size: 8, bold: true, halign: 'center' },
  { title: 'Total Dues', dataKey: 'net_amount', size: 8, bold: true, halign: 'center' },
  { title: 'Collected', dataKey: 'net_amount', size: 8, bold: true, halign: 'center' },
  { title: 'Type', dataKey: 'net_amount', size: 8, bold: true, halign: 'center' },
  ];
    // Calculate the table widths
const totalWidth = pageWidth - 2 * margin;
    const productWidth = totalWidth * 0.06;  // 45% of the page width for product name/code
    const orderTypeWidth = totalWidth * 0.06;  // 5% for Order Type
    const qtyWidth = totalWidth * 0.06;  // 5% for Qty
    const unitPriceWidth = totalWidth * 0.12;  // 13% for Unit Price
    const discountWidth = totalWidth * 0.08;  // 6% for Discount
    const vatWidth = totalWidth * 0.11;  // 13% for VAT
    const netAmountWidth = totalWidth * 0.12; 

    // Construct table body
    const tableBody = tripData.trip_order.map((item, index) => [
      index + 1,
      item.customerName + ' ( ' + item.customerAccountNo + ' ) ', // Combined product code and name
      item.reference_no,
     item.credit_type_name,
      '£'+parseFloat(item.grand_total).toFixed(2),
      '£'+parseFloat(item.previous_dues).toFixed(2),
      '£'+parseFloat(item.total_dev).toFixed(2),
      '£'+parseFloat(item.driver_collect).toFixed(2),
        item.type,
      ]);

   


    doc.autoTable({
      startY: y,
      head: [tableColumns.map(col => col.title)],
      body: tableBody,
      theme: 'grid',
      headStyles: {
        fillColor: '#f8f9fa',
        textColor: '#404040',
        fontSize: 8,  
        valign: 'middle', 
        lineWidth: 0.3,
        halign: 'center', 
        lineWidth: 0.3,
        lineColor: [0, 0, 0] 
      },
      bodyStyles: {
        fontSize: 8,  
        valign: 'middle',
        lineWidth: 0.3,
        lineColor: [0, 0, 0] 
      },
      margin: { top: 0, left: margin, right: margin },
      columnStyles: {
        0: { cellWidth: productWidth, fontSize: 8, fontStyle: 'bold' },
        1: { cellWidth: orderTypeWidth,fontSize: 8, fontStyle: 'bold' },
        2: { cellWidth: qtyWidth ,fontSize: 8, fontStyle: 'bold'},
        3: { cellWidth: unitPriceWidth ,fontSize: 8, fontStyle: 'bold'},
        4: { cellWidth: discountWidth ,fontSize: 8, fontStyle: 'bold'},
        5: { cellWidth: vatWidth ,fontSize: 8, fontStyle: 'bold'},
        6: { cellWidth: netAmountWidth ,fontSize: 8, fontStyle: 'bold' },
        1: { halign: 'center' },
        2: { halign: 'center' },
        3: { halign: 'right' },
        4: { halign: 'center' },
        5: { halign: 'right' },
        6: { halign: 'right' },
      },
    });


    y = doc.autoTable.previous.finalY + 5;

    const addFooterSection = (footerSection) => {
      footerSection.forEach((section, index) => {
        const colStartX = margin + (index % 4) * sectionWidth;

        section.forEach((item, i) => {
          doc.setFontSize(item.size);
          doc.setFont(item.bold ? 'helvetica' : 'helvetica', item.bold ? 'bold' : 'normal');
          doc.text(item.text, colStartX, y + i * 5);
        });

        if (index % 4 === 3) {
          y += 20; 
        }
      });
      y += 25; 
    };




const remainingSpace = pageHeight - y - 10; // Calculate available space

// Add footer content with direct positioning
if (remainingSpace >= 20) {
 // doc.text("No claims for damages or short delivery will be considered unless made at the time of delivery of goods. These goods are not sold on a sale or return basis.", 10, pageHeight - 20);  // Directly specify position
} else {
  doc.addPage(); 
 // doc.text("No claims for damages or short delivery will be considered unless made at the time of delivery of goods. These goods are not sold on a sale or return basis.", 10, pageHeight - 20);  // Directly specify position on new page
}

const filename = generateUniqueFilename('Invoice', 'pdf');
//doc.save(filename);

doc.autoPrint();
window.open(doc.output('bloburl')); 



} catch (error) {
  console.error('Error generating PDF:', error);
}
};

export default generateSummaryPDF;
