import jsPDF from 'jspdf';
import 'jspdf-autotable';
import config from "../common/Config";
import axios from 'axios';

const generateUniqueFilename = (baseName, extension) => {
  const timestamp = new Date().toISOString().replace(/[-:.]/g, "");
  return `${baseName}_${timestamp}.${extension}`;
};

const printNewSalesInvoice = async (orderId) => {
  const accessToken = localStorage.getItem('accessToken');
  const formData = new FormData();
  formData.set('orderId', orderId);
  const apiUrl = `${config.API_URL}/ws-new-sales-details`;

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);  
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const day = String(date.getDate()).padStart(2, '0');

    return `${day}-${month}-${year}`;  
  };

  try {
    const response = await axios.post(apiUrl, formData, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    });

    const data = response.data;
    console.log(data);

    if (!data.success || !data.details.orderDetails || data.details.orderDetails.length === 0) {
      console.error("No order details found in the response.");
      return;  
    }

    const orderDetails = data.details.orderDetails[0];  
    const shopDetails = data.details.shop_details;
    console.log(orderDetails);

     const box_qty = data.details.product_details
    .filter(item => item.quantity != '0.00' && item.quantity != null && item.order_type === 'box')
    .reduce((sum, item) => sum + (parseFloat(item.quantity)), 0);

    const piece_qty = data.details.product_details
    .filter(item => item.quantity != '0.00' && item.quantity != null && item.order_type === 'piece')
    .reduce((sum, item) => sum + (parseFloat(item.quantity)), 0);

    const totalstd = data.details.product_details
    .filter(item => item.item_tax != '0.0000' )
    .reduce((sum, item) => sum + (parseFloat(item.subtotal)), 0);

    const totalzeroGoods = data.details.product_details
    .filter(item => item.item_tax == '0.0000' )
    .reduce((sum, item) => sum + (parseFloat(item.subtotal)), 0);

    const totalSTDGoods = parseFloat(totalstd) - parseFloat(totalzeroGoods);
    const orderDiscount = parseFloat(data.details.orderDetails[0].order_discount) || 0;
    const orderDiscount1 = parseFloat(data.details.orderDetails[0].order_discount1) || 0;
    const over_all_discount = parseFloat(data.details.orderDetails[0].over_all_discount) || 0;
    const over_all_amount = parseFloat(data.details.orderDetails[0].over_all_amount) || 0;

    const totalVAT = data.details.product_details
    .reduce((sum, item) => sum + (item.item_tax ? parseFloat(item.item_tax) : 0), 0);

    // let totalDiscountOnVat = 0;

    // alert(over_all_discount);
    // alert(typeof(over_all_discount));

    // if(Number(data.details.orderDetails[0].over_all_discount) > 0)
    // {
    //   totalDiscountOnVat += Number(data.details.orderDetails[0].over_all_discount);
    // }

    // if(Number(data.details.orderDetails[0].percentage) !== null)
    // {
    //   totalDiscountOnVat += Number(data.details.orderDetails[0].percentage);
    // }

    const totalAmount = totalstd + totalzeroGoods + totalVAT - orderDiscount - over_all_amount;

    const itemTotal = totalstd + totalzeroGoods;


    const doc = new jsPDF('portrait'); // Set to portrait orientation
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const margin = 5;  // 5px padding
    let y = margin;
    const firstColumnWidth = pageWidth * 0.40;  // 30% of the page width
    const secondColumnWidth = pageWidth * 0.70; 

    const H2firstColumnWidth = pageWidth * 0.20;  // 45% of the page width
    const H2secondColumnWidth = pageWidth * 0.55; // 27.5% of the page width
    const H2thirdColumnWidth = pageWidth * 0.25;  // 27.5% of the page width
    // Add the logo image to the header (make sure the image path is correct)
   const topMargin = 10; // Adjust this value for the desired top margin
const logoUrl = `${config.BASE_URL}/assets/img/${accessToken}_logo.png`; // Path to the logo image
const logoWidth = 45; // Width of the logo in the PDF
const logoHeight = 15; // Height of the logo

const a = topMargin; // Start position with top margin
doc.addImage(logoUrl, 'PNG', margin, a, logoWidth, logoHeight); // Adds the logo with top margin
const newY = a + logoHeight + 5;

    // Now create the other sections of the header
    const headerSections2 = [
    	[
    // Add shop details (below logo)
    		],
    	

    	[
    		{ text: `${shopDetails.shop_name}`, size: 13, bold: true },
    		{text: `Unit 31, Silicon Business Centre, 28 Wadsworth Road,`,size: 8,bold: false},
        {text:'Perivale, UB6 7JZ United Kingdom',size: 8,bold: false},
    		{ text:`Company No : ${shopDetails.company_reg_no} | VAT No : ${shopDetails.vat_no}`, size: 8, bold: false, margin: [0, 3, 0, 3] },
    		{ text:`Email : ${shopDetails.email}`, size: 8, bold: false, margin: [0, 3, 0, 3] },

    		],

    	[
    	
    		{text:`${shopDetails.website}`, size: 8, bold: false },
    		{text:`SORT CODE : ${shopDetails.sort_code}`,size: 8, bold: false},      
    		{text:`ACC NO : ${shopDetails.bank_ac}`,size: 8, bold: false},  
    		{text:`Tel: ${shopDetails.phone}`,size: 8, bold: false}, 
        {text:`Mob: 07480 672493`,size: 8, bold: false},  
    		],
    	];


    const headerSections3 = [

       [
        
        { text: `Order : ${orderDetails.reference_no}`, size: 8, bold: false },
        { text: `Order Date: ${formatDate(orderDetails.date)}`, size: 8, bold: false },
        { text: `Sale Status: ${orderDetails.sale_status}`, size: 8, bold: false },
        { text: `Payment Method : `, size: 8, bold: false },
        { text: `Payment Status : ${orderDetails.payment_status}`, size: 8, bold: false },

        ],
      [

        { text: `${orderDetails.company}`, size: 8, bold: false },
        { text: `Phone No : ${orderDetails.phone}`, size: 8, bold: false },
        { text: `Email Id : ${orderDetails.email}`, size: 8, bold: false },
        { text: `Address  : ${orderDetails.address}`, size: 8, bold: false },
        { text: `${orderDetails.city} ,${orderDetails.postal_code}`, size: 8, bold: false },
        ],
      
     
      ];

    const sectionWidth = (pageWidth - margin * 3) / 3; // Adjust for margins

    // Render header sections
    y = 7;
 const renderSections = (sections) => {
  let rowHeight = 0; // To store the maximum height of columns in a row
  let rowStartY = y; // Track the starting Y position for each row of 3 columns
  let columnHeights = []; // To store individual column heights within a row

  sections.forEach((section, index) => {
    // Column X position based on section index (for a 3-column layout)
    let colStartX;
    if (index % 3 === 0) {
      colStartX = margin;
    } else if (index % 3 === 1) {
      colStartX = margin + H2firstColumnWidth;
    } else {
      colStartX = margin + H2firstColumnWidth + H2secondColumnWidth;
    }

    let sectionHeight = 0; // To track the height of the current section

    section.forEach((item, i) => {
      doc.setFontSize(item.size);
      doc.setFont(item.bold ? 'helvetica' : 'helvetica', item.bold ? 'bold' : 'normal');

      // Wrap text if it exceeds column width (maxWidth)
      let maxWidth;
      if (index % 3 === 0) {
        maxWidth = H2firstColumnWidth - 5; // First column width
      } else if (index % 3 === 1) {
        maxWidth = H2secondColumnWidth - 5; // Second column width
      } else {
        maxWidth = H2thirdColumnWidth - 5; // Third column width
      }

      const textWidth = doc.getTextWidth(item.text);
      let lines = [];
      if (textWidth > maxWidth) {
        // Split text into multiple lines
        let words = item.text.split(' ');
        let line = '';

        words.forEach((word) => {
          const testLine = line + (line ? ' ' : '') + word;
          if (doc.getTextWidth(testLine) <= maxWidth) {
            line = testLine;
          } else {
            lines.push(line);
            line = word;
          }
        });
        lines.push(line); // Push remaining line
      } else {
        lines.push(item.text); // No wrapping needed, just one line
      }

      // Render each line of text
      lines.forEach((lineText, lineIndex) => {
        const lineHeight = 4; // Adjust vertical spacing for each line
        doc.text(lineText, colStartX + 5, y + 5 + i * lineHeight + lineIndex * lineHeight);
        sectionHeight = y + i + lineIndex - 5; // Update section height
      });
    });

    // Store the height of the current section (column height)
    columnHeights.push(sectionHeight);

    // After every 3 columns (3 sections), calculate the maximum height for this row
    if ((index + 1) % 3 === 0) {
      rowHeight = Math.max(...columnHeights); // Get the maximum height in this row
      columnHeights = []; // Reset for the next row

      y += rowHeight + 12; // Add some space after each row of columns
    }
  });

  // Draw a final horizontal line at the end of all sections
 doc.line(margin, y + 5, margin + H2firstColumnWidth + H2secondColumnWidth + H2thirdColumnWidth + 1, y + 5);

//doc.line(margin + 5, y, margin + 5 + H2firstColumnWidth + H2secondColumnWidth + H2thirdColumnWidth, y);


  y += -3; // Add a little space after all header sections
};


    const h2renderSections = (sections, topMargin = 15) => {
  y += topMargin; // Add the top margin before rendering sections

  sections.forEach((section, index) => {
    const colStartX = margin + (index % 2) * H2firstColumnWidth;

    section.forEach((item, i) => {
      doc.setFontSize(item.size);
      doc.setFont(item.bold ? 'helvetica' : 'helvetica', item.bold ? 'bold' : 'normal');
      doc.text(item.text, colStartX, y + i * 4); // Reduced space between lines
    });

    if (index % 4 === 3) {
      y += 12; // Reduced spacing after every 4 sections
    }
  });

  y += 20; // Add a little space after the last header section
};

    
    renderSections(headerSections2);
    h2renderSections(headerSections3);
    // Render table columns with customized widths
    const tableColumns = [
   		{ title: 'Qty', dataKey: 'qty', size: 6, bold: false },
    	{ title: 'Order Type', dataKey: 'order_type', size: 6, bold: false },
     	{ title: 'Product', dataKey: 'product', size: 6, bold: false },
      { title: 'Unit Price', dataKey: 'unit_price', size: 6, bold: false },
      { title: 'Discount', dataKey: 'discount', size: 6, bold: false },
      { title: 'VAT', dataKey: 'vat', size: 6, bold: false },
      { title: 'Net Amount', dataKey: 'net_amount', size: 6, bold: false },
      ];

    // Calculate the table widths
    const totalWidth = pageWidth - 2 * margin;
    const productWidth = totalWidth * 0.45;  
    const orderTypeWidth = totalWidth * 0.10;  
    const qtyWidth = totalWidth * 0.06; 
    const unitPriceWidth = totalWidth * 0.10;  
    const discountWidth = totalWidth * 0.08;  
    const vatWidth = totalWidth * 0.10; 
    const netAmountWidth = totalWidth * 0.10; 


  const tableBody = data.details.product_details.map((item) => {
      // Determine the discount text and class
      let discountText = '';
      let discountColor = '';

      if (item.discount !== '0.0' && item.discount !== 0.0) {
        discountText = `(${parseFloat(item.discount).toFixed(0)} % )`;
        // Apply a red color style if the discount is 100%
        if (item.discount === 100.00 || item.discount === '100.00') {
          discountColor = 'red'; // Change the color when discount is 100%
        }
      }

      return [
        parseInt(item.quantity),
        item.order_type,
        `${item.product_code} - ${item.product_name}`,
        `£ ${parseFloat(item.unit_price).toFixed(2)}`,
        discountText ? discountText : '',  // Only display discount if it exists
        `£ ${parseFloat(item.item_tax).toFixed(2)}`,
        `£ ${parseFloat((item.quantity * item.unit_price) - item.item_discount).toFixed(2)}`
      ];
    });


    // Add an empty row with totals
    tableBody.push([
      { content: 'Item Total', colSpan: 6, styles: { halign: 'right',fontStyle: 'bold' } },
      {content : `£ ${itemTotal.toFixed(2)}`} 
      
      ]);
     tableBody.push([
      { content: 'Vatable Total ', colSpan: 6, styles: { halign: 'right' ,fontStyle: 'bold'} },
      {content : `£ ${totalstd.toFixed(2)}`} 
      
      ]);

    tableBody.push([
     
      { content: 'Total Vat',colSpan: 6,  styles: { halign: 'right',fontStyle: 'bold' } }, 
      {content : `£ ${totalVAT.toFixed(2)}` }

      ]);


    tableBody.push([
      { content: `Order Discount(${over_all_discount}%)`, colSpan: 6, styles: { halign: 'right',fontStyle: 'bold' } }, 
      over_all_amount.toFixed(2)
      ]);
    tableBody.push([
      { content: `Promo Discount`, colSpan: 6, styles: { halign: 'right',fontStyle: 'bold' } }, 
      orderDiscount.toFixed(2)
      ]);
    
      tableBody.push([
      { content:  `Box : ${box_qty} PCs : ${piece_qty} `, colSpan: 5, styles: { halign: 'left',fontSize :8,fontStyle: 'bold' } }, 
      { content: 'Total Amount', styles: { halign: 'right',fontSize :8,fontStyle: 'bold' } }, 
      {content : `£ ${totalAmount.toFixed(2)}` }
      ]);



    doc.autoTable({
  startY: y,
  head: [tableColumns.map(col => col.title)],
  body: tableBody,
  theme: 'grid',
  headStyles: {
    fillColor: '#f8f9fa',
    textColor: '#404040',
    fontSize: 8,  // Font size for header
    valign: 'middle', // Vertical alignment for header
    lineWidth: 0.3, // Border thickness
    lineColor: [0, 0, 0]  // Black color for borders
  },
  bodyStyles: {
    fontSize: 8,  // Font size for body rows
    valign: 'middle', // Vertical alignment for body rows
    lineWidth: 0.3,  // Border thickness for body rows
    lineColor: [0, 0, 0]  // Black color for borders
  },
  margin: { top: 5, left: margin, right: margin },
  columnStyles: {
    0: { cellWidth: qtyWidth, halign: 'center' },
    1: { cellWidth: orderTypeWidth, halign: 'center' },
    2: { cellWidth: productWidth },
    3: { cellWidth: unitPriceWidth, halign: 'right' },
    4: { cellWidth: discountWidth, halign: 'center' },
    5: { cellWidth: vatWidth, halign: 'right' },
    6: { cellWidth: netAmountWidth, halign: 'right' }
  }
});


    


    y = doc.autoTable.previous.finalY + 5; // Y position after table

    const addFooterSection = (footerSection) => {
      footerSection.forEach((section, index) => {
        const colStartX = margin + (index % 4) * sectionWidth;

        section.forEach((item, i) => {
          doc.setFontSize(item.size);
          doc.setFont(item.bold ? 'helvetica' : 'helvetica', item.bold ? 'bold' : 'normal');
          doc.text(item.text, colStartX, y + i * 5);
        });

        if (index % 4 === 3) {
          y += 20; // Move down after every 4 sections
        }
      });
      y += 25; // Add space after each footer section
    };

    const addFooterWithSpaceCheck = (footerSection) => {
      const remainingSpace = pageHeight - y - margin; // Remaining space on the page
      const requiredSpace = footerSection.length * 5 + 20; // Space needed

      if (remainingSpace < requiredSpace) {
        doc.addPage(); // Add a new page if not enough space
        y = margin; // Reset Y position for new page
      }

      addFooterSection(footerSection);
    };

    const footerSections2 = [
      [
      	{ text: 'Goods remain the property of TS CHILLED FOODS LTD until invoice fully paid.', size: 8, bold: true },
        { text: 'Good Received & Checked By : _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ No of Boxes Received : _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _', size: 8, bold: false },
        { text: 'Amount due to collect : _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ ', size: 8, bold: false },
        { text: 'Amount Paid : _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _:cash :Cheque : ', size: 8, bold: false },
        { text: 'Amount Outstanding : _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _:Driver Name : ', size: 8, bold: false },
     
        ]
      ];

    addFooterWithSpaceCheck(footerSections2);
   const footerSections3 = [
  [
    { text: `Note: ${orderDetails.staff_note}`, size: 8, bold: true },
  ]
];

    addFooterWithSpaceCheck(footerSections3);
    const filename = generateUniqueFilename('Invoice', 'pdf');
    // doc.save(filename);

    doc.autoPrint();
    window.open(doc.output('bloburl'));

  } catch (error) {
    console.error('Error generating PDF:', error);
  }
};

export default printNewSalesInvoice;
