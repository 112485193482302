import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { API_URL, BASE_URL } from './Config';
import config from "./common/Config";

const ManifestList = () => {
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [manifestList, setManifestList] = useState([]);
  const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(50);
  const accessToken = localStorage.getItem('accessToken');
  const salesOrderRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("button clicked");
    fetchData();
  };

  // const fetchData = async () => {
  //   const formData = new FormData();
  //   formData.append('searchByFromdate', fromDate);
  //   formData.append('searchByTodate', toDate);
  //   formData.append('draw', draw);
  //   formData.append('start', start);
  //   formData.append('length', length);
  //   formData.append('role', selectedRole);

  //   const apiUrl = `${API_URL}/manifest/list`;
  //   try {
  //     const response = await fetch(apiUrl, {
  //       method: 'POST',
  //       headers: {
  //         // 'Content-Type': 'application/json',
  //         authorization: `Bearer ${accessToken}`,
  //       },
  //       body: formData,
  //     });

  //     if (!response.ok) {
  //       throw new Error('Failed to fetch new sales data');
  //     }

  //     const data = await response.json();
      
  //     setManifestList(Array.isArray(data.data) ? data.data : []); 
  //   } catch (error) {
  //     console.error('Error fetching new sales data:', error);
  //   }
  // };

  useEffect(() => {
    if (accessToken === null) {
      window.location.href = `${config.BASE_URL}/login`;
      return;
    }

    fetchData();
  }, [fromDate, toDate, selectedRole]);

  
  const fetchData = (startDate, endDate) => {
      const $table = $(salesOrderRef.current);
    if (!$table.length) return;

    // Destroy existing DataTable instance to prevent reinitialization issues
    if ($.fn.DataTable.isDataTable($table)) {
      $table.DataTable().destroy();
    }

    // Initialize DataTable
    $table.DataTable({
      processing: true,
      serverSide: true,
      pageLength: 50,
      ajax: {
        url: `${config.API_URL}/manifest/list`,
        type: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        data: function (d) {
          return {
            ...d,
            draw: d.draw,
            start: d.start,
            length: d.length,
            searchByFromdate: fromDate,
            searchByTodate: toDate,
            role: selectedRole
          };
        },
        dataSrc: function (json) {
          if (json?.data && Array.isArray(json.data)) {
            setManifestList(json.data);
            return json.data;
          }
          return [];
        },
        error: function (xhr) {
          console.error("DataTable AJAX error:", xhr.responseText);
        },
      },
      language: {
        paginate: {
          first: "First",
          last: "Last",
          next: "Next",
          previous: "Previous",
        },
        search: "Search:",
      },
      columns: [          
        { data: 'id', width : '4%' },    
        { data: 'date', width : '10%' },
       
         {
        data: 'manifest_id',
        width: '10%', 
        className: 'text-center',
         render: function (data, type, row) {
          return '<a href="'+BASE_URL+'/manifest_list/ManifestPrint/' + row.manifest_id + '">' + row.manifest_id + '</a>';
        }
      },
        { data: 'trip_id', width: '20%' },
        { data: 'driver', width : '15%' },    
        // { data: 'status' },
        { 
          data: null,
          width : '10%',
          render: function (data, type, row) {                
         
                return '<span class="btn btn-primary badge" >'  + row.status + '</span>';
             
          }
      },
        { 
            data: null,
            width : '5%',
            render: function (data, type, row) {                
              if (row.trip_id == null || row.trip_id == '' ) 
                {
                  return '<a class="viewbtn" href="'+BASE_URL+'/manifest_list/mark_delivery/' + data.manifest_id +'"  id="mark_deliver">  <i class="fa fa-edit"></i></a>';

                  //<a class="viewbtn" href="/ManifestList/mark_delivery/MANIFEST00928" id="mark_deliver">  <i class="fa fa-edit"></i></a>


                }
                else
                {
                  return '';
                }
            }
        }
    ],
    columnDefs: [
      { targets: [], sortable: true }
      ],
    });

     // Cleanup on unmount
     return () => {
      if ($.fn.DataTable.isDataTable($table)) {
        $table.DataTable().destroy();
      }
    };
  };




  return (
    <div>
      <Header />
      <div class="breadcrumbs">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-lg-12 col-sm-12"> 
        <Link to="/Dashboard"><i class="fa fa-home"></i></Link> &gt; 
        <Link to="/Dashboard">Dashboard</Link> &gt; 
        <Link to="/ManifestList">Dispatch & Deliveries</Link> &gt;
        <span class="last-crumb">Manifest List</span> 
      </div>
    </div>
  </div>
</div>

      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <div className="show-order-area dashboard">                
                <div className="verical-scrl-wrapper menifest-area" id="style-3" style={{width: '100%'}}>
                  <table className="table table-bordered dataTable resizable" id="manifestlisttable" ref={salesOrderRef}>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>date</th>
                        <th>Manifest Number</th>
                        <th>Trip ID</th>
                        <th>Driver</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {manifestList.map((item, index) => (
                        <tr key={index}>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ManifestList;
