import React, { useState, useEffect, useRef, useCallback  } from 'react';
import Select from 'react-select';
import axios from "axios";
import Header from '../Header';
import Footer from '../Footer';
import FlashMessage from '../FlashMessage';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { API_URL, BASE_URL } from '../Config';
import config from "../common/Config";
import { useNavigate } from "react-router-dom";

const StockTakeList = () => {

	const [draw, setDraw] = useState(1);
	const [start, setStart] = useState(0);
	const [length, setLength] = useState(5000);
	const [stockTakeList, setStockTakeList] = useState('');
	const [categoryOptions, setCategoryOptions] = useState([]);
  	const [categoryValue, setCategoryValue] = useState('');
  	const [brandOptions, setBrandOptions] = useState([]);
  	const [brandValue, setBrandValue] = useState('');
  	const accessToken = localStorage.getItem('accessToken'); 
  	const [checkedValues, setCheckedValues] = useState([]);
  	const [checkedItems, setCheckedItems] = useState({});

  	const [showModal, setShowModal] = useState(false);
	const modalRef = useRef(null);
	const [remarkValue, setRemarkValue] = useState('');
	const [flashMessage, setFlashMessage] = useState(null);
	const navigateToGRNList = useNavigate();
  	const handleCategoryChange = (event) => {
	    const value = event.target.value;
	    setCategoryValue(value);
	};
	const handleBrandChange = (event) => {
	    const value = event.target.value;
	    setBrandValue(value);
	};
	const handleCloseMessage = () => {
	    setFlashMessage(null);
	  };

	const handleRemarkChange = (event) => {
	    const value = event.target.value;
	    setRemarkValue(value);
	};

	const handleShow = () => {
	     if (modalRef.current) {
	      const modal = new window.bootstrap.Modal(modalRef.current);
	      modal.show();
	    }
	};

	const fetchCategoriesOptions = async () => {
  		const apiCategoriesUrl = `${API_URL}/stock_take/getCategories`;
  		//const apiCategoriesUrl = `${API_URL}/getSalesRep`;
	    try {
	     	const response = await fetch(apiCategoriesUrl, {
        		method: 'POST',
        		headers: {

          			authorization: `Bearer ${accessToken}`,
        		}
      		});

	      	const data = await response.json();
	      	const formattedOptions = data.data.category.map(item => ({
                key: `${item.name}`,
                value: item.id,
            }));
            setCategoryOptions(formattedOptions);
	    }catch (error) {
	      console.error('api fail Error:', error);
	    }
  	};

  	const fetchBrandOptions = async () => {
  		const apiCategoriesUrl = `${API_URL}/stock_take/getBrands`;
  		//const apiCategoriesUrl = `${API_URL}/getSalesRep`;
	    try {
	     	const response = await fetch(apiCategoriesUrl, {
        		method: 'POST',
        		headers: {

          			authorization: `Bearer ${accessToken}`,
        		}
      		});

	      	const data = await response.json();
	      	const formattedOptions = data.data.brands.map(item => ({
                key: `${item.name}`,
                value: item.id,
            }));
            setBrandOptions(formattedOptions);
	    }catch (error) {
	      console.error('api fail Error:', error);
	    }
  	};

  	const submitPdtRemark = async () => {
  		//console.log(checkedValues);

		if (checkedValues.length > 0) {
			const formData = new FormData();    
			formData.append('remark', remarkValue);
			formData.append('product_id', checkedValues);

			const apiAddOrdersUrl = `${API_URL}/products/update_remark`;
		  try {
		      const response = await fetch(apiAddOrdersUrl, {
		      		method: 'POST',
		      		headers: {
		        		// 'Content-Type': 'application/json',
		        		authorization: `Bearer ${accessToken}`,
		      		},
		      		body: formData,
		    		});

		      if (!response.ok) {
		        setFlashMessage({ message: 'something went wrong, try again', type: 'error' });
		      }
		      const data = await response.json();
		      setFlashMessage({ message: 'Remark Added successfully', type: 'success' });

		      fetchStockTakeList();
		    } catch (error) {      
		      setFlashMessage({ message: 'Error. Please check the console for details', type: 'error' });
		    }

		}else{
			setFlashMessage({ message: 'please select product to remark', type: 'error' });
		}
  }

  	const fetchStockTakeList = async () => {
	    // Create URL parameters
	    const params = new URLSearchParams();
	    params.append('draw', draw);
	    params.append('start', start);
	    params.append('length', length);
	    params.append('categories', categoryValue);
	    params.append('brands', brandValue);
	    //const apiUrl = `${API_URL}/customer_not_order_report?${params.toString()}`;
	    const apiUrl = `${API_URL}/product/stockTakeList?${params.toString()}`;

	    try {
	        const response = await fetch(apiUrl, {
	            method: 'GET',
	            headers: {
	                authorization: `Bearer ${accessToken}`,
	            },
	        });

	        if (!response.ok) {
	            throw new Error('Failed to fetch Not Order Report');
	        }
	        const data = await response.json();
	        setStockTakeList(data.data);
	        console.log(data);

	    } catch (error) {
	        console.error('api fail Error fetching Not Order Report:', error);
	    }
		};

  	

  	const searchFormData = () => {
  		fetchStockTakeList();
  	};

  	const updatePhysicalQty = async(id, newValue) => {

  		const formData = new FormData();    
			formData.append('id', id);
			formData.append('physical_qty', newValue);

			const apiPhysicalQtyUrl = `${API_URL}/stock_take/update_physical_qty`;
		  try {
	      const response = await fetch(apiPhysicalQtyUrl, {
      		method: 'POST',
      		headers: {
        		authorization: `Bearer ${accessToken}`,
      		},
      		body: formData,
	    	});

	      if (!response.ok) {
	        setFlashMessage({ message: 'something went wrong, try again', type: 'error' });
	      }
	      const data = await response.json();
	      console.log(data);
	      setFlashMessage({ message: 'Physical Qty updated successfully', type: 'success' });

	      fetchStockTakeList();
	    } catch (error) {      
	      setFlashMessage({ message: 'Physical Qty updated successfully', type: 'success' });
	    }


  	};

  	useEffect(() => {

  		if (accessToken === null){
            window.location.href = `${config.BASE_URL}/login`; 
            return;
        }
        
        fetchStockTakeList();
    	fetchCategoriesOptions();
    	fetchBrandOptions();
  	},[]);

  	


		useEffect(() => {
		  // Initialize DataTable
		  const table = $('#stockTakeListInfo').DataTable({
		    pageLength: 5000,
		    paging: true,
		    lengthMenu: [10, 20, 50,5000],
		    searching: true,
		    ordering: false,
		    info: true,
		    responsive: true,
		    autoWidth: false,
		    language: {
		      paginate: {
		        first: 'First',
		        last: 'Last',
		        next: 'Next',
		        previous: 'Previous',
		      },
		      search: 'Search:',
		    },
		    data: stockTakeList,
		    columns: [
		      {
		        data: null,
		        render: function (data, type, row) {
		          const isChecked = !!checkedItems[row.ids];
		          return `
		            <label class="checkbox">
		              <input type="checkbox" name="val[]" value="${row.id}" class="dataTableCheckbox" />
		              <span class="default"></span>
		            </label>
		          `;
		        }
		      },
		      { data: 'name' },
		      { data: 'bay' },
		      { data: 'rack' },
		      { data: 'price' },
		      { data: 'cost' },
		      { data: 'quantity' },
		      {
		        data: 'physical_qty',
		        width: '6%',
		        className: 'text-center',
		        render: function (data, type, row) {
		          return `<div><input type="text" data-id="${row.id}" class="form-control physical-qty text-center" value="${row.physical_qty}" min="0" /></div>`;
		        }
		      },
		      { data: 'diff' },
		      { data: 'piece_qty' },
		      { data: 'physical_piece_qty' },
		      { data: 'piece_diff' },
		      {
		        data: null,
		        width: '14%',
		        render: function (data, type, row) {
		          return '<input readonly disabled type="text" class="form-control" value="' + row.remark + '">';
		        }
		      }
		    ],
		    drawCallback: function (settings) {
		     
		    }
		  });

		  // Event delegation for .physical-qty input fields
		  //$('#stockTakeListInfo').on('keyup', '.physical-qty', function () {
		  $(document).off('keyup', '#stockTakeListInfo .physical-qty').on('keyup', '#stockTakeListInfo .physical-qty', function () {
		   
		    const id = $(this).data('id');
		    const newValue = parseInt($(this).val()) || 0;

		    const rowIdx = table
		      .rows()
		      .indexes()
		      .toArray()
		      .find(idx => table.row(idx).data().id == id);

		    if (rowIdx !== undefined) {
		      const rowData = table.row(rowIdx).data();
		      rowData.physical_qty = newValue < 0 ? 0 : newValue;

		      table.row(rowIdx).invalidate().draw();

		      updatePhysicalQty(id, newValue);

		    } else {
		      console.warn(`No row found with ID: ${id}`);
		    }
		  });

		  // Event delegation for .dataTableCheckbox input fields
		  $(document).off('change', '#stockTakeListInfo .dataTableCheckbox').on('change', '#stockTakeListInfo .dataTableCheckbox', function () {
		    const value = $(this).val();
		    const checked = $(this).is(':checked');
		    setCheckedValues(prevState => {
		      if (checked) {
		        return [...prevState, value];
		      } else {
		        return prevState.filter(val => val !== value);
		      }
		    });
		  });

		  return () => {
		    table.destroy();
		  };
		}, [stockTakeList]);

		const handelFormSubmit = async() =>{
		

			const formData = new FormData();  
			stockTakeList.forEach((value, index) => {
			  formData.append('selectedIds[]', value.id);
			});
			formData.append('uniqueId', stockTakeList[0].uniqueid);

			const apiStockFormUrl = `${API_URL}/products/update_stock_take_product`;
	  	try {
	      const response = await fetch(apiStockFormUrl, {
	      		method: 'POST',
	      		headers: {
	        		authorization: `Bearer ${accessToken}`,
	      		},
	      		body: formData,
	    		});

	      if (!response.ok) {

	        setFlashMessage({ message: 'something went wrong, try again', type: 'error' });
	      }

	      const data = await response.json();

	      setFlashMessage({ message: 'Data Submitted successfully', type: 'success' });
	      setTimeout(() => {
	      		
	      		navigateToGRNList("/stock_take/history"); 	
	      	}, 1000); 
	     // fetchStockTakeList();
	    } catch (error) {
	      console.error('Error Accept Order:', error);	      
	      setFlashMessage({ message: 'Error Accept Order order. Please check the console for details', type: 'error' });
	    }
		};

	return(
		<>
			<Header />
			<div className="breadcrumbs">
	      <div className="container-fluid">
		      <div className="row">
		        <div className="col-12 col-lg-12 col-sm-12"> 
	            <Link to="/Dashboard"><i className="fa fa-home"></i></Link>   
	            <Link to="/stock_take/stock_take_list">Stock Take List</Link> 
		        </div>
		      </div>
	      </div>
	    </div>
	    <div className="main-container">
		    <div className="container-fluid">
		    	<div className="row">
	          <div>
	            <div className="col-md-3 col-lg-3 col-sm-3" style={{float: 'left',  padding: '0px 0px 0px 0px'}}>
						    <select value={categoryValue} onChange={handleCategoryChange} className="form-select">
			            <option value="">Select Categories</option>
                    {categoryOptions.map(option => (
                        <option key={option.value} value={option.value}>
                          {option.key}
                        </option>
                    ))}
			          </select>
							</div>
							<div className="col-md-3 col-lg-3 col-sm-3" style={{float: 'left',  padding: '0px 0px 0px 15px'}}>
								<select value={brandValue} onChange={handleBrandChange} className="form-select" >
				          <option value="">Select Brands</option>
	                  {brandOptions.map(option => (
	                      <option key={option.value} value={option.value}>
	                        {option.key}
	                      </option>
	                  ))}
				          </select>
							</div>

							<div className="col-md-3 col-lg-3 col-sm-3" style={{float: 'left', paddingTop: '3px', paddingRight: '15px' }} >
								<button type="button" onClick={searchFormData} className="acceptbtn" style={{float: 'left', margin: '0 0 0 15px'}}>Search</button>
							</div>
							<div className="col-md-3 col-lg-3 col-sm-3" style={{float: 'left', paddingTop: '3px', paddingRight: '0x' }} >
								<button type="button" onClick={handleShow}  className="acceptbtn" style={{float: 'right', margin: '0 0 0 0px'}}>Bulk Remark</button>
							</div>
	        	</div>
	      	</div>
	      	
			    <div className="total-manifests-area dashboard">
			      	
	          &nbsp;

	          <div className="col-md-12 col-xs-12 verical-scrl-wrapper common-table autoheight-table stake-list" id="style-3">
	            <table className="table table-bordered dataTable resizable" id="stockTakeListInfo">
	              <thead>
	                <tr>
	                  <th>#</th>
	                  <th>Product Code - Name</th>
	                  <th>Bay</th>
	                  <th>Rack</th>
	                  <th>Sale P</th>
	                  <th>Cost P</th>
	                  <th>Sys Qty</th>
	                  <th>Phy Qty</th>
	                  <th>Diff</th>
	                  <th>Pice Qty</th>
	                  <th>Phy Piece</th>
	                  <th>Diff</th>
	                  <th>Remark</th>
	               	</tr>
	              </thead>
	              <tbody>
	                  
	              </tbody>
	              <tfoot>

					      </tfoot>
	            </table>
	          </div>
	          <div className="col-md-12 col-lg-12 col-sm-12" style={{paddingTop: '3px', paddingRight: '0' }} >
								<button type="button" onClick={handelFormSubmit}  className="acceptbtn" style={{ margin: '0 0 0 0px'}}>submit</button>
						</div>
		        
	        </div>
			</div>
			</div>

			<div ref={modalRef} className="modal fade" aria-hidden="true" tabIndex="-1" role="dialog">
	      <div className="modal-dialog" role="document">
		      <div className="modal-content">
		        <div className="modal-header">
			        <h5 className="modal-title">Add Remark</h5>
			        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
		        </div>
		        <div className="modal-body">
		        	<input type="type" onChange={handleRemarkChange}  placeholder="remark"  className="form-control" value={remarkValue} />
		        </div>
		        <div className="modal-footer">
		          <button type="button" className="common-closebtn" data-bs-dismiss="modal" >Cancel</button>
		          <button type="button" onClick={submitPdtRemark} className="float-right-btn common-popup-btn" data-bs-dismiss="modal"  >Save </button>
		        </div>
		      </div>
	      </div>
	    </div>


    	<FlashMessage
        message={flashMessage?.message}
        type={flashMessage?.type}
        onClose={handleCloseMessage}
      />

	    <Footer />
		</>
	)

}

export default StockTakeList