import React, { useState, useEffect } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import { API_URL, BASE_URL } from '../Config';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import config from "../common/Config";

const PickingHistory = () => {
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [newSales, setNewSales] = useState([]); // State to store statistics data
  const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(50);
 const accessToken = localStorage.getItem('accessToken');
  const handleSubmit = (e) => {
    e.preventDefault();
    fetchData();
  };

  const fetchData = async () => {
    const formData = new FormData();
    formData.append('searchByFromdate', fromDate);
    formData.append('searchByTodate', toDate);
    formData.append('draw', draw);
    formData.append('start', start);
    formData.append('length', length);
    formData.append('role', selectedRole);

    const apiUrl = `${API_URL}/picking/get`;
    const accessToken = localStorage.getItem('accessToken');

    if (accessToken === null) {
      window.location.href = `${config.BASE_URL}/login`;
      return;
    }
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to fetch new sales data');
      }

      const data = await response.json();
      setNewSales(Array.isArray(data.data) ? data.data : []);
    } catch (error) {
      console.error('Error fetching new sales data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fromDate, toDate, selectedRole]);

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    const table = $('#neworderTable').DataTable({
      paging: true,
      lengthMenu: [10, 20, 50],
      searching: true,
      ordering: false,
      info: true,
      responsive: true,
      autoWidth: false,
      language: {
        paginate: {
          first: 'First',
          last: 'Last',
          next: 'Next',
          previous: 'Previous',
        },
        search: 'Search:',
      },
      data: newSales,
      columns: [

        { data: 'picklist_number', width: '12%', className: 'text-center' },
        ...(accessToken === 'tss' || accessToken === 'tsc'
      ? [
          
          { data: 'reference_no', width: '12%', className: 'text-center' },
          { data: 'customer', width: '12%', className: 'text-center' },
           { data: 'route', width: '12%', className: 'text-center' },
        ]
      : [
           { data: 'route', width: '12%', className: 'text-center' },
        ]),
       
        { data: 'picker' },
        { data: 'picklist_date', width: '12%', className: 'text-center' },
        { data: 'picking_status', width: '12%', className: 'text-center' },
        {
          data: null,
          width: '5%',
          render: function (data, type, row) {
            return `<a class="deductionbtn" title="view" href="${config.BASE_URL}/picking/view_picking/${row.picklist_number}"><i class="fa fa-eye"></i></a>`;
          },
        },
      ],
    });

    return () => {
      table.destroy();
    };
  }, [newSales]);

  return (
    <div>
      <Header />
      <nav className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <a href="#"><i className="fa fa-home"></i> </a>
              <span className="last-crumb">Picking History</span>
            </div>
          </div>
        </div>
      </nav>
      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">


              <div className='total-manifests-area dashboard'>
                {/* <div style={{ float: 'right', width: '100%', textAlign: 'right', marginTop: '25px' }}>

    </div> */}
                <div className="verical-scrl-wrapper picking-history" id="style-3">
                  <table className="table table-bordered dataTable resizable" id="neworderTable">
                    <thead>
                      <tr>
                        <th>Picklist Number</th>
                     {accessToken === 'tss' || accessToken === 'tsc' ? (
                            <>
                              <th>Order ID</th>
                              <th>Customer Name</th>
                               <th>Route Name</th>
                            </>
                          ) : (
                            <th>Route Name</th>
                          )}
                       

                        <th>Picker</th>
                        <th>Picklist Date</th>
                        <th>Picking Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {newSales.map((item, index) => (
                        <tr key={index}>

                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PickingHistory;
