import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../Header';
import Footer from '../../Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import config from "../../common/Config";
import Swal from 'sweetalert2';
import AddCustomersForm from './AddCustomersForm';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Ensure Bootstrap 5 JS is included

const Customers = () => {
  const customerTableRef = useRef(null);
  const customerInactiveTableRef = useRef(null);
  const [custSearchValue, setCustSearchValue] = useState([]);
  const [custInactiveSearchValue, setCustInactiveSearchValue] = useState([]);
  const accessToken = localStorage.getItem('accessToken');

  // Initialize customer table
  const initializeCustomerTable = () => {
    const $table = $(customerTableRef.current);
    const searchValue = $('#searchValue').val();

    if ($.fn.DataTable.isDataTable($table)) {
      $table.DataTable().destroy();
    }

    $table.DataTable({
      processing: true,
      serverSide: true,
      ajax: {
        url: `${config.API_URL}/get_active_customer`,
        type: 'POST',
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        data: function (d) {
          return $.extend({}, d, {
            draw: d.draw,
            start: d.start,
            length: d.length,
            searchValue: searchValue
          });
        },
        dataSrc: function (json) {
          return Array.isArray(json.data) ? json.data : [];
        }
      },
      language: {
        paginate: {
          first: 'First',
          last: 'Last',
          next: 'Next',
          previous: 'Previous',
        },
        search: 'Search:',
      },
      columns: [
        { data: null, width: "6%", render: function (data, type, row, meta) { return meta.row + meta.settings._iDisplayStart + 1; } },
        { data: 'account_no', width: '4%' },
        { data: null, width: '10%', render: function (data, type, row) { return `<span style="text-transform: none;">${row.company}</span>`; } },
        { data: null, render: function (data, type, row) { return `<span style="text-transform: none;">${row.name}</span>`; } },
        { data: null, render: function (data, type, row) { return `<span class="text-lowercase">${row.email.toLowerCase()}</span>`; } },
        { data: 'phone' },
        { data: 'vat_no' },
        { data: null, render: function (data, type, row) { const checked = row.status == "1" ? "checked" : ""; return `<label class="switch" style="margin: 0px auto;"><input data-id="${row.id}" class="switch-input" type="checkbox" name="changeStatus" id="changeStatus_${row.id}" ${checked} /><span class="switch-label" data-on="Active" data-off="Inactive"></span><span class="switch-handle"></span></label>`; } },
        { data: 'product_price_type', width: '5%', className: 'text-center' },
        
        { data: null, title: 'Action', 
          render: function (data, type, row) {
      
    return `<div>
    <a class="deductionbtn reject-btn" href="${config.BASE_URL}/customer/contra_price/${row.id}" title="Contra P"><i class="bi bi-tags"></i></a>
    <a class="deductionbtn reject-btn" href="${config.BASE_URL}/customer/edit_customer/${row.id}" title="Edit"><i class="bi bi-pencil-square"></i></a>
    </div>`;

        }
      }


      ],
      columnDefs: [
        { orderable: false, targets: '_all' },
        { targets: 2, render: function (data) { return data === '1' ? 'Active' : 'Inactive'; } }
      ],
      pageLength: 10,
    });

    // Event handler for status change
    $table.on('change', '.switch-input', function () {
      const id = $(this).data('id');
      const status = this.checked ? 1 : 0;
      handleChangeStatus(id, status);
    });
  };

  // Handle changing customer status
  const handleChangeStatus = async (id, status) => {
    const formData = new FormData();
    formData.append('status', status);
    formData.append('id', id);
    const apiUrl = `${config.API_URL}/update_customer_status`;

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to change status');
      }

      const data = await response.json();

      Swal.fire({
        title: 'Success',
        text: 'Status changed successfully.',
        icon: 'success',
        showConfirmButton: false,
        timer: 1500,
      });

      initializeCustomerTable();
      initializeInactiveCustomerTable();
    } catch (error) {
      console.error('Error status change:', error);
    }
  };

  useEffect(() => {
    if (accessToken == null) {
      window.location.href = `${config.BASE_URL}/login`;
      return;
    }
    initializeCustomerTable();
    initializeInactiveCustomerTable();
    return () => {
      if (customerTableRef.current && $.fn.DataTable.isDataTable(customerTableRef.current)) {
        $(customerTableRef.current).DataTable().destroy();
      }
    };
  }, [custSearchValue]);
  const handleClick = () => {
    alert('Button clicked!');
  };
//InactiveCustomerTable
  const initializeInactiveCustomerTable = () => {
    const $table = $(customerInactiveTableRef.current);
    const searchValue = $('#searchValue').val();
    
    if ($.fn.DataTable.isDataTable($table)) {
      $table.DataTable().destroy();
    }

    $table.DataTable({
      processing: true,
      serverSide: true,
      ajax: {
        url: `${config.API_URL}/get_inctive_customer`,
        type: 'POST',
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        data: function (d) {
          return $.extend({}, d, {
            draw: d.draw,
            start: d.start,
            length: d.length,
            searchValue: searchValue
          });
        },
        dataSrc: function (json) {
          return Array.isArray(json.data) ? json.data : [];
        }
      },
      language: {
        paginate: {
          first: 'First',
          last: 'Last',
          next: 'Next',
          previous: 'Previous',
        },
        search: 'Search:',
      },
      columns: [
        { data: null, width: "6%", render: function (data, type, row, meta) { return meta.row + meta.settings._iDisplayStart + 1; } },
        { data: 'account_no', width: '4%' },
        { data: null, width: '10%', render: function (data, type, row) { return `<span style="text-transform: none;">${row.company}</span>`; } },
        { data: null, render: function (data, type, row) { return `<span style="text-transform: none;">${row.name}</span>`; } },
        { data: null, render: function (data, type, row) { return `<span class="text-lowercase">${row.email.toLowerCase()}</span>`; } },
        { data: 'phone' },
        { data: 'vat_no' },
        { data: null, render: function (data, type, row) { const checked = row.status == "1" ? "checked" : ""; return `<label class="switch" style="margin: 0px auto;"><input data-id="${row.id}" class="switch-input" type="checkbox" name="changeStatus" id="changeStatus_${row.id}" ${checked} /><span class="switch-label" data-on="Active" data-off="Inactive"></span><span class="switch-handle"></span></label>`; } },
        { data: 'product_price_type', width: '5%', className: 'text-center' },
        
        { data: null, title: 'Action', 
          render: function (data, type, row) {
      
    return `<div>
    <a class="deductionbtn reject-btn" href="${config.BASE_URL}/customer/contra_price/${row.id}" title="Contra P"><i class="bi bi-tags"></i></a>
    <a class="deductionbtn reject-btn" href="${config.BASE_URL}/customer/edit_customer/${row.id}" title="Edit"><i class="bi bi-pencil-square"></i></a>
    </div>`;

        }
      }


      ],
      columnDefs: [
        { orderable: false, targets: '_all' },
        { targets: 2, render: function (data) { return data === '1' ? 'Active' : 'Inactive'; } }
      ],
      pageLength: 10,
    });

    // Event handler for status change
    $table.on('change', '.switch-input', function () {
      const id = $(this).data('id');
      const status = this.checked ? 1 : 0;
      handleChangeStatus(id, status);
    });
  };
  return (
    <div>
      <AddCustomersForm />
      <Header />
      <div className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <a href="#"><i className="fa fa-home"></i></a> &gt; <a href="Dashboard">Master</a> &gt; <span className="last-crumb">Customer</span>
            </div>
          </div>
        </div>
      </div>
      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className='bank-pagearea'>
                <div>
                  <div style={{ float: "right" }}>
                    <a href="#" className="create-catalogue-btn" onClick={handleClick} style={{ marginBottom: "-10px" }} data-bs-toggle="modal" data-bs-target="#add-customer" >Add Customer</a>
                  </div>
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#eur" type="button" role="tab" aria-controls="eur" aria-selected="true"> Customers </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#gbp" type="button" role="tab" aria-controls="gbp" aria-selected="false"> Inactive Customers </button>
                    </li>
                  </ul>
                  <div className="tab-content fulltable-area" id="myTabContent">
                    <div className="tab-pane fade show active" id="eur" role="tabpanel" aria-labelledby="eur-tab">
                      <div className="backlist-content-area">
                        <div className="verical-scrl-wrapper common-table customer-table" id="style-3">
                          <table className="table table-bordered dataTable resizable" id="neworderTable" ref={customerTableRef} style={{ width: '100%' }}>
                            <thead>
                              <tr>
                                <th style={{width: '50px'}}>&nbsp;</th>
                                <th style={{width: '150px'}}>A/C No.</th>
                                <th>Company</th>
                                <th>Name</th>
                                <th>Email Address</th>
                                <th style={{width: '120px'}}>Phone</th>
                                <th style={{width: '150px'}}>Vat No.</th>
                                <th style={{width: '100px'}}>Status</th>
                                <th>Product Band</th>
                                <th style={{width: '120px'}}>Action</th>
                              </tr>
                            </thead>
                            <tbody></tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div className="tab-pane fade" id="gbp" role="tabpanel" aria-labelledby="gbp-tab">
                      <div className="backlist-content-area">
                        <div className="verical-scrl-wrapper common-table customer-table" id="style-3">
                          <table className="table table-bordered dataTable resizable" id="neworderInactiveTable" ref={customerInactiveTableRef} style={{ width: '100%' }}>
                            <thead>
                              <tr>
                              <th style={{width: '50px'}}>&nbsp;</th>
                              <th style={{width: '150px'}}>A/C No.</th>
                                <th>Company</th>
                                <th>Name</th>
                                <th>Email Address</th>
                                <th style={{width: '120px'}}>Phone</th>
                                <th style={{width: '150px'}}>Vat No.</th>
                                <th style={{width: '100px'}}>Status</th>
                                <th>Product Band</th>
                                <th style={{width: '120px'}}>Action</th>
                              </tr>
                            </thead>
                            <tbody></tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Customers;
