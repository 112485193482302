import React, { useEffect, useState } from 'react';
import axios from "axios";
import Header from './Header';
import Footer from './Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import jsPDF from "jspdf";
import { API_URL, BASE_URL, PREFIX } from './Config';
import { useParams } from "react-router-dom";
import autoTable from "jspdf-autotable";


const Customer_Onboarding = () => {

    return (
        <div>
            <Header />
            <div class="breadcrumbs">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-lg-12 col-sm-12"> <a href="#"><i class="fa fa-home"></i></a> &gt; <a href="dashboard.html">Dashboard</a> &gt; <span class="last-crumb">Customer Onboarding</span></div>
                    </div>
                </div>
            </div>



            <div className="main-container">
                <div class="container">
                    <div className='verical-scrl-wrapper common-table customer-onboarding' id="style-3">
                        <div className='row'>
                            <div className='col-6 col-lg-12 col-sm-12'>
                                <table class="table table-bordered resizable dataTable">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Field Name</th>
                                            <th>Web</th>
                                            <th>App</th>
                                            <th>Mandatory</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td>01</td>
                                            <td>Company Name </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>02</td>
                                            <td>Company Email Id</td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                        </tr>


                                        <tr>
                                            <td>03</td>
                                            <td>Company Phone</td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>04</td>
                                            <td>Owner/Director Name</td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                        </tr>


                                        <tr>
                                            <td>05</td>
                                            <td>Director Email Id </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                        </tr>


                                        <tr>
                                            <td>06</td>
                                            <td>Director Phone  </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                        </tr>


                                        <tr>
                                            <td>07</td>
                                            <td>Contact Person</td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                        </tr>


                                        <tr>
                                            <td>08</td>
                                            <td> Contact Person Email Id</td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>09</td>
                                            <td>Contact Person Phone</td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                        </tr>


                                        <tr>
                                            <td>10</td>
                                            <td>Account Number</td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>11</td>
                                            <td>Is PO Number ?</td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>12</td>
                                            <td>Route</td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>13</td>
                                            <td>VAT Number</td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                        </tr>


                                        <tr>
                                            <td>14</td>
                                            <td>Type of Business</td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                        </tr>


                                        <tr>
                                            <td>15</td>
                                            <td>Business Category</td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                        </tr>


                                        <tr>
                                            <td>16</td>
                                            <td>Delivery Days</td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                        </tr>


                                        <tr>
                                            <td>17</td>
                                            <td>Address Line 1</td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>18</td>
                                            <td>Address Line 2</td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>19</td>
                                            <td>City</td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>20</td>
                                            <td>Country</td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>21</td>
                                            <td>Postal Code</td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>22</td>
                                            <td> Product Price</td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                        </tr>


                                        <tr>
                                            <td>23</td>
                                            <td>Credit</td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                        </tr>


                                        <tr>
                                            <td>24</td>
                                            <td>Username</td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                        </tr>


                                        <tr>
                                            <td>25</td>
                                            <td>County </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>26</td>
                                            <td>Assign Sales Rep</td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>27</td>
                                            <td>Customer Activation</td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>28</td>
                                            <td>Contra Prices </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="checkbox">
                                                    <input type="checkbox" name="val[]" value="39" class="dataTableCheckbox" />
                                                    <span class="default"></span>
                                                </label>
                                            </td>
                                        </tr>



                                    </tbody>


                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <Footer />
        </div>


    );
};

export default Customer_Onboarding;
