import React, { useState, useEffect, useRef } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { API_URL, BASE_URL } from '../Config';
import config from "../common/Config";

const InventorySalesReport = () => {

	const [reportrange, setReportrange] = useState('');
	const [dates, setDates] = useState([null, null]);
	const [reportDate, setReportDate] = useState('');
	const [inventoryReport, setInventoryReport] = useState([]);
	const [categoryOptions, setCategoryOptions] = useState([]);
	const [brandOptions, setBrandOptions] = useState('');
	const [salescategory, setSalescategory] = useState('');
	const [salesbrand, setSalesbrand] = useState('');
	const accessToken = localStorage.getItem('accessToken');
	const salesOrderRef = useRef(null);

	const handleDateChange = (value) => {
		setDates(value);
		// Convert them to 'MM/dd/yyyy' format for further use
		let formattedStartDate = value[0] ? format(value[0], 'MM/dd/yyyy') : '';
		let formattedEndDate = value[1] ? format(value[1], 'MM/dd/yyyy') : '';
		setReportrange(formattedStartDate ? formattedStartDate + ' - ' + formattedEndDate : '');
	};

	const fetchCategoriesOptions = async () => {
		const apiCategoriesUrl = `${API_URL}/stock_take/getCategories`;
		try {
			const response = await fetch(apiCategoriesUrl, {
				method: 'POST',
				headers: {
					authorization: `Bearer ${accessToken}`,
				},
			});
			const data = await response.json();

			const formattedOptions = data.data.category.map(item => ({
				value: item.id,
				label: item.name,
			}));


			console.log(formattedOptions);

			setCategoryOptions(formattedOptions);
		} catch (error) {
			console.error('API fail Error:', error);
		}
	};

	const fetchBrandOptions = async () => {
		const apiBrandsUrl = `${API_URL}/stock_take/getBrands`;
		try {
			const response = await fetch(apiBrandsUrl, {
				method: 'POST',
				headers: {
					authorization: `Bearer ${accessToken}`,
				},
			});
			const data = await response.json();
			const formattedOptions = data.data.brands.map(item => ({
				value: item.id,
				label: item.name,
			}));
			setBrandOptions(formattedOptions);
		} catch (error) {
			console.error('API fail Error:', error);
		}
	};

	const fetchReport = (startDate, endDate) => {
		const $table = $(salesOrderRef.current);
		const searchValue = $('#searchValue').val();

		if ($.fn.DataTable.isDataTable($table)) {
			$table.DataTable().destroy();
		}

		$table.DataTable({
			processing: true,
			serverSide: true,
			ajax: {
				url: `${config.API_URL}/salesProductReport`,
				type: 'POST',
				headers: {
					authorization: `Bearer ${accessToken}`,
				},
				data: function (d) {
					return $.extend({}, d, {
						draw: d.draw,
						start: d.start,
						length: d.length,
						searchValue: searchValue,
						reportrange: reportrange,
						brand_id: salesbrand,
						category_id: salescategory,
						authorization: `Bearer ${accessToken}`,
					});
				},
				dataSrc: function (json) {
					if (json && json.data && Array.isArray(json.data)) {
						setInventoryReport(json.data);
					}

					return Array.isArray(json.data) ? json.data : [];
				},
			},
			language: {
				paginate: {
					first: 'First',
					last: 'Last',
					next: 'Next',
					previous: 'Previous',
				},
				search: 'Search:',
			},
			columns: [
			    { data: null, title: 'Sr. No.', orderable: false },  // Sr. No. column
			    { data: 'product_code'},
			    { data: 'product_name'},
			    { data: 'BoxQuantity'},
			    { data: 'PiecesQuantity'},
			    { data: 'PromoQuantity'},
			    {
			    	data: 'total',
			    	render: function (data, type, row) {
			    		var total = parseFloat(data);
			    		return !isNaN(total) ? total.toFixed(2) : '0.00';
			    	}
			    }
			    ],

			columnDefs: [
			{
            targets: [0], // Target Sr. No. column
            orderable: false,
            createdCell: function (td, cellData, rowData, row, col) {
                $(td).html(row + 1); // Set Sr. No. dynamically
            }
        }
        ],
			
			pageLength: 10,
		});
	};

	useEffect(() => {
		fetchReport();
		fetchCategoriesOptions();
		fetchBrandOptions();
	}, [reportrange]);




	const searchFormData = () => {
	
		fetchReport();
	};
	const handleOptionChange = (event) => {
		const value = event.target.value;
		setSalescategory(value);
	};

	const handleOptionChangebrand = (event) => {
		const value = event.target.value;
		setSalesbrand(value);
	};

	return (
		<div>
		<Header />
		<div className="breadcrumbs">
		<div className="container-fluid">
		<div className="row">
		<div className="col-12 col-lg-12 col-sm-12">
		<Link to="/Dashboard"><i className="fa fa-home"></i></Link>
		<Link to="/reports/inventory_sales_report">Reports</Link>
		<Link to="/reports/sales_product_report">Sales Products report</Link>
		</div>
		</div>
		</div>
		</div>

		<div className="main-container">
		<div className="container-fluid">
		<div className="row">
		<div className="date-range-picker col-md-4 col-lg-4 col-sm-12" style={{ float: 'left' }}>
		<div class="row mb-3 padd-0">
		<label class="col-sm-3" style={{ marginBottom: '5px' }} ><b>Select Date</b></label>
		<div class="col-sm-9">
		<DateRangePicker
		value={dates}
		placeholder="DD/MM/YYYY - DD/MM/YYYY"
		onChange={handleDateChange}
		format="dd/MM/yyyy"
		character=" - "
		style={{ width: '100%' }}
		/>
		</div>
		</div>
		</div>

		<div className="date-range-picker col-md-3 col-lg-3 col-sm-12" style={{ float: 'left' }}>
		<div class="row mb-3 padd-0">
		<label class="col-sm-4" style={{ marginBottom: '5px' }} ><b>Select Category</b></label>
		<div class="col-sm-8">
		<select value={salescategory} onChange={handleOptionChange} className="form-control selectinput" name="" id="" >
		<option value="">Select a Category</option>
		{categoryOptions.map(option => (
			<option key={option.value} value={option.value}>
			{option.label}
			</option>
			))}
			</select>
			</div>
			</div>
			</div>

			<div className="date-range-picker col-md-3 col-lg-3 col-sm-12" style={{ float: 'left' }}>
			<div class="row mb-3 padd-0">
			<label class="col-sm-4" style={{ marginBottom: '5px' }} ><b>Select Brands</b></label>
			<div class="col-sm-8">
			<select value={salesbrand} onChange={handleOptionChangebrand} className="form-control selectinput" name="" id="" >
			<option value="">Select Brands</option>
				{Array.isArray(brandOptions) && brandOptions.map(option => (
			   <option key={option.value} value={option.value}>
			      {option.label}
			   </option>
			))}
			</select>
			</div>
			</div>
			</div>

			<div className="col-md-2 col-lg-2 col-sm-6" style={{ float: 'left' }} >
			<button type="button" onClick={searchFormData} className="acceptbtn" style={{ float: 'left' }} >Submit</button>
			</div>


			</div>

			<div className="total-manifests-area dashboard">
			<p><b> Sales Product Report </b></p>
			<div className="verical-scrl-wrapper common-table autoheight-table salesproduct" id="style-3">
			<table ref={salesOrderRef} className="table table-bordered resizable" id="inventorySalesReport">
			<thead>
			<tr>
			<th>SR</th>										
			<th>Cost</th>
			<th>Name</th>
			<th>Total Box Qty</th>
			<th>Total Piece Qty</th>
			<th>Total Promo Qty</th>
			<th>Total Amount</th>

			</tr>
			</thead>
			<tbody>

			</tbody>
			</table>
			</div>
			</div>

			</div>
			</div>

			<Footer />
			</div>
			);

		}

		export default InventorySalesReport;
