import jsPDF from 'jspdf';
import 'jspdf-autotable';
import config from "../common/Config";
import axios from 'axios';

const generateUniqueFilename = (baseName, extension) => {
  const timestamp = new Date().toISOString().replace(/[-:.]/g, "");
  return `${baseName}_${timestamp}.${extension}`;
};

const printNewSalesInvoice = async (orderId) => {
  const accessToken = localStorage.getItem('accessToken');
  const formData = new FormData();
  formData.set('orderId', orderId);
  const apiUrl = `${config.API_URL}/ws-order-history-details`;

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);  
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const day = String(date.getDate()).padStart(2, '0');

    return `${day}-${month}-${year}`;  
  };

  try {
    const response = await axios.post(apiUrl, formData, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    });

    const data = response.data;
    console.log(data);

    if (!data.success || !data.details.orderDetails || data.details.orderDetails.length === 0) {
      console.error("No order details found in the response.");
      return;  
    }

    const orderDetails = data.details.orderDetails[0];  
    const shopDetails = data.details.shop_details;
    console.log(orderDetails);

     const box_qty = data.details.product_details
    .filter(item => item.quantity != '0.00' && item.quantity != null && item.order_type === 'box')
    .reduce((sum, item) => sum + (parseFloat(item.quantity)), 0);

    const piece_qty = data.details.product_details
    .filter(item => item.quantity != '0.00' && item.quantity != null && item.order_type === 'piece')
    .reduce((sum, item) => sum + (parseFloat(item.quantity)), 0);

    const totalstd = data.details.product_details
    .filter(item => item.item_tax != '0.0000' )
    .reduce((sum, item) => sum + (parseFloat(item.unit_price) * parseFloat(item.order_qty)), 0);

    const totalzeroGoods = data.details.product_details
    .filter(item => item.item_tax == '0.0000' )
    .reduce((sum, item) => sum + (parseFloat(item.unit_price) * parseFloat(item.order_qty)), 0);

    const totalSTDGoods = parseFloat(totalstd) - parseFloat(totalzeroGoods);
    const orderDiscount = parseFloat(data.details.orderDetails[0].order_discount) || 0;
    const orderDiscount1 = parseFloat(data.details.orderDetails[0].order_discount1) || 0;
    const over_all_discount = parseFloat(data.details.orderDetails[0].over_all_discount) || 0;
    const over_all_amount = parseFloat(data.details.orderDetails[0].over_all_amount) || 0;

    const totalVAT = data.details.product_details
    .reduce((sum, item) => sum + (item.item_tax ? parseFloat(item.item_tax) : 0), 0);

    const totalAmount = totalstd + totalzeroGoods + totalVAT - orderDiscount - over_all_amount;

    const itemTotal = totalstd + totalzeroGoods;


    const doc = new jsPDF('portrait'); // Set to portrait orientation
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const margin = 5;  // 5px padding
    let y = margin;
    const firstColumnWidth =  0.00;  // 30% of the page width
    const secondColumnWidth = pageWidth * 0.60; 

    const H2firstColumnWidth = pageWidth * 0.64;  // 45% of the page width
    const H2secondColumnWidth = pageWidth * 0.30; // 27.5% of the page width
    const H2thirdColumnWidth = pageWidth * 0.20; 
     const H2firstColumnWidth1 = pageWidth * 0.45;  // 27.5% of the page width
    // Add the logo image to the header (make sure the image path is correct)
    const topMargin = 10; // Adjust this value for the desired top margin
    const logoUrl = `${config.BASE_URL}/assets/img/${accessToken}_logo.png`; // Path to the logo image
    const logoWidth = 27; // Width of the logo in the PDF
    const logoHeight = 27; // Height of the logo
    const logoX = pageWidth - logoWidth - margin - 10; // Position the logo on the right side with some margin
    const logoY = 5;
    const a = topMargin; // Start position with top margin
    doc.addImage(logoUrl, 'PNG', logoX, logoY,  logoWidth, logoHeight); // Adds the logo with top margin
    const newY = a + logoHeight;

    // Now create the other sections of the header
    const headerSections2 = [

    	[
    		{ text: `${shopDetails.shop_name}`, size: 16, bold: true },
    		{text: `${shopDetails.address}`,size: 8,bold: false},
        {text:'',size: 8,bold: false},
        { text:`Company No : ${shopDetails.company_reg_no} | VAT No : ${shopDetails.vat_no}`, size: 8, bold: false, margin: [0, 3, 0, 3] },
        { text:`Email : ${shopDetails.email}`, size: 8, bold: false, margin: [0, 3, 0, 3] },
        { text:`Head Office : 07712345675 | Sales No : 07712345674`, size: 8, bold: false, margin: [0, 3, 0, 3] },
        ],

    	[
        {text:'',size: 8,bold: false},
        {text:'',size: 8,bold: false},
        {text:'',size: 8,bold: false},
        { text: `INVOICE`, size: 16, bold: true },
        ],
      [
    // Add shop details (below logo)
        ],
      ];


    const headerSections3 = [

      [

        { text: `${orderDetails.company}`, size: 10, bold: true },
        {text: `Phone No : ${orderDetails.phone}  | Email Id : ${orderDetails.email}`,size: 8, bold: false},
        { text: `Address  : ${orderDetails.address}`, size: 8, bold: false },
        { text: `${orderDetails.city} ,${orderDetails.postal_code}`, size: 8, bold: false },
        ],
       [
        
        { text: `Invoice No : ${orderDetails.reference_no}`, size: 8, bold: false },
        { text: `Order Date: ${formatDate(orderDetails.date)}`, size: 8, bold: false },
        { text: `Previous Balance: 0.00`, size: 8, bold: false },
        { text: `Current Balance : ${orderDetails.grand_total}`, size: 8, bold: false },
        { text: `Account Code : ${orderDetails.account_no}`, size: 8, bold: false },

        ],
     
      ];


    const sectionWidth = (pageWidth - margin * 3) / 3; // Adjust for margins

    // Render header sections
    y = 10;
 const renderSections = (sections) => {
  let rowHeight = 0; // To store the maximum height of columns in a row
  let rowStartY = y; // Track the starting Y position for each row of 3 columns
  let columnHeights = []; // To store individual column heights within a row

  sections.forEach((section, index) => {
    // Column X position based on section index (for a 3-column layout)
    let colStartX;
    if (index % 3 === 0) {
      colStartX = margin;
    } else if (index % 3 === 1) {
      colStartX = margin + H2firstColumnWidth1;
    } else {
      colStartX = margin + H2firstColumnWidth1 + H2secondColumnWidth;
    }

    let sectionHeight = 0; // To track the height of the current section

    section.forEach((item, i) => {
      doc.setFontSize(item.size);
      doc.setFont(item.bold ? 'helvetica' : 'helvetica', item.bold ? 'bold' : 'normal');

      // Wrap text if it exceeds column width (maxWidth)
      let maxWidth;
      if (index % 3 === 0) {
        maxWidth = H2firstColumnWidth1 - 5; // First column width
      } else if (index % 3 === 1) {
        maxWidth = H2secondColumnWidth - 5; // Second column width
      } else {
        maxWidth = H2thirdColumnWidth - 5; // Third column width
      }

      const textWidth = doc.getTextWidth(item.text);
      let lines = [];
      if (textWidth > maxWidth) {
        // Split text into multiple lines
        let words = item.text.split(' ');
        let line = '';

        words.forEach((word) => {
          const testLine = line + (line ? ' ' : '') + word;
          if (doc.getTextWidth(testLine) <= maxWidth) {
            line = testLine;
          } else {
            lines.push(line);
            line = word;
          }
        });
        lines.push(line); // Push remaining line
      } else {
        lines.push(item.text); // No wrapping needed, just one line
      }

      // Render each line of text
      lines.forEach((lineText, lineIndex) => {
        const lineHeight = 4; // Adjust vertical spacing for each line
        doc.text(lineText, colStartX + 5, y + 5 + i * lineHeight + lineIndex * lineHeight);
        sectionHeight = y + i + lineIndex - 5; // Update section height
      });
    });

    // Store the height of the current section (column height)
    columnHeights.push(sectionHeight);

    // After every 3 columns (3 sections), calculate the maximum height for this row
    if ((index + 1) % 3 === 0) {
      rowHeight = Math.max(...columnHeights); // Get the maximum height in this row
      columnHeights = []; // Reset for the next row

      y += rowHeight + 12; // Add some space after each row of columns
    }
  });

  // Draw a final horizontal line at the end of all sections
 doc.line(margin, y + 5, margin + H2firstColumnWidth1 + H2secondColumnWidth + H2thirdColumnWidth + 1, y + 5);

//doc.line(margin + 5, y, margin + 5 + H2firstColumnWidth + H2secondColumnWidth + H2thirdColumnWidth, y);


  y += 10; // Add a little space after all header sections
};


    const h2renderSections = (sections, topMargin = 15) => {
  let rowHeight = 0; // To store the maximum height of columns in a row
  let rowStartY = y; // Track the starting Y position for each row of 2 columns
  let columnHeights = []; // To store individual column heights within a row

  sections.forEach((section, index) => {
    // Column X position based on section index (for a 2-column layout)
    let colStartX;
    if (index % 2 === 0) {
      colStartX = margin;
    } else {
      colStartX = margin + H2firstColumnWidth; // Adjust for the second column
    }

    let sectionHeight = 0; // To track the height of the current section

    section.forEach((item, i) => {
      doc.setFontSize(item.size);
      doc.setFont(item.bold ? 'helvetica' : 'helvetica', item.bold ? 'bold' : 'normal');

      // Wrap text if it exceeds column width (maxWidth)
      let maxWidth;
      if (index % 2 === 0) {
        maxWidth = H2firstColumnWidth - 5; // First column width
      } else {
        maxWidth = H2secondColumnWidth - 5; // Second column width
      }

      const textWidth = doc.getTextWidth(item.text);
      let lines = [];
      if (textWidth > maxWidth) {
        // Split text into multiple lines
        let words = item.text.split(' ');
        let line = '';

        words.forEach((word) => {
          const testLine = line + (line ? ' ' : '') + word;
          if (doc.getTextWidth(testLine) <= maxWidth) {
            line = testLine;
          } else {
            lines.push(line);
            line = word;
          }
        });
        lines.push(line); // Push remaining line
      } else {
        lines.push(item.text); // No wrapping needed, just one line
      }

      // Render each line of text
      lines.forEach((lineText, lineIndex) => {
        const lineHeight = 4; // Adjust vertical spacing for each line
        doc.text(lineText, colStartX + 5, y + 5 + i * lineHeight + lineIndex * lineHeight);
        sectionHeight = y + i + lineIndex - 5; // Update section height
      });
    });

    // Store the height of the current section (column height)
    columnHeights.push(sectionHeight);

    // After every 2 columns (2 sections), calculate the maximum height for this row
    if ((index + 1) % 2 === 0) {
      rowHeight = Math.max(...columnHeights); // Get the maximum height in this row
      columnHeights = []; // Reset for the next row

      // Draw borders for both columns in the row
      let colX = margin;
      for (let i = 0; i < 2; i++) {
        doc.setLineWidth(0.1); // Border line width
        let colWidth = (i === 0) ? H2firstColumnWidth : H2secondColumnWidth;
        doc.rect(colX, rowStartY - 2, colWidth, rowHeight-15); // Draw the rectangle for the column with a small padding
        colX += colWidth; // Update X position for the next column
      }

      // Move the Y position down for the next row of columns
      y += rowHeight - 12; // Add some space after each row of columns
    }
  });

  y += 0; 
};

    
    renderSections(headerSections2);
    h2renderSections(headerSections3);
    // Render table columns with customized widths
    const tableColumns = [
      { title: 'Code', dataKey: 'code', size: 6, bold: false },
   		{ title: 'Qty', dataKey: 'qty', size: 6, bold: false },
    	{ title: 'Order Type', dataKey: 'order_type', size: 6, bold: false },
     	{ title: 'Product', dataKey: 'product', size: 6, bold: false },
      { title: 'Unit Price', dataKey: 'unit_price', size: 6, bold: false },
      { title: 'Discount', dataKey: 'discount', size: 6, bold: false },
      { title: 'VAT', dataKey: 'vat', size: 6, bold: false },
      { title: 'Net Amount', dataKey: 'net_amount', size: 6, bold: false },
      ];

    // Calculate the table widths
    const totalWidth = pageWidth - 2 * margin;
    const productWidth = totalWidth * 0.39;  
    const orderTypeWidth = totalWidth * 0.10;  
    const qtyWidth = totalWidth * 0.06; 
    const codeWidth = totalWidth * 0.06; 
    
    const unitPriceWidth = totalWidth * 0.10;  
    const discountWidth = totalWidth * 0.08;  
    const vatWidth = totalWidth * 0.10; 
    const netAmountWidth = totalWidth * 0.10; 


  const tableBody = data.details.product_details.map((item) => {
      // Determine the discount text and class
      let discountText = '';
      let discountColor = '';

      if (item.discount !== '0.0' && item.discount !== 0.0) {
        discountText = `(${parseFloat(item.discount).toFixed(0)} % )`;
        // Apply a red color style if the discount is 100%
        if (item.discount === 100.00 || item.discount === '100.00') {
          discountColor = 'red'; // Change the color when discount is 100%
        }
      }

      return [
        item.product_code,
        parseInt(item.quantity),
        item.order_type,
        `${item.product_name} - ${item.size}`,
        `£ ${parseFloat(item.unit_price).toFixed(2)}`,
        discountText ? discountText : '',  // Only display discount if it exists
        `£ ${parseFloat(item.item_tax).toFixed(2)}`,
        `£ ${parseFloat((item.quantity * item.unit_price) - item.item_discount).toFixed(2)}`
      ];
    });


  


    doc.autoTable({
  startY: y,
  head: [tableColumns.map(col => col.title)],
  body: tableBody,
  theme: 'grid',
  headStyles: {
    fillColor: '#f8f9fa',
    textColor: '#404040',
    fontSize: 8,  // Font size for header
    valign: 'middle', // Vertical alignment for header
    lineWidth: 0.3, // Border thickness
    lineColor: [0, 0, 0]  // Black color for borders
  },
  bodyStyles: {
    fontSize: 8,  // Font size for body rows
    valign: 'middle', // Vertical alignment for body rows
    lineWidth: 0.3,  // Border thickness for body rows
    lineColor: [0, 0, 0]  // Black color for borders
  },
  margin: { top: 5, left: 5, right: margin },
  columnStyles: {
     0: { cellWidth: codeWidth, halign: 'center' },
    1: { cellWidth: qtyWidth, halign: 'center' },
    2: { cellWidth: orderTypeWidth, halign: 'center' },
    3: { cellWidth: productWidth },
    4: { cellWidth: unitPriceWidth, halign: 'right' },
    5: { cellWidth: discountWidth, halign: 'center' },
    6: { cellWidth: vatWidth, halign: 'right' },
    7: { cellWidth: netAmountWidth, halign: 'right' }
  }
});


    


    y = doc.autoTable.previous.finalY + 5; // Y position after table

    const addFooterSection = (footerSection) => {
      footerSection.forEach((section, index) => {
        const colStartX = margin + (index % 4) * sectionWidth;

        section.forEach((item, i) => {
          doc.setFontSize(item.size);
          doc.setFont(item.bold ? 'helvetica' : 'helvetica', item.bold ? 'bold' : 'normal');
          doc.text(item.text, colStartX, y + i * 5);
        });

        if (index % 4 === 3) {
          y += 20; // Move down after every 4 sections
        }
      });
      y += 10; // Add space after each footer section
    };

    const addFooterWithSpaceCheck = (footerSection) => {
      const remainingSpace = pageHeight - y - margin; // Remaining space on the page
      const requiredSpace = footerSection.length * 5 + 20; // Space needed

      if (remainingSpace < requiredSpace) {
        doc.addPage(); // Add a new page if not enough space
        y = margin; // Reset Y position for new page
      }

      addFooterSection(footerSection);
    };

    const footerSections2 = [
      [
      	{ text: 'Bank Details.', size: 8, bold: true },
       { text: 'Bank name:TSB             | Sort Code:77-72-69              | Account Number:00067865             | SWIFT:                |  IBAN:                 |  Reference:             ', size: 8, bold: false },
        ]
      ];

addFooterWithSpaceCheck(footerSections2);


const renderPaymentTypesInOneRow = (sections, topMargin = 40) => {
 // let y = topMargin;  // Starting Y position
  
  // Define the box height (same for both boxes)
  const boxHeight = 43;  // Height of the box, you can adjust this value as needed

  // Define the first box's position (left box)
  const firstBoxX = margin;  // X position for the first box
  const firstBoxWidth = 140;  // Width of the first box

  // Draw the first box for Payment Type and Payment Terms
  doc.setDrawColor(0, 0, 0);
  doc.setLineWidth(0.3);
  doc.rect(firstBoxX, y, firstBoxWidth, boxHeight);  // First box with width 140

  // Loop through the first section (Payment Type) and render it inside the first box
  sections[0].forEach((item, i) => {
    // Set the font for each text item
    doc.setFontSize(item.size);
    doc.setFont(item.bold ? 'helvetica' : 'helvetica', item.bold ? 'bold' : 'normal');
    
    // If the text is "Payment Type", just draw the label without a checkbox
    if (item.text === "Payment Type") {
      // Draw the "Payment Type" label
      doc.text(item.text, firstBoxX + 2, y + 5 + i * 8); // Text position
    } else {
      // For other items like CASH, CHEQUE, etc., we add checkboxes and labels
      const checkboxSize = 3;  // Define size of the checkbox
      const checkboxOffsetX = firstBoxX + 3;  // X position of checkbox (closer to text)

      // Draw the text for each payment type
      doc.text(item.text, firstBoxX + 8, y + 5 + i * 8); // Move text closer to checkbox

      // Draw checkboxes for items that contain "CASH", "CHEQUE", "CARD", or "NO PAYMENT"
      if (item.text.includes("CASH") || item.text.includes("CHEQUE") || item.text.includes("CARD") || item.text.includes("NO PAYMENT")) {
        const checkboxY = y + 3 + i * 8; // Adjust Y position to bring checkbox closer to label
        doc.setDrawColor(0, 0, 0); // Set color for the checkbox outline
        doc.setLineWidth(0.2);
        doc.rect(checkboxOffsetX, checkboxY, checkboxSize, checkboxSize); // Draw the checkbox
      }
    }
  });

  // Loop through the second section (Payment Terms) and render it inside the first box
  sections[1].forEach((item, i) => {
    doc.setFontSize(item.size);
    doc.setFont(item.bold ? 'helvetica' : 'helvetica', item.bold ? 'bold' : 'normal');
    
    // Draw text on the right side of the first box
    doc.text(item.text, firstBoxX + 35, y - 28 + (sections[0].length + i) * 8);
  });

  // Add space before drawing the second box (Sub Total, VAT Total, Discount Total, Total)
  y += boxHeight + -43;

  // Define the second box's X position for top-right corner
  const pageWidth = doc.internal.pageSize.width;  // Get the page width dynamically
  const secondBoxWidth = 55;  // Width of the second box
  const secondBoxHeight = 43;  // Height of the second box

  // Position the second box at the top-right (subtract width of the box and any margins)
  const secondBoxX = pageWidth - secondBoxWidth - margin;  // Right edge of the page, minus the box's width and margin

  // Draw the second box for Sub Total, VAT Total, Discount Total, Total at the top-right
  doc.setDrawColor(0, 0, 0);
  doc.setLineWidth(0.3);
  doc.rect(secondBoxX, y, secondBoxWidth, secondBoxHeight);  // Second box

  // Loop through the third section (Sub Total, VAT Total, Discount Total, Total)
  sections[2].forEach((item, i) => {
    doc.setFontSize(item.size);
    doc.setFont(item.bold ? 'helvetica' : 'helvetica', item.bold ? 'bold' : 'normal');
    
    // Draw text for Sub Total, VAT Total, Discount Total, Total in the second box
    doc.text(item.text, secondBoxX + 5, y + 5 + i * 8);
  });

  // Add some space after the second box (to position the next element)
  y += secondBoxHeight + 5;
};


// Define the sections (Payment Type on the left, Payment Terms on the right, and the second Payment Terms in a separate box)
const headerSections4 = [
  [ // Left section: Payment Type
    { text: `Payment Type`, size: 8, bold: true },  // This is the label, no checkbox here
    { text: `CASH`, size: 8, bold: false },
    { text: `CHEQUE`, size: 8, bold: false },
    { text: `CARD`, size: 8, bold: false },
    { text: `NO PAYMENT`, size: 8, bold: false },
  ],
  [ // Right section: Payment Terms (first part)
    { text: `Payment Terms: C.O.D / 0 DAYS`, size: 8, bold: false },
    { text: `Payment Amount: £___________          Signature________________`, size: 8, bold: false },
    { text: `Name: _______________________`, size: 8, bold: false },
 
    { text: `Box : ${box_qty} PCs : ${piece_qty} `, size: 8, bold: false}
  ],
  [ // Second Right section: Sub Total, VAT Total, Discount Total, Total
    { text: `Sub Total : £ ${itemTotal.toFixed(2)}`, size: 8, bold: false },
    { text: `VAT Total: £ ${totalVAT.toFixed(2)}`, size: 8, bold: false },
    { text: `Invoice Discount: £ ${over_all_amount.toFixed(2)}`, size: 8, bold: false },
    { text: `Promo Discount: £ ${orderDiscount.toFixed(2)}`, size: 8, bold: false },
    { text: `Total: £ ${totalAmount.toFixed(2)}`, size: 8, bold: true },
  ]
];

// Call the function with headerSections4
renderPaymentTypesInOneRow(headerSections4);




 const addFooterWithCompanyDetails = (footerSection) => 
 {
  const topMargin = 0; // Define the top margin for the footer
  const remainingSpace = pageHeight - y - margin - topMargin; // Adjust remaining space to account for top margin
  const requiredSpace = footerSection.length * 5 + 0; // Space needed for footer

  if (remainingSpace < requiredSpace) 
  {
    doc.addPage(); // Add a new page if not enough space
    y = margin + topMargin; // Reset Y position for the new page, including the top margin
  }
footerSection[0][0].text = footerSection[0][0].text.replace('Page No:', `Page No: ${doc.internal.getNumberOfPages()}`);
  // Add top margin before the footer
  y += topMargin;

  // Now, call the function to render the footer
  addFooterSection(footerSection);
};

    const footerSections3 = [
      [
      
       { text: 'Company Reg. No:TSB                      | VAT No:77-72-69                   | AWRS No:00067865                | Page No:                     ', size: 8, bold: false },
         { text: `Note: ${orderDetails.staff_note}`, size: 8, bold: true },
        ]
      ];

addFooterWithCompanyDetails(footerSections3);


    const filename = generateUniqueFilename('Invoice', 'pdf');
    // doc.save(filename);

    doc.autoPrint();
    window.open(doc.output('bloburl'));

  } catch (error) {
    console.error('Error generating PDF:', error);
  }
};

export default printNewSalesInvoice;
