import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import Header from '../../Header';
import Footer from '../../Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import config from "../../common/Config";
import Swal from 'sweetalert2';
import axios from 'axios';


const AddCustomersForm = () => {

	const accessToken = localStorage.getItem('accessToken');

  const userId = localStorage.getItem('userId');

  const [isSubmitting, setIsSubmitting] = useState(false);

	const [businessType, setBusinessType] = useState([]);
  const[businessRute, setBusinessRute] = useState([]);
  const[businessCategory, setBusinessCategory] = useState([]);
  const [errors, setErrors] = useState({});

	  const fetchBusinessTypeList = async () =>{
	   

	    const apiBTUrl = `${config.API_URL}/ws-get-business-types_web`;
	    try{
	      const response = await fetch(apiBTUrl, {
	        method : 'POST',
	        headers : {
	          authorization : `Bearer ${accessToken}`,
	        },
	      });

	      if(!response.ok){
	        throw new Error("Failed to fetch brand list");
	      }

	      const data = await response.json();

	      setBusinessType(data.businessTypes ? data.businessTypes : []);

	    }catch (error){
	      console.log("Error fetch brand list", error);
	    }

	  };
	  const fetchBusinessCategoryList = async () =>{
	   

	    const apiBCUrl = `${config.API_URL}/ws-get-business-categories_web`;
	    try{
	      const response = await fetch(apiBCUrl, {
	        method : 'POST',
	        headers : {
	          authorization : `Bearer ${accessToken}`,
	        },
	      });

	      if(!response.ok){
	        throw new Error("Failed to fetch brand list");
	      }

	      const data = await response.json();



	      setBusinessCategory(data.businessCategories ? data.businessCategories : []);

	    }catch (error){
	      console.log("Error fetch brand list", error);
	    }

	  };

	  const fetchBusinessRouteList = async () =>{
	    const brForm = new FormData();

	    brForm.append("platform", 'web');

	    const apiBRUrl = `${config.API_URL}/getRoute`;
	    try{
	      const response = await fetch(apiBRUrl, {
	        method : 'POST',
	        headers : {
	          authorization : `Bearer ${accessToken}`,
	        },
	        body : brForm,
	      });

	      if(!response.ok){
	        throw new Error("Failed to fetch brand list");
	      }

	      const data = await response.json();
        

	      setBusinessRute(data.data ? data.data : []);
	   

	    }catch (error){
	      console.log("Error fetch brand list", error);
	    }

	  };

	const [formData, setFormData] = useState({
	    companyName: '',
	    firstName: '',
	    emailId: '',
	    phone: '',
	    accound_no : '',
	    route : '',
	    addressLine1: '',
	    city: '',
	    postalCode: '',
	    county: '',
	    vatNo: '',
	    default_delivery : '',
	    typeOfBusiness: '',
	    businessCategory: '',
	    product_price : '',
	    credit_facility : '',
	    day_wise : '0',
	    total_day : '',
	    invoice_wise : '0',
	    total_invoice : '',
	    amount_wise : '0',
	    total_amount : '',
  	});

  const navigateToCustomerList = useNavigate(); 

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked ? '1' : '0', // Set '1' if checked, null if unchecked
    }));
  };

  useEffect(() => {

    if (accessToken === null) {
      window.location.href = `${config.BASE_URL}/login`;
      return;
    }

    fetchBusinessRouteList(); 
    fetchBusinessTypeList();
    fetchBusinessCategoryList();


    

  }, []);


  const [creditFacility, setCreditFacility] = useState('0'); // Default to Credit Facility Cash

  const handleRadioChange = (event) => {
    setCreditFacility(event.target.value);
  };


  const validateForm = () => {
    const newErrors = {};

    // Company validation
    if (!formData.companyName) {
      newErrors.companyName = "Mention business Name.";
    } else if (formData.companyName.length < 2) {
      newErrors.companyName = "business name must consist of at least 2 characters.";
    } else if (formData.companyName.length > 255) {
      newErrors.companyName = "business name must not exceed 255 characters.";
    }

    // Name validation
    if (!formData.firstName) {
      newErrors.firstName = "Mention Name.";
    } else if (formData.firstName.length < 2) {
      newErrors.firstName = "Name must consist of at least 2 characters.";
    } else if (formData.firstName.length > 255) {
      newErrors.firstName = "Name must not exceed 255 characters.";
    }

    // Phone validation
    if (!formData.phone) {
      newErrors.phone = "Phone is Required.";
    } else if (!/^\d{10,12}$/.test(formData.phone)) {
      newErrors.phone = "Phone number must be between 10 and 12 digits.";
    }

   
    // Account Number 
    if (!formData.accound_no) {
      newErrors.accound_no = "Mention Name.";
    } else if (formData.accound_no.length < 2) {
      newErrors.accound_no = "Name must consist of at least 2 characters.";
    } else if (formData.accound_no.length > 15) {
      newErrors.accound_no = "Name must not exceed 15 digits.";
    }

    if (!formData.route) {
      newErrors.route = "Mention Route.";
    } 
    if (!formData.typeOfBusiness) {
      newErrors.typeOfBusiness = "Mention typeOfBusiness.";
    }
    if (!formData.businessCategory) {
      newErrors.businessCategory = "Mention businessCategory.";
    }
    if (!formData.default_delivery) {
      newErrors.default_delivery = "Mention Delivery Days.";
    } 

    
    if (!formData.addressLine1) {
      newErrors.addressLine1 = "Mention Address Name.";
    } else if (formData.addressLine1.length < 2) {
      newErrors.addressLine1 = "Address name must consist of at least 2 characters.";
    } else if (formData.addressLine1.length > 255) {
      newErrors.addressLine1 = "Address name must not exceed 255 characters.";
    }

    if (!formData.city) {
      newErrors.city = "Mention city Name.";
    } else if (formData.city.length < 2) {
      newErrors.city = "city name must consist of at least 2 characters.";
    } else if (formData.city.length > 255) {
      newErrors.city = "city name must not exceed 255 characters.";
    }

    if (!formData.county) {
      newErrors.county = "Mention county Name.";
    } else if (formData.county.length < 2) {
      newErrors.county = "county name must consist of at least 2 characters.";
    } else if (formData.county.length > 255) {
      newErrors.county = "county name must not exceed 255 characters.";
    }

    if (!formData.postalCode) {
      newErrors.postalCode = "Mention postal code .";
    } else if (formData.postalCode.length < 2) {
      newErrors.postalCode = "postal code name must consist of at least 2 digit.";
    } else if (formData.county.length > 255) {
      newErrors.postalCode = "postal code name must not exceed 255 digit.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
   
    setFormData({ ...formData, [name]: value });

    let fieldErrors = {};

    // Validate the input field immediately as the user types
    switch (name) {
      case 'companyName':
        if (!value) {
          fieldErrors.companyName = "Mention business Name.";
        } else if (value.length < 2) {
          fieldErrors.companyName = "business name must consist of at least 2 characters.";
        } else if (value.length > 255) {
          fieldErrors.companyName = "business name must not exceed 255 characters.";
        }
        if (!fieldErrors.companyName) {
           fieldErrors.companyName = '';
        }
        break;

      case 'firstName':
        if (!value) {
          fieldErrors.firstName = "Mention Name.";
        } else if (value.length < 2) {
          fieldErrors.firstName = "Name must consist of at least 2 characters.";
        } else if (value.length > 255) {

          fieldErrors.firstName = "Name must not exceed 255 characters.";
        }
        if (!fieldErrors.firstName) {
          fieldErrors.firstName = '';
        }
        break;

      case 'phone':
        if (!value) {
          fieldErrors.phone = "Phone is Required.";
        } else if (!/^\d{10,12}$/.test(value)) {
          fieldErrors.phone = "Phone number must be between 10 and 12 digits.";
        }
        if (!fieldErrors.phone) {
          
          fieldErrors.phone = '';
        }
        break;
        
      case 'accound_no':        
        if (!value) {
          fieldErrors.accound_no = "Account Number is Required.";
        } else if (value.length < 2) {
          fieldErrors.accound_no = "No consist of at least 2 digits.";
        } else if (value.length > 15) {
          fieldErrors.accound_no = "No must not exceed 15 digits.";
        }
        if (!fieldErrors.accound_no) {
           fieldErrors.accound_no = '';
        }
        break;      

      case 'route':
        if (!value) {
          fieldErrors.route = "Mention route.";
        } 
        if (!fieldErrors.route) {
           fieldErrors.route = '';
        }
        break;

      case 'typeOfBusiness':
        if (!value) {
          fieldErrors.typeOfBusiness = "Mention typeOfBusiness.";
        } 
        if (!fieldErrors.typeOfBusiness) {
           fieldErrors.typeOfBusiness = '';
        }
        break;

      case 'businessCategory':
        if (!value) {
          fieldErrors.businessCategory = "Mention businessCategory.";
        } 
        if (!fieldErrors.businessCategory) {
           fieldErrors.businessCategory = '';
        }
        break;



       case 'default_delivery':
        if (!value) {
          fieldErrors.default_delivery = "Mention Delivery Days.";
        } 
        if (!fieldErrors.default_delivery) {
           fieldErrors.default_delivery = '';
        }
        break;

      case 'addressLine1':
        if (!value) {
          fieldErrors.addressLine1 = "Mention Address.";
        } else if (value.length < 2) {
          fieldErrors.addressLine1 = "Address must consist of at least 2 characters.";
        } else if (value.length > 255) {
          fieldErrors.addressLine1 = "Address must not exceed 255 characters.";
        }
        if (!fieldErrors.addressLine1) {
          fieldErrors.addressLine1 = '';
        }
        break;

       case 'city':
        if (!value) {
          fieldErrors.city = "Mention city.";
        } else if (value.length < 2) {
          fieldErrors.city = "city must consist of at least 2 characters.";
        } else if (value.length > 255) {
          fieldErrors.city = "city must not exceed 255 characters.";
        }
        if (!fieldErrors.city) {
          fieldErrors.city = '';
        }
        break;

        case 'county':
        if (!value) {
          fieldErrors.county = "Mention county.";
        } else if (value.length < 2) {
          fieldErrors.county = "county must consist of at least 2 characters.";
        } else if (value.length > 255) {
          fieldErrors.county = "county must not exceed 255 characters.";
        }
        if (!fieldErrors.county) {
          fieldErrors.county = '';
        }
        break;

        case 'postalCode':
        if (!value) {
          fieldErrors.postalCode = "Mention postal code.";
        } else if (value.length < 2) {
          fieldErrors.postalCode = "postal code must consist of at least 2 digit.";
        } else if (value.length > 255) {
          fieldErrors.postalCode = "postal code must not exceed 255 digit.";
        }
        if (!fieldErrors.postalCode) {
          fieldErrors.postalCode = '';
        }
        break;


        
     
     
      default:
        break;
    }

    // Update errors state for the specific field
    setErrors((prevErrors) => ({
      ...prevErrors,
      ...fieldErrors
    }));
  };

  const submitAddCustomerForm = async () => {

    if (!validateForm()) return; // Validate before submission

    const form = document.getElementById('addcustomerform');

    setIsSubmitting(true);


    const formData = new FormData(form);
    try {
      const response = await axios.post(`${config.API_URL}/add_customer_details`, formData, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        }
      });

      const result = response.data;

      const msg = result.message;
    
      if (result.success == true){
          

          Swal.fire({
            title: "Success!",
            text: "Customer added successfully.",
            icon: "success",
            timer: 1500,
            showConfirmButton: false,
          }).then(() => 
          {
             window.location.href = config.BASE_URL+'/customers';
          });

      }else{
        Swal.fire({
          title: "Failed!",
          text: msg,
          icon: "error",
          timer: 2500,
          showConfirmButton: false,
        });
      }


      } catch (error) {
        console.error("Error add customer:", error);
      }finally {
        setIsSubmitting(false); // Re-enable the button
      }
  };

	return(
		<>
      <div class="modal fade" id="add-customer" tabindex="-1">
                <div class="modal-dialog modal-dialog-centered modal-xl">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title">Add Customer</h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                    <div className='add-customer-popup'>
              <form id='addcustomerform'>
                <input type="hidden" class="form-control" name="user_id" id="user_id" value={userId}  />
                <div class="row mb-3 padd-l-13px">
                  <div class="col-sm-3">
                  	<div className="input-group">
                       <div className="col-sm-12">
                    		<label for="inputEmail5" class="col-form-label">Business Name <span style={{color: 'red'}}> * </span> </label>
                    		<input type="text" class="form-control" name="companyName" id="companyName" value={formData.companyName} onChange={handleChange} />
                    	</div>
                    	<div className="col-sm-12">
                            {errors.companyName && <span className="text-danger">{errors.companyName}</span>}
                          </div>
                    </div>
                  </div>
                  <div class="col-sm-3">
                  	<div className="input-group">
                       <div className="col-sm-12">
                    		<label for="inputEmail5" class="col-form-label">Customer Name <span style={{color: 'red'}}> * </span></label>
                    		<input type="text" class="form-control" name="firstName" id="firstName" value={formData.firstName} onChange={handleChange} />
                    	</div>
                    	<div className="col-sm-12">
                            {errors.firstName && <span className="text-danger">{errors.firstName}</span>}
                          </div>
                    </div>
                  </div>                 

                  <div class="col-sm-3">
                  	<label for="inputEmail5" class="col-form-label">Email Address</label>
                    <input type="text" class="form-control" name="emailId" id="emailId" />
                  </div>
                  <div class="col-sm-3">
                  	<div className="input-group">
                       <div className="col-sm-12">
                    		<label for="inputEmail5" class="col-form-label">Phone <span style={{color: 'red'}}> * </span></label>
                    		<input type="number" class="form-control no-arrows" name="phone" id="phone" value={formData.phone} onChange={handleChange} style={{ appearance: 'none', MozAppearance: 'textfield' }} />
                    	</div>
                    	<div className="col-sm-12">
                            {errors.phone && <span className="text-danger">{errors.phone}</span>}
                          </div>

                    </div>
                  </div>

                </div>



                <div class="row mb-3 padd-l-13px">            

                  <div class="col-sm-3">
                  	<div className="input-group">
                       <div className="col-sm-12">
	                  	<label for="inputEmail5" class="col-form-label">Route <span style={{color: 'red'}}> * </span></label>
	                    <select name="route" class="form-select orgselect" value={formData.route} onChange={handleChange}>
	                      <option value="">Select</option>
	                      {businessRute.map((type) => (
	                          <option key={type.id} value={type.id}>
	                            {type.route_number}
	                          </option>
	                        ))} 
	                    </select>
	                   </div>
	                   <div className="col-sm-12">
                            {errors.route && <span className="text-danger">{errors.route}</span>}
                          </div>
	                   </div>
                  </div>


                    <div class="col-sm-3">
                      <div className="input-group">
                      <div className="col-sm-12">
                        <label for="inputEmail5" class="col-form-label">Type of Business <span style={{color: 'red'}}> * </span></label>

                        <select name="typeOfBusiness" class="form-select orgselect" onChange={handleChange}> 
                          <option value="">Select</option>
                          {businessType.map((type) => (
                            <option key={type.id} value={type.id}>
                              {type.type_business}
                            </option>
                          ))}
                        </select>
                        </div>
                        <div className="col-sm-12">
                            {errors.typeOfBusiness && <span className="text-danger">{errors.typeOfBusiness}</span>}
                          </div>
                      </div>
                    </div>

                     <div class="col-sm-3">
                     <div className="input-group">
                     <div className="col-sm-12">
                      <label for="inputEmail5" class="col-form-label">Business Category <span style={{color: 'red'}}> * </span></label>
                    <select name="businessCategory" class="form-select orgselect" onChange={handleChange}> 
                      <option value="">Select</option>
                       {businessCategory.map((type) => (
                          <option key={type.id} value={type.id}>
                            {type.category_name}
                          </option>
                      ))} 
                    </select>
                    </div>

                    <div className="col-sm-12">
                            {errors.businessCategory && <span className="text-danger">{errors.businessCategory}</span>}
                          </div>
                    </div>
                  </div>

                  <div class="col-sm-3">
                    <div className="input-group">
                       <div className="col-sm-12">
                      <label for="inputEmail5" class="col-form-label">Delivery Days <span style={{color: 'red'}}> * </span></label>
                      <select name="default_delivery" class="form-select orgselect" value={formData.default_delivery} onChange={handleChange}>
                        <option value="">Select</option>
                        <option value="1">3 Days Delivery</option>
              <option value="2">1 Days Delivery</option>
              <option value="3">7 Days Delivery</option>
                      </select>
                     </div>
                     <div className="col-sm-12">
                            {errors.default_delivery && <span className="text-danger">{errors.default_delivery}</span>}
                          </div>
                    </div>
                    </div>

                </div>



                <div class="row mb-3 padd-l-13px">
                  
                     <div class="col-sm-3">
                     	<div className="input-group">
                       		<div className="col-sm-12">
                    		<label for="inputEmail5" class="col-form-label">Address <span style={{color: 'red'}}> * </span></label>
                   			<input type="text" class="form-control" name="addressLine1" id="addressLine1" value={formData.addressLine1} onChange={handleChange} />
                   			</div>
                   			<div className="col-sm-12">
                            {errors.addressLine1 && <span className="text-danger">{errors.addressLine1}</span>}
                          </div>
                   		</div>


                   	 </div>
                     <div class="col-sm-3">
                    <label for="inputEmail5" class="col-form-label">Address 2 </label>
                    <input type="text" class="form-control" name="addressLine2" id="addressLine2" />
                    </div>

                    <div class="col-sm-3">
                    <div className="input-group">
                        <div className="col-sm-12">
                        <label for="inputEmail5" class="col-form-label">City <span style={{color: 'red'}}> * </span></label>
                        <input type="text" class="form-control" name="city" id="city" value={formData.city} onChange={handleChange} />
                      </div>
                      <div className="col-sm-12">
                            {errors.city && <span className="text-danger">{errors.city}</span>}
                          </div>
                    </div>
                    </div>

                    <div class="col-sm-3">
                    <div className="input-group">
                        <div className="col-sm-12">
                        <label for="inputEmail5" class="col-form-label">County <span style={{color: 'red'}}> * </span></label>
                        <input type="text" class="form-control" name="county" id="county" value={formData.county} onChange={handleChange}  />
                      </div>
                      <div className="col-sm-12">
                            {errors.county && <span className="text-danger">{errors.county}</span>}
                          </div>
                    </div>
                  </div>

                </div>


                <div class="row mb-3 padd-l-13px">
                  <div class="col-sm-3">
                  	<div className="input-group">
                       	<div className="col-sm-12">
                  			<label for="inputEmail5" class="col-form-label">Postal Code <span style={{color: 'red'}}> * </span></label>
                    		<input type="text" class="form-control" name="postalCode" id="postalCode"  value={formData.postalCode} onChange={handleChange}  />
                    	</div>
                    	<div className="col-sm-12">
                            {errors.postalCode && <span className="text-danger">{errors.postalCode}</span>}
                          </div>
                    </div>
                  </div>

                  <div class="col-sm-3">
                    <div className="input-group">
                       <div className="col-sm-12">
                            <label for="inputEmail5" class="col-form-label">Account Number (User Name)<span style={{color: 'red'}}> * </span></label>
                            <input type="text" class="form-control" name="accound_no" id="accound_no" value={formData.accound_no} onChange={handleChange} />
                        </div>
                        <div className="col-sm-12">
                            {errors.accound_no && <span className="text-danger">{errors.accound_no}</span>}
                          </div>
                    </div>
                  </div>

                  <div class="col-sm-3">
                    <label for="inputEmail5" class="col-form-label">VAT Number</label>
                    <input type="text" class="form-control" name="vatNo" id="vatNo" />
                  </div>

                </div>



                <div class="row mb-3 padd-l-13px">
                  <div class="col-sm-9">
                    <label for="inputEmail5" class="col-form-label">Product Price</label>

                    <div class="col-sm-10">
                      <div class="form-check">
                        <input class="form-check-input" type="radio" name="product_price" id="gridRadios1" value="0"  />
                        <label class="form-check-label" for="gridRadios1" > Price 1  </label>
                      </div>

                      <div class="form-check">
                        <input class="form-check-input" type="radio" name="product_price" id="gridRadios2" value="1" />
                        <label class="form-check-label" for="gridRadios2"> Price 2</label>
                      </div>

                      <div class="form-check disabled">
                        <input class="form-check-input" type="radio" name="product_price" id="gridRadios3" value="2" />
                        <label class="form-check-label" for="gridRadios3" >Price 3</label>
                      </div>

                      <div class="form-check disabled">
                        <input class="form-check-input" type="radio" name="product_price" id="gridRadios4" value="3" />
                        <label class="form-check-label" for="gridRadios4" >Price 4</label>
                      </div>

                      <div class="form-check disabled">
                        <input class="form-check-input" type="radio" name="product_price" id="gridRadios5" value="4" />
                        <label class="form-check-label" for="gridRadios5" >Price 5</label>
                      </div>

                      <div class="form-check disabled">
                        <input class="form-check-input" type="radio" name="product_price" id="gridRadios6" value="5" />
                        <label class="form-check-label" for="gridRadios6" >Price 6</label>
                      </div>


                    </div>
                  </div>
                </div>


                


                <div class="row mb-3 padd-l-13px">
                  <div class="col-md-3">
                    <div class="col-sm-10">
                      <div class="form-check">
                        <input class="form-check-input" type="radio" name="credit_facility" id="gridRadios7" value="1" checked={creditFacility === '1'} onChange={handleRadioChange} />
                        <label class="form-check-label" for="gridRadios7"> Credit Facility</label>
                      </div>

                      <div class="form-check">
                        <input class="form-check-input" type="radio" name="credit_facility" id="gridRadios8" value="0" checked={creditFacility === '0'} onChange={handleRadioChange} />
                        <label class="form-check-label" for="gridRadios8"> Credit Facility Cash</label>
                      </div>
                    </div>
                  </div>

                  {creditFacility === '1' && (
                  <div class="col-md-9">
                    <div class="data">
                      <div class="row">
                        <div class="col-6 col-lg-4 col-sm-4">
                          <div className='credit-box'>
                            <div class="form-check">
                              <label class="checkbox">
                               

                                <input
                                    type="checkbox"
                                    name="day_wise"
                                    checked={formData.day_wise === '1'} 
                                    onChange={handleCheckboxChange}
                                    value="1"
                                  />
                                <span class="default"></span>
                              </label>
                              <label class="form-check-label" for="gridCheck1"> Day Wise </label></div>

                            <div class="data1">
                            {formData.day_wise === '1' && (
                            <div class="row mb-3">
                              <label for="inputText" class="col-sm-4 col-form-label">Total Days</label>
                              <div class="col-sm-6">
                                <input type="text" class="form-control" name="total_day" />
                              </div>
                                </div>
                                )}
                            </div>
                            

                          </div>
                        </div>



                        <div class="col-6 col-lg-4 col-sm-4">
                        <div className='credit-box'>
                            <div class="form-check">
                              <label class="checkbox">
                                

                                <input
                                    type="checkbox"
                                    name="invoice_wise"
                                    checked={formData.invoice_wise === '1'} 
                                    onChange={handleCheckboxChange}
                                    value="1"
                                  />

                                <span class="default"></span>
                              </label>
                              <label class="form-check-label" for="gridCheck2"> Invoice Wise </label></div>

                            <div class="data1">
                            {formData.invoice_wise === '1' && (
                            <div class="row mb-3">
                              <label for="inputText" class="col-sm-4 col-form-label">Total Invoice</label>
                              <div class="col-sm-6">
                                <input type="text" class="form-control" name="total_invoice" />
                              </div>
                                </div>
                                )}
                            </div>

                          </div>
                        </div>


                        <div class="col-6 col-lg-4 col-sm-4">
                          <div className='credit-box'>
                            <div class="form-check">
                              <label class="checkbox">
                                

                                <input
                                    type="checkbox"
                                    name="amount_wise"
                                    checked={formData.amount_wise === '1'} 
                                    onChange={handleCheckboxChange}
                                    value="1"
                                  />
                                <span class="default"></span>
                              </label>
                              <label class="form-check-label" for="gridCheck3">  Amount Wise </label></div>

                              <div class="data1">
                              {formData.amount_wise === '1' && (
                            <div class="row mb-3">
                              <label for="inputText" class="col-sm-5 col-form-label">Total Amount</label>
                              <div class="col-sm-6">
                                <input type="text" class="form-control" name="total_amount" />
                              </div>
                                </div>
                                )}
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  )}

                </div>







                <div className='col-12 col-lg-12 col-sm-12'>
                  <hr />
                </div>

                <div className='row mb-3 padd-l-13px'>
                  <div className='col-12 col-lg-12 col-sm-12'>
                    <button type="button" onClick={submitAddCustomerForm} class="float-right-btn common-popup-btn"  disabled={isSubmitting}>Add customer</button>
                  </div>
                </div>



              </form>
            </div>
                    </div>
                   
                  </div>
                </div>
              </div>


		</>

	);
}
export default AddCustomersForm;