import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Header from '../Header';
import Footer from '../Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import config from "../common/Config";
import generateManifestListPDF from './ManifestPrintPDF';

const ManifestPrint = () => {

  const { manifestId } = useParams();
  const accessToken = localStorage.getItem('accessToken');
  const firstName = localStorage.getItem('first_name');
  const lastName = localStorage.getItem('last_name');
  const [showCustomer, setShowCustomer] = useState(false);
  const todayDate = new Date();

  function formatDate(date) {
    const day = String(date.getDate()).padStart(2, '0'); // Get day and pad with zero
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month (0-based) and pad
    const year = date.getFullYear(); // Get full year

    return `${day}-${month}-${year}`; // Return formatted date
  }

  function formatNewDate(dateString) {
    const date = new Date(dateString); // Parse the date string
    const day = String(date.getDate()).padStart(2, '0'); // Get day and pad with zero
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month (0-based) and pad
    const year = date.getFullYear(); // Get full year

    return `${day}-${month}-${year}`; // Return formatted date
  }

  const [headerData, setHeaderData] = useState({
    date: '',
    journeyNo: '',
    salesRep: '',
    driver: '',
    shop_name: '',
    address: '',
    phone: '',
    email: '',
    website: '',
  });
  const [manifestList, setmanifestList] = useState([]);

  const handleGenerateManifestListPDF = () => {
    generateManifestListPDF(manifestId);
  }

  const fetchViewManifestList = async () => {
    const formData = new FormData();
    formData.set('manifest_id', manifestId);

    const apiUrl = `${config.API_URL}/get_manifestPrint`;
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to fetch Picker List');
      }
      const data = await response.json();
      setHeaderData({
        date: data.data.date,
        journeyNo: data.data.route_id,
        salesRep: data.data.sale_rep,
        driver: data.data.driver_name,
        shop_name: data.data.shop_details.shop_name,
        address: data.data.shop_details.address,
        phone: data.data.shop_details.phone,
        email: data.data.shop_details.email,
        website: data.data.shop_details.website,
      });
      setmanifestList(data.data.inwards);

    } catch (error) {
      console.error('Error fetching Picker List data:', error);
    }



  };

  useEffect(() => {

    if (accessToken === null) {
      window.location.href = `${config.BASE_URL}/login`;
      return;
    }

    fetchViewManifestList();
  }, []);

  return (
    <>
      <Header />

      <div class="breadcrumbs">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-lg-12 col-sm-12"> <a href="#"><i class="fa fa-home"></i></a> &gt; <a href="dashboard.html">Dashboard</a> &gt; <Link to="/ManifestList">Dispatch & Deliveries</Link> &gt;
              <span class="last-crumb">Manifest List </span>
            </div>
          </div>
        </div>
      </div>

      <div className="main-container">
      <div className="container-fluid">
        
      <div className="row">
      <div className="col-12">
          <button className="acceptbtn" onClick={(e) => handleGenerateManifestListPDF()}>
                Print
              </button>
          </div>
        </div>

      <div className="row">
        <div className="col-12">
          <div className="show-order-area dashboard">

            <div className="sales-view-area">
              


              <header>
                <div class="row align-items-center gy-3">
                  <div class="col-sm-4 text-center text-sm-start"> <img src={`${config.BASE_URL}/assets/img/${accessToken}_logo.png`} width={accessToken === 'knm' ? '30%' : ''} alt="Logo" />
                  </div>
                  <div class="col-sm-8">

                    <table width="100%" border="0" cellspacing="0" cellpadding="0">
                      <tbody>
                        <tr>
                          <td><h4 class="text-7 mb-0">{headerData.shop_name}</h4></td>
                        </tr>
                        <tr>
                          <td>
                            <address>
                              {headerData.address}
                            </address>
                          </td>
                        </tr>
                        <tr>
                          <td><b>Phone No :</b> {headerData.phone} | <b>Email :</b> {headerData.email}</td>
                        </tr>
                        <tr>
                          <td><b>Website :</b> {headerData.website}</td>
                        </tr>
                      </tbody>
                    </table>

                  </div>
                </div>
                <hr />
              </header>

              <div style={{ marginBottom: '0px' }} >
                <div class="col-12 col-lg-12 col-sm-12">
                  <table width="100%" border="0" cellspacing="0" cellpadding="0" class="table table-bordered">
                    <tbody>
                      <tr>
                        <td style={{ paddingRight: '10px', width: '33%' }}>
                          <div class="col-12 col-lg-12 col-sm-12 order-sm-1" style={{ verticalAlign: 'top' }}>
                            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                              <tr>
                                <td style={{ verticalAlign: 'top', width: '22%' }}><strong>Manifest No :</strong> </td>
                                <td style={{ whiteSpace: 'normal' }}> {manifestId}</td>
                              </tr>
                              <tr>
                                <td style={{ verticalAlign: 'top', width: '22%' }}><strong>Date :</strong> </td>
                                <td style={{ whiteSpace: 'normal' }}> {headerData.date}</td>
                              </tr>
                              <tr>
                                <td style={{ verticalAlign: 'top', width: '22%' }}><strong>Journey No :</strong> </td>
                                <td style={{ whiteSpace: 'normal' }}> {headerData.journeyNo}</td>
                              </tr>
                              <tr>
                                <td style={{ verticalAlign: 'top', width: '22%' }}><strong>SalesRep Name :</strong> </td>
                                <td style={{ whiteSpace: 'normal' }}> {headerData.salesRep}</td>
                              </tr>
                              <tr>
                                <td style={{ verticalAlign: 'top', width: '22%' }}><strong>Driver Name :</strong> </td>
                                <td style={{ whiteSpace: 'normal' }}> {headerData.driver}</td>
                              </tr>
                            </table>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="verical-scrl-wrapper menifest-area-invoice-tbl" id="style-3">
                
                <table className="table table-bordered resizable dataTable" id="purchaseProforma">
                  <thead>
                    <tr>
                      <th>Customer name (A/C No)</th>
                      <th>Inv No</th>
                      <th>Inv Amt</th>
                      <th>Total Dues</th>
                      <th>Rep Collect</th>
                      <th>Driver to Collect</th>
                      <th>Remark</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* Cash Section */}
                    {manifestList.some(order => {
                      // Check if the order has no credit_type or if all credit_type names are null or empty
                      return !order.credit_type ||
                        order.credit_type.every(type => !type.name); // Checks if all names are null or empty
                    }) && (
                        <>
                          <tr>
                            <td colSpan="7" style={{ textAlign: 'center' }}><b>Cash</b></td>
                          </tr>
                          {manifestList.map((order, index) => {
                            // Show orders where credit_type is empty or contains only null/empty names
                            if (!order.credit_type || order.credit_type.every(type => !type.name)) {
                              return (
                                <tr key={`cash-row-${index}`}>
                                  <td>{order.customer || 'N/A'} ({order.accound_no || 'N/A'})</td>
                                  <td>{order.reference_no || 'N/A'}</td>
                                  <td style={{ textAlign: 'right' }}>£ {order.grand_total}</td>
                                  <td style={{ textAlign: 'right' }}>£ {order.get_total}</td>
                                  <td style={{ textAlign: 'right' }}>£ {order.sale_person_collected}</td>
                                  <td className="text-right">{order.driver_to_collect || ''}</td>
                                  <td>{order.remark || ''}</td>
                                </tr>
                              );
                            }
                            return null; // Skip orders with valid credit_type for Cash section
                          })}
                        </>
                      )}


                    {/* Credit Section */}
                    {manifestList.some(order => order.credit_type && order.credit_type.some(type => type.name === 'amount_wise' || type.name === 'day_wise')) && (
                      <>
                        <tr>
                          <td colSpan="7" style={{ textAlign: 'center' }}><b>Credit</b></td>
                        </tr>
                        {manifestList.map((order, index) => {
                          // Only show orders with 'amount_wise' or 'day_wise' credit types
                          const isCredit = order.credit_type && order.credit_type.some(type => type.name === 'amount_wise' || type.name === 'day_wise');
                          if (isCredit) {
                            return (
                              <tr key={`credit-row-${index}`}>
                                <td>{order.customer || 'N/A'} ({order.accound_no || 'N/A'})</td>
                                <td>{order.reference_no || 'N/A'}</td>
                                <td style={{ textAlign: 'right' }}>£ {order.grand_total}</td>
                                <td style={{ textAlign: 'right' }}>£ {order.get_total}</td>
                                <td style={{ textAlign: 'right' }}>£ {order.sale_person_collected}</td>
                                <td>{order.driver_to_collect || ''}</td>
                                <td>{order.remark || ''}</td>
                              </tr>
                            );
                          }
                          return null; // Skip orders without 'amount_wise' or 'day_wise' credit types
                        })}
                      </>
                    )}

                    {/* TC Section */}
                    {manifestList.some(order => order.credit_type && order.credit_type.some(type => type.name === 'invoice_wise')) && (
                      <>
                        <tr>
                          <td colSpan="7" style={{ textAlign: 'center' }}><b>TC</b></td>
                        </tr>
                        {manifestList.map((order, index) => {
                          // Only show orders with 'invoice_wise' credit type
                          const isTC = order.credit_type && order.credit_type.some(type => type.name === 'invoice_wise');
                          if (isTC) {
                            return (
                              <tr key={`tc-row-${index}`}>
                                <td>{order.customer || 'N/A'} ({order.accound_no || 'N/A'})</td>
                                <td>{order.reference_no || 'N/A'}</td>
                                <td style={{ textAlign: 'right' }}>£ {order.grand_total}</td>
                                <td style={{ textAlign: 'right' }}>£ {order.get_total}</td>
                                <td style={{ textAlign: 'right' }}>£ {order.sale_person_collected}</td>
                                <td style={{ textAlign: 'right' }}>{order.driver_to_collect || ''}</td>
                                <td>{order.remark || ''}</td>
                              </tr>
                            );
                          }
                          return null; // Skip orders without 'invoice_wise' credit types
                        })}
                      </>
                    )}
                  </tbody>

                </table>

              </div>
            </div>
          </div>
        </div>
      </div>

</div>
</div>

      <Footer />

    </>

  );
}
export default ManifestPrint;