import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import { API_URL, BASE_URL } from './Config';
import config from "./common/Config";
import { Dropdown } from "rsuite";
import "rsuite/Dropdown/styles/index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { Helmet } from "react-helmet";
const Header = () => {
  const [activeLink, setActiveLink] = useState("Dashboard");
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [SiteName, setSiteName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [showNotifications, setShowNotifications] = useState(false); // Toggle notification dropdown
  const [showProfileDropdown, setShowProfileDropdown] = useState(false); // Toggle profile dropdown
  const [notificationCount, setNotificationCount] = useState(4); // Placeholder for notification count
  const [notificationData, setNotificationData] = useState([]);
  const accessToken = localStorage.getItem("accessToken");
  const isSinglePicking = localStorage.getItem("isSinglePicking");
  const userId = localStorage.getItem("userId");
  const user_role = localStorage.getItem("user_role");
  const navigate = useNavigate();
  const location = useLocation();

  if (accessToken == "ayt") 
  {
    const pageTitle = "Southall";
  }
  if (accessToken == "dds") 
  {
    const pageTitle = "DDsnack";
  } else 
  {
    const pageTitle = "Ebusso";
  }

  useEffect(() => {
    // Retrieve the logo URL, first name, and last name from localStorage
    const accessToken = localStorage.getItem("accessToken");

    if (!accessToken) {
      window.location.href = "/react/login";
      return;
    }

    const userId = localStorage.getItem("userId");
    const site_name = localStorage.getItem("site_name");
    const storedFirstName = localStorage.getItem("first_name");
    const storedLastName = localStorage.getItem("last_name");

    if (site_name) {
      setSiteName(site_name);
    }
    if (storedFirstName) {
      setFirstName(storedFirstName);
    }
    if (storedLastName) {
      setLastName(storedLastName);
    }
  }, []);

  const handleSetActiveLink = (link) => {
    setActiveLink(link);
  };

  const handleMobileNavToggle = () => {
    setMobileNavOpen(!mobileNavOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem("site_name");
    localStorage.removeItem("first_name");
    localStorage.removeItem("last_name");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userId");
    localStorage.removeItem("user_role");
    localStorage.removeItem("isSinglePicking");
    
    window.location.href = `${config.LOG_OUT}`;
  };

  // Toggle the notifications dropdown
  const toggleNotifications = (e) => {
    e.stopPropagation(); // Prevent event bubbling
    setShowNotifications(!showNotifications);
    setShowProfileDropdown(false);
    getNotificationData();
  };

  const getNotificationData = async () => {
    const apiBCUrl = `${config.API_URL}/ws-get-notification-data`;
    try {
      const response = await fetch(apiBCUrl, {
        method: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch notification list");
      }

      const data = await response.json();
      // console.log(data);
      setNotificationData(data.data);
    } catch (error) {
      console.log("Error fetch notification data", error);
    }
  };

  const toggleProfileDropdown = (e) => {
    e.stopPropagation();
    setShowProfileDropdown(!showProfileDropdown);
    setShowNotifications(false);
  };

  const handleClickOutside = (e) => {
    if (showNotifications && !e.target.closest(".notification-drop")) {
      setShowNotifications(false);
    }
    if (showProfileDropdown && !e.target.closest(".nav-profile")) {
      setShowProfileDropdown(false);
    }
  };

  useEffect(() => {
    getNotificationData();
    // Close notification dropdown and profile dropdown when clicked outside
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showNotifications, showProfileDropdown]);

  return (
    <header id="header" className="header fixed-top" data-scrollto-offset="0">
      <div className="container-fluid d-flex align-items-center justify-content-between">
        <Link
          to={`/dashboard/${accessToken}/${userId}`}
          className="logo-area align-items-center scrollto me-auto me-lg-0"
        >
          {SiteName}
        </Link>
        {user_role == 8 && (
          <nav
            id="navbar"
            className={`navbar ${mobileNavOpen ? "mobile-nav-active" : ""}`}
          >
            <ul
              className={`nav-menu ${mobileNavOpen ? "mobile-nav-show" : ""}`}
            >
              <li>
                <Link
                  to={`/dashboard/${accessToken}/${userId}`}
                  onClick={() => handleSetActiveLink("Dashboard")}
                  className={
                    location.pathname ===
                      `/dashboard/${accessToken}/${userId}` && "active"
                  }
                >
                  <span>Dashboard</span>
                </Link>
              </li>
              <li className="dropdown">
                <a
                  href="#"
                  className={
                    (location.pathname === "/sales/newsales" ||
                      location.pathname === "/sales/shistory" ||
                      location.pathname === "/sales/reject_sales" ||
                      location.pathname === "/nsales/EditSalesInvoice" ||
                      location.pathname === "/sales/Epos" ||
                      location.pathname === "/sales/epos_sales" ||
                      location.pathname === "/sales/holdList" ||
                      location.pathname === "/sales/Quick_Order") &&
                    "active"
                  }
                >
                  <span>Sales</span>{" "}
                  <i className="bi bi-chevron-down dropdown-indicator"></i>
                </a>
                <ul>
                  <li>
                    <Link
                      to="/sales/newsales"
                      className={
                        location.pathname === "/sales/newsales" && "active"
                      }
                    >
                      <span>New Sales</span>
                    </Link>
                  </li>
                  {/* <li><Link to="/sales/holdList" className={location.pathname === '/sales/holdList' && 'active'}><span>Hold List</span></Link></li> */}
                  <li>
                    <Link
                      to="/sales/shistory"
                      className={
                        location.pathname === "/sales/shistory" && "active"
                      }
                    >
                      <span>Order History</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/sales/reject_sales"
                      className={
                        location.pathname === "/sales/reject_sales" && "active"
                      }
                    >
                      <span>Reject Sales History</span>
                    </Link>
                  </li>
                  {/*<li><Link to="/sales/Common_Invoice" className={location.pathname === '/sales/Common_Invoice' && 'active'}><span>Common Invoice</span></Link></li>*/}
                {/*    <li>
                    <Link
                      to="/sales/Epos"
                      className={
                        location.pathname === "/sales/Epos" && "active"
                      }
                    >
                      <span>Epos</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/sales/epos_sales"
                      className={
                        location.pathname === "/sales/epos_sales" && "active"
                      }
                    >
                      <span>Sales Report(Epos)</span>
                    </Link>
                  </li>*/ }
                  <li>
                    <Link
                      to="/sales/Quick_Order"
                      className={
                        location.pathname === "/sales/Quick_Order" && "active"
                      }
                    >
                      <span>Quick Order</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="dropdown">
                <a
                  href="#"
                  className={
                    (location.pathname === "/picking/picking_create" ||
                      location.pathname === "/picking/picking_list" ||
                      location.pathname === "/picking/picking_history" ||
                      location.pathname === "/picking/short_qty" ||
                      location.pathname === "/picking/proforma") &&
                    "active"
                  }
                >
                  <span>Picking</span>{" "}
                  <i className="bi bi-chevron-down dropdown-indicator"></i>
                </a>
                <ul>
                  <li>
                    <Link
                      to="/picking/picking_create"
                      className={
                        location.pathname === "/picking/picking_create" &&
                        "active"
                      }
                    >
                      <span>Create Picking</span>
                    </Link>
                  </li>
                <li>
                    {isSinglePicking === 'Y'  ? (
                      <Link
                        to="/picking/single_picking_list"
                        className={location.pathname === "/picking/single_picking_list" ? "active" : ""}
                      >
                        <span>Picking List</span>
                      </Link>
                    ) : (
                      <Link
                        to="/picking/picking_list"
                        className={location.pathname === "/picking/picking_list" ? "active" : ""}
                      >
                        <span>Picking List</span>
                      </Link>
                    )}
                  </li>

                  <li>
                    <Link
                      to="/picking/picking_history"
                      className={
                        location.pathname === "/picking/picking_history" &&
                        "active"
                      }
                    >
                      <span>Picking History</span>
                    </Link>
                  </li>
                 {isSinglePicking === 'N' && (
                  <li>
                    <Link
                      to="/picking/short_qty"
                      className={location.pathname === "/picking/short_qty" ? "active" : ""}
                    >
                      <span>Short QTY</span>
                    </Link>
                  </li>
                )}
                  <li>
                    <Link
                      to="/picking/proforma"
                      className={
                        location.pathname === "/picking/proforma" && "active"
                      }
                    >
                      <span>Proforma Invoice</span>
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="dropdown">
                <a
                  href="#"
                  className={
                    (location.pathname === "/delivery/route_planing" ||
                      location.pathname === "/manifest_list" ||
                      location.pathname === "/open_trips" ||
                      location.pathname === "/complete_trips") &&
                    "active"
                  }
                >
                  <span>Dispatch & Deliveries</span>{" "}
                  <i className="bi bi-chevron-down dropdown-indicator"></i>
                </a>
                <ul>
                  <li>
                    <Link
                      to="/delivery/route_planing"
                      className={
                        location.pathname === "/delivery/route_planing" &&
                        "active"
                      }
                    >
                      <span>Create Manifest</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/manifest_list"
                      className={
                        location.pathname === "/manifest_list" && "active"
                      }
                    >
                      <span>Manifest List</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/open_trips"
                      className={
                        location.pathname === "/open_trips" && "active"
                      }
                    >
                      <span>Open Trip</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/complete_trips"
                      className={
                        location.pathname === "/complete_trips" && "active"
                      }
                    >
                      <span>Complete Trip</span>
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="dropdown">
                <a
                  href="#"
                  className={
                    (location.pathname === "/products" ||
                      location.pathname === "/products_price_variation" ||
                      location.pathname === "/customers" ||
                      location.pathname === "/supplier" ||
                      location.pathname === "/users" ||
                      location.pathname === "/product_category" ||
                      location.pathname === "/Brands" ||
                      location.pathname === "/Units" ||
                      location.pathname === "/ContraList" ||
                      location.pathname === "/ContraList/Contra_Price_Edit" ||
                      location.pathname === "/products/add_product") &&
                    "active"
                  }
                >
                  <span>Master</span>{" "}
                  <i className="bi bi-chevron-down dropdown-indicator"></i>
                </a>
                <ul>
                  <li>
                    <Link
                      to="/products"
                      className={location.pathname === "/products" && "active"}
                    >
                      <span>Products</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/products_price_variation"
                      className={
                        location.pathname === "/products_price_variation" &&
                        "active"
                      }
                    >
                      <span>Product Price Variation</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/supplier"
                      className={location.pathname === "/supplier" && "active"}
                    >
                      <span>Suppliers</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/users"
                      className={location.pathname === "/users" && "active"}
                    >
                      <span>Users</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/product_category"
                      className={
                        location.pathname === "/product_category" && "active"
                      }
                    >
                      <span>Product Categories</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/Brands"
                      className={location.pathname === "/Brands" && "active"}
                    >
                      <span>Brands</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/Units"
                      className={location.pathname === "/Units" && "active"}
                    >
                      <span>Units</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/customers"
                      className={location.pathname === "/customers" && "active"}
                    >
                      <span>Customers</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/ContraList"
                      className={
                        location.pathname === "/ContraList" && "active"
                      }
                    >
                      <span>Contra Price Customers</span>
                    </Link>
                  </li>
                  {/*<li><Link to="/Customer_Credit_Facility"><span>Customers Credit Facility</span></Link></li>*/}
                </ul>
              </li>

              <li className="dropdown">
                <a
                  href="#"
                  className={
                    (location.pathname ===
                      "/reports/customer_not_order_report" ||
                      location.pathname === "/reports/inventory_sales_report" ||
                      location.pathname === "/reports/mis_report" ||
                      location.pathname === "/reports/missing_order" ||
                      location.pathname === "/reports/sales_report" ||
                      location.pathname === "/reports/profit_loss_report" ||
                      location.pathname === "/reports/lat_long_report" ||
                      location.pathname === "/reports/sales_product_report" ||
                      location.pathname === "/reports/Cashier_report") &&
                    "active"
                  }
                >
                  <span>Report</span>{" "}
                  <i className="bi bi-chevron-down dropdown-indicator"></i>
                </a>
                <ul>
                  <li>
                    <Link
                      to="/reports/customer_not_order_report"
                      className={
                        location.pathname ===
                          "/reports/customer_not_order_report" && "active"
                      }
                    >
                      <span>Customer Not Order</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/reports/inventory_sales_report"
                      className={
                        location.pathname ===
                          "/reports/inventory_sales_report" && "active"
                      }
                    >
                      <span>Inventory Sales Report</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/reports/mis_report"
                      className={
                        location.pathname === "/reports/mis_report" && "active"
                      }
                    >
                      <span>MIS Report</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/reports/missing_order"
                      className={
                        location.pathname === "/reports/missing_order" &&
                        "active"
                      }
                    >
                      <span>Cross Off Report</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/reports/sales_report"
                      className={
                        location.pathname === "/reports/sales_report" &&
                        "active"
                      }
                    >
                      <span>Sales Rep Report</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/reports/profit_loss_report"
                      className={
                        location.pathname === "/reports/profit_loss_report" &&
                        "active"
                      }
                    >
                      <span>Profit and Loss</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/reports/lat_long_report"
                      className={
                        location.pathname === "/reports/lat_long_report" &&
                        "active"
                      }
                    >
                      <span>Order Location Report</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/reports/sales_product_report"
                      className={
                        location.pathname === "/reports/lat_long_report" &&
                        "active"
                      }
                    >
                      <span>Sales Products Report</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/reports/Cashier_report"
                      className={
                        location.pathname === "/reports/Cashier_report" &&
                        "active"
                      }
                    >
                      <span>Cashier Report</span>
                    </Link>
                  </li>
                    <li>
                    <Link
                      to="/reports/Expiry_report"
                      className={
                        location.pathname === "/reports/Expiry_report" &&
                        "active"
                      }
                    >
                      <span>Expiry Report</span>
                    </Link>
                  </li> 
                </ul>
              </li>

              <li className="dropdown">
                <a
                  href="#"
                  className={
                    (location.pathname === "/purchases/purchases_order" ||
                      location.pathname === "/purchases/add" ||
                      location.pathname === "/purchases/purchases_grn" ||
                      location.pathname === "/purchases/AddPurchasesGRN") &&
                    "active"
                  }
                >
                  <span>Purchases</span>{" "}
                  <i className="bi bi-chevron-down dropdown-indicator"></i>
                </a>
                <ul>
                  <li>
                    <Link
                      to="/purchases/purchases_order"
                      className={
                        location.pathname === "/purchases/purchases_order" &&
                        "active"
                      }
                    >
                      <span>Purchases Order</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/purchases/purchases_grn"
                      className={
                        location.pathname === "/purchases/purchases_grn" &&
                        "active"
                      }
                    >
                      <span>Purchases History</span>
                    </Link>
                  </li>
                  <li>
                    {/*   <Link
                      to="/purchases/suggested"
                      className={
                        location.pathname === "/purchases/suggested" &&
                        "active"
                      }>
                      <span>Suggested PO</span>
                    </Link> */}
                  </li>
                </ul>
              </li>
              <li className="dropdown">
                <a
                  href="#"
                  className={
                    (location.pathname === "/account/push_sage" ||
                      location.pathname === "/account/push_invoice" ||
                      location.pathname === "/account/payments" ||
                      location.pathname === "/account/customer_accounts" ||
                      location.pathname === "/account/supplier_ledger" ||
                      location.pathname === "/account/payment_ladger" ||
                      location.pathname === "/account/my_ledger" ||
                      location.pathname === "/account/product_ledger" ||
                      location.pathname === "/account/bank_ledger") &&
                    "active"
                  }
                >
                  <span>Account</span>{" "}
                  <i className="bi bi-chevron-down dropdown-indicator"></i>
                </a>
                <ul>
                  <li>
                    <Link
                      to="/account/push_sage"
                      className={
                        location.pathname === "/account/push_sage" && "active"
                      }
                    >
                      <span>Push to Sage</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/account/push_invoice"
                      className={
                        location.pathname === "/account/push_invoice" &&
                        "active"
                      }
                    >
                      <span>All Push Invoice</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/account/payments"
                      className={
                        location.pathname === "/account/payments" && "active"
                      }
                    >
                      <span>Received Payments</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/account/customer_accounts"
                      className={
                        location.pathname === "/account/customer_accounts" &&
                        "active"
                      }
                    >
                      <span>Customer Accounts</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/account/supplier_ledger"
                      className={
                        location.pathname === "/account/supplier_ledger" &&
                        "active"
                      }
                    >
                      <span>Supplier Ledger</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/account/payment_ladger"
                      className={
                        location.pathname === "/account/payment_ladger" &&
                        "active"
                      }
                    >
                      <span>Payment Ledger</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/account/my_ledger"
                      className={
                        location.pathname === "/account/my_ledger" && "active"
                      }
                    >
                      <span>My Ledger</span>
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/account/product_ledger"
                      className={
                        location.pathname === "/account/product_ledger" &&
                        "active"
                      }
                    >
                      <span>Product Ledger</span>
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/stock_take/history"
                      className={
                        location.pathname === "/stock_take/history" && "active"
                      }
                    >
                      <span>Stock Take</span>
                    </Link>
                  </li>

                  {/* <li>
        <Link to="/account/bank_ledger" className={location.pathname === '/account/bank_ledger' && 'active'}>
        <span>Bank Ledger</span>
        </Link>
                                </li>*/}
                </ul>
              </li>
              <li className="dropdown">
                <a
                  href="#"
                  className={
                    (location.pathname === "/return_list" ||
                      location.pathname === "/return/add_return") &&
                    "active"
                  }
                >
                  <span>Return</span>{" "}
                  <i className="bi bi-chevron-down dropdown-indicator"></i>
                </a>
                <ul>
                  <li>
                    <Link
                      to="/return_list"
                      className={
                        location.pathname === "/return_list" && "active"
                      }
                    >
                      <span>Return List</span>
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="dropdown">
                <a
                  href="#"
                  className={
                    (location.pathname === "/Catalogue" ||
                      location.pathname === "/slider_settings" ||
                      location.pathname === "/stock_take/history" ||
                      location.pathname === "/stock_take/start_stock_take" ||
                      location.pathname === "/stock_take/stock_take_list" ||
                      location.pathname ===
                        "/stock_take/show_history_of_stock_take_list" ||
                      location.pathname === "/Promotions/Promos" ||
                      location.pathname === "/Promotions/Add_Promos" ||
                      location.pathname === "/Promotions/Edit_Promos" ||
                      location.pathname === "/Settings/Group_Permissions" ||
                      location.pathname === "/Settings/Change_Permissions" ||
                      location.pathname === "/settings/Application_Setting" ||
                      location.pathname === "/settings/Registration") &&
                    "active"
                  }
                >
                  <span>Promotion</span>{" "}
                  <i className="bi bi-chevron-down dropdown-indicator"></i>
                </a>
                <ul>
                  <li>
                    <Link
                      to="/Catalogue"
                      className={location.pathname === "/Catalogue" && "active"}
                    >
                      <span>Catalogue</span>
                    </Link>
                  </li>
                 <li>
                    <Link
                      to="/slider_settings"
                      className={
                        location.pathname === "/slider_settings" && "active"
                      }
                    >
                      <span>Slider Settings</span>
                    </Link>
                  </li> 
                  <li>
                    <Link
                      to="/Promotions/Promos"
                      className={
                        location.pathname === "/Promotions/Promos" && "active"
                      }
                    >
                      <span>Promotions</span>
                    </Link>
                  </li>

                  {/*  <li><Link to="/Settings/Group_Permissions" className={location.pathname === '/Settings/Group_Permissions' && 'active'}><span> Group Permissions</span></Link></li>
        <li><Link to="/settings/Application_Setting" className={location.pathname === '/settings/Application_Setting' && 'active'}><span> Application Setting</span></Link></li>
        <li><Link to="/settings/Registration" className={location.pathname === '/settings/Registration' && 'active'}><span> Registration</span></Link></li>*/}
                </ul>
              </li>
            </ul>
            <i
              className="mobile-nav-toggle bi bi-list"
              onClick={handleMobileNavToggle}
            ></i>
          </nav>
        )}

        {user_role == 19 && (
          <nav
            id="navbar"
            className={`navbar ${mobileNavOpen ? "mobile-nav-active" : ""}`}
          >
            <ul
              className={`nav-menu ${mobileNavOpen ? "mobile-nav-show" : ""}`}
            >
              <li>
                <Link
                  to="/sales/IncompleteOrderList"
                  className={
                    location.pathname ===
                      `sales/IncompleteOrderList/${accessToken}/${userId}` &&
                    "active"
                  }
                >
                  <span>Incomplete Order List</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/sales/InventoryReport"
                  className={
                    location.pathname ===
                      `sales/InventoryReport/${accessToken}/${userId}` &&
                    "active"
                  }
                >
                  <span>Inventory Report</span>
                </Link>
              </li>

              <li>
                <Link
                  to="/sales/epos_sales"
                  className={
                    location.pathname ===
                      `sales/epos_sales/${accessToken}/${userId}` && "active"
                  }
                >
                  <span>Sales Report</span>
                </Link>
              </li>
            </ul>
          </nav>
        )}

        {user_role == 18 && (
          <nav
            id="navbar"
            className={`navbar ${mobileNavOpen ? "mobile-nav-active" : ""}`}
          >
            <ul
              className={`nav-menu ${mobileNavOpen ? "mobile-nav-show" : ""}`}
            >
              <li>
                <Link
                  to="/sales/Epos"
                  className={
                    location.pathname ===
                      `sales/Epos/${accessToken}/${userId}` && "active"
                  }
                >
                  <span>Epos</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/sales/InventoryReport"
                  className={
                    location.pathname ===
                      `sales/InventoryReport/${accessToken}/${userId}` &&
                    "active"
                  }
                >
                  <span>Inventory Report</span>
                </Link>
              </li>

              <li>
                <Link
                  to="/sales/epos_sales"
                  className={
                    location.pathname ===
                      `sales/epos_sales/${accessToken}/${userId}` && "active"
                  }
                >
                  <span>Sales Report</span>
                </Link>
              </li>
            </ul>
          </nav>
        )}

        <div className="header-nav ms-auto">
          <ul className="d-flex align-items-center">
            <li className="nav-item dropdown">
              <a
                className={`nav-link nav-icon ${
                  showNotifications ? "active" : ""
                }`}
                onClick={toggleNotifications}
              >
                <i class="bi bi-bell"></i>
                <span class="badge bg-primary badge-number">
                  {notificationData?.length}
                </span>
              </a>
              <ul class="notification-drop notie">
                <li>
                  {/* Notification Dropdown */}
                  {showNotifications && (
                    <div
                      style={{ height: "300px", overflowY: "scroll" }}
                      id="style-3"
                    >
                      <ul className="dropdown notification-list">
                        {notificationData.map((item, index) => {
                          if (item.status === "expirePromo") {
                            const formattedDate = new Date(
                              item.expDate
                            ).toLocaleDateString("en-GB");
                            return (
                              <li key={index}>
                                <div className="notidetail">
                                  <div>
                                    <i className="bi bi-exclamation-circle text-warning"></i>
                                  </div>
                                  <div className="notdiscription">
                                    <p>Promotion expired</p>
                                    <h4>{item.productName}</h4>
                                    <p>{formattedDate}</p>
                                  </div>
                                </div>
                              </li>
                            );
                          } else {
                            return (
                              <li key={index}>
                                <div className="notidetail">
                                  <div>
                                    <i className="bi bi-exclamation-circle text-warning"></i>
                                  </div>
                                  <div className="notdiscription">
                                    <p>Product is below minimum quantity</p>
                                    <h4>{item.productName}</h4>
                                    <p className="currectstk">
                                      Current Stock : {parseInt(item.qty)}
                                    </p>
                                  </div>
                                </div>
                              </li>
                            );
                          }
                        })}
                      </ul>
                    </div>
                  )}
                </li>
              </ul>
            </li>

            {/* User Profile Dropdown */}
            <li className="nav-item dropdown pe-3">
              <a
                className="nav-link nav-profile d-flex align-items-center pe-0"
                href="#"
                onClick={toggleProfileDropdown}
              >
                <span className="d-none d-md-block dropdown-toggle ps-2">
                  {firstName} {lastName ? lastName : ""}
                </span>
              </a>
              <ul
                className={`dropdown-menu dropdown-menu-end dropdown-menu-arrow profile ${
                  showProfileDropdown ? "active" : ""
                }`}
              >
                {/* <li className="dropdown-header">
                  <h6>
                    {firstName} {lastName ? lastName : ""}
                  </h6>
                </li> */}

                <li>
                  <Link className="dropdown-header" onClick={handleLogout}>
                    <i className="bi bi-box-arrow-right"></i>
                    <span>Sign Out</span>
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
