import jsPDF from 'jspdf';
import 'jspdf-autotable';
import config from "../common/Config";
import axios from 'axios';

const generateUniqueFilename = (baseName, extension) => {
  const timestamp = new Date().toISOString().replace(/[-:.]/g, "");
  return `${baseName}_${timestamp}.${extension}`;
};

const generateSummaryPDF = async (orderId) => {
  const accessToken = localStorage.getItem('accessToken');
  const formData = new FormData();
  formData.set('id', orderId);
  const apiUrl = `${config.API_URL}/purchases/getpurches_items`;

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);  // Convert timestamp string to Date object
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;  // Return formatted date as "YYYY-MM-DD"
  };

  try {
    const response = await axios.post(apiUrl, formData, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    });

    const data = response.data;
    console.log(data);

    if (!data.purchase || data.purchase.length === 0) {
      console.error("No order details found in the response.");
      return;  // Return early if order details are not available
    }

    const orderDetails =data.commonData[0];  
    const shopDetails = data.shop_details;
    console.log(orderDetails);

  

    const box_qty = data.purchase
    .filter(item => item.quantity != '0.00' && item.quantity != null && item.type === '1')
    .reduce((sum, item) => sum + (parseFloat(item.quantity)), 0);

    const piece_qty =data.purchase
    .filter(item => item.quantity != '0.00' && item.quantity != null && item.type === '2')
    .reduce((sum, item) => sum + (parseFloat(item.quantity)), 0);

    

    const doc = new jsPDF('portrait'); // Set to portrait orientation
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const margin = 10;  // 5px padding
    let y = margin;
    const firstColumnWidth = pageWidth * 0.25;  // 30% of the page width
    const secondColumnWidth = pageWidth * 0.75; 
    //const H2firstColumnWidth = pageWidth * 0.45; 

    const H2firstColumnWidth = pageWidth * 0.40;  // 45% of the page width
    const H2secondColumnWidth = pageWidth * 0.25; // 27.5% of the page width
    const H2thirdColumnWidth = pageWidth * 0.25;  // 27.5% of the page width


    // Add the logo image to the header (make sure the image path is correct)
 

   
    const headerSections2 = [
    
      [
      { text: `${shopDetails.shop_name}`, size: 13, bold: true },
      { text: `${shopDetails.address.split(',')[0]}`, size: 8, bold: false }, // First line of address
    { text: `${shopDetails.address.split(',')[1]}`, size: 8, bold: false }, // Second line of address
    { text: `Company No : ${shopDetails.company_reg_no} | VAT No : ${shopDetails.vat_no}`, size: 8, bold: false },
    { text: `Phone No : ${shopDetails.phone}`, size: 8, bold: false },
 

    ],
    [
    { text: `PLEASE PHONE THE OFFICE TO `, size: 8, bold: false,align: 'right'},
    { text: `PAY BY CREDIT/DEBIT CARD `, size: 8, bold: false ,align: 'right' },
    { text: `Bank A/C : ${shopDetails.bank_ac} `, size: 8, bold: false ,align: 'right' },
    { text: `Sort Code : ${shopDetails.sort_code}`, size: 8, bold: false ,align: 'right' },
    
    ]

      ];





    const headerSections3 = [
      [
        { text: `Supplier Details`, size: 8, bold: true },
        { text: `${orderDetails.name}`, size: 8, bold: false },
        { text: `${orderDetails.address}`, size: 8, bold: false },
        { text: `Phone No : ${orderDetails.phone}`, size: 8, bold: false },

        
        ],
      [

        { text: `Po No : ${orderDetails.reference_no}`, size: 8, bold: false },
        { text: `PO Date: ${orderDetails.date}`, size: 8, bold: false },
        
       

        ],
      


      ];




    const sectionWidth = (pageWidth - margin * 2) / 2; // Adjust for margins

    // Render header sections
    y = 7;
  const renderSections = (sections, marginTop = 7) => {
  let yPosition = y + marginTop; // Start Y position with marginTop
  const columnsPerRow = 2; // We want 2 columns per row (section per row)
  const colWidth = (doc.internal.pageSize.width - 2 * margin) / columnsPerRow; // Adjust the width based on 2 columns

  sections.forEach((section, index) => {
    // Calculate starting X position for the columns
    const colStartX = margin + (index % columnsPerRow) * colWidth; // Modulo for alternating between 2 columns

    section.forEach((item, i) => {
      doc.setFontSize(item.size);
      doc.setFont(item.bold ? 'helvetica' : 'helvetica', item.bold ? 'bold' : 'normal');
      doc.text(item.text, colStartX, yPosition + i * 4); // Adjust Y based on current position
    });

    // Increase the Y position after rendering each section
    if ((index + 1) % columnsPerRow === 0) {
      yPosition += 12; // Adjust space between rows if you render 2 sections per row
    }

    // After rendering two sections, move to the next line
    if ((index + 1) % columnsPerRow === 0) {
      yPosition += 10; // Space after each row of two columns
    }
  });

  // Add a little space after the last section
  yPosition += 0;

  // Update the global Y value if you need to keep track of the Y position globally
  y = yPosition;
};

renderSections(headerSections2);




// Call the function to render sections with equal height borders
renderSections(headerSections3);






    // Render table columns with customized widths
const tableColumns = [
  { title: 'Product', dataKey: 'product', size: 8, bold: true, halign: 'center' },
  { title: 'Type', dataKey: 'order_type', size: 8, bold: true, halign: 'center' },
  { title: 'Qty', dataKey: 'qty', size: 8, bold: true, halign: 'center' },

  ];
    // Calculate the table widths
const totalWidth = pageWidth - 2 * margin;
    const productWidth = totalWidth * 0.75;  // 45% of the page width for product name/code
    const orderTypeWidth = totalWidth * 0.06;  // 5% for Order Type
    const qtyWidth = totalWidth * 0.06;  // 5% for Qty
  

    let qty = 0;

const tableBody = data.purchase.map((item) => {
  // Update qty based on item quantity
  qty += parseInt(item.quantity, 10);

  // Set type based on item.type
  let type;
  if (item.type === '1') {
    type = 'Box';  // Set type as 'Pic' if item.type is '1'
  } else {
    type = 'PCs';  // Set a default type if item.type is not '1'
  }

  // Return the row data with product details
  return [
    item.product_code + ' - ' + item.product_name + ' - ' + item.size, // Combined product code and name
    type, // Type ('Pic' or 'Box')
    parseInt(item.quantity, 10), // Quantity as an integer
  ];
});
 tableBody.push([
      { content:  `Box : ${box_qty} PCs : ${piece_qty} `, colSpan: 1, styles: { halign: 'left' } }, 
      { content: 'Total:', styles: { halign: 'right',fontSize :8,fontStyle: 'bold' } }, 
      {content : ` ${qty}` }
      ]);

   


    doc.autoTable({
      startY: y,
      head: [tableColumns.map(col => col.title)],
      body: tableBody,
      theme: 'grid',
      headStyles: {
        fillColor: '#f8f9fa',
        textColor: '#404040',
        fontSize: 8,  
        valign: 'middle', 
        lineWidth: 0.3,
        halign: 'center', 
        lineWidth: 0.3,
        lineColor: [0, 0, 0] 
      },
      bodyStyles: {
        fontSize: 8,  
        valign: 'middle',
        lineWidth: 0.3,
        lineColor: [0, 0, 0] 
      },
      margin: { top: 10, left: margin, right: margin },
      columnStyles: {
        0: { cellWidth: productWidth, fontSize: 8, fontStyle: 'bold' },
        1: { cellWidth: orderTypeWidth,fontSize: 8, fontStyle: 'bold' },
        2: { cellWidth: qtyWidth ,fontSize: 8, fontStyle: 'bold'},
       
        1: { halign: 'center' },
        2: { halign: 'center' },
       
      },
    });


    y = doc.autoTable.previous.finalY + 0;

    const addFooterSection = (footerSection) => {
      footerSection.forEach((section, index) => {
        const colStartX = margin + (index % 4) * sectionWidth;

        section.forEach((item, i) => {
          doc.setFontSize(item.size);
          doc.setFont(item.bold ? 'helvetica' : 'helvetica', item.bold ? 'bold' : 'normal');
          doc.text(item.text, colStartX, y + i * 5);
        });

        if (index % 4 === 3) {
          y += 20; 
        }
      });
      y += 20; 
    };

    const addFooterWithSpaceCheck = (footerSection) => {
      const remainingSpace = pageHeight - y - margin; 
      const requiredSpace = footerSection.length * 5 + 20; 

      if (remainingSpace < requiredSpace) {
        doc.addPage(); 
        y = margin + 20; 
      }

      addFooterSection(footerSection);
    };

 


const remainingSpace = pageHeight - y - 10; // Calculate available space

// Add footer content with direct positioning
if (remainingSpace >= 20) {
 // doc.text("No claims for damages or short delivery will be considered unless made at the time of delivery of goods. These goods are not sold on a sale or return basis.", 10, pageHeight - 20);  // Directly specify position
} else {
  doc.addPage(); 
 // doc.text("No claims for damages or short delivery will be considered unless made at the time of delivery of goods. These goods are not sold on a sale or return basis.", 10, pageHeight - 20);  // Directly specify position on new page
}

const filename = generateUniqueFilename('Invoice', 'pdf');
//doc.save(filename);

doc.autoPrint();
window.open(doc.output('bloburl')); 



} catch (error) {
  console.error('Error generating PDF:', error);
}
};

export default generateSummaryPDF;
